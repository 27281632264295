export const fluorination = {
  "(1,10-Phenanthroline)(trifluoromethyl)(triphenylphosphine)copper(I)": "[Cu]C(F)(F)F.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.C45=CC=C6C=CC=NC6=C4N=CC=C5",
  "(Bromodifluoromethyl)trimethylsilane": "C[Si](C)(C(F)(Br)F)C",
  "(Diethylamino)sulfur Trifluoride": "CCN(S(F)(F)F)CC",
  "(Difluoromethyl)trimethylsilane": "C[Si](C)(C(F)F)C",
  "(E)-Trimethyl(3,3,3-trifluoro-1-propenyl)silane": "FC(F)(F)/C=C/[Si](C)(C)C",
  "(Perfluorohexyl)phenyliodonium Trifluoromethanesulfonate": "FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O",
  "(Perfluoro-n-octyl)phenyliodonium Trifluoromethanesulfonate": "FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O",
  "(Perfluoropropyl)phenyliodonium Trifluoromethanesulfonate": "FC([I+]C1=CC=CC=C1)(F)C(F)(F)C(F)(F)F.O=S(C(F)(F)F)([O-])=O",
  "(Trifluoroacetyl)benzotriazole": "O=C(C1=C2N=NNC2=CC=C1)C(F)(F)F",
  "(Trifluoromethyl)trimethylsilane": "C[Si](C)(C(F)(F)F)C",
  "(Trifluoromethyl)tris(triphenylphosphine)copper(I)": "[Cu]C(F)(F)F.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9",
  "1-(Heptafluorobutyryl)imidazole": "O=C(N1C=CN=C1)C(F)(F)C(F)(F)C(F)(F)F",
  "1-(Trifluoroacetyl)-4-(dimethylamino)pyridinium Trifluoroacetate": "CN(C1=CC=[N+](C(C(F)(F)F)=O)C=C1)C.O=C([O-])C(F)(F)F",
  "1-(Trifluoroacetyl)imidazole": "O=C(N1C=CN=C1)C(F)(F)F",
  "1-(Trifluoroacetyl)piperidine": "O=C(N1CCCCC1)C(F)(F)F",
  "1,1,1,3,3,3-Hexafluoroisopropyl Trifluoromethanesulfonate": "O=S(C(F)(F)F)(OC(C(F)(F)F)C(F)(F)F)=O",
  "1,1'-Difluoro-2,2'-bipyridinium Bis(tetrafluoroborate)": "F[N+]1=CC=CC=C1C2=CC=CC=[N+]2F.F[B-](F)(F)F.F[B-](F)(F)F",
  "1-Fluoro-2,4,6-trimethylpyridinium Tetrafluoroborate": "CC1=[N+](F)C(C)=CC(C)=C1.F[B-](F)(F)F",
  "1-Fluoro-2,4,6-trimethylpyridinium Trifluoromethanesulfonate": "CC1=[N+](F)C(C)=CC(C)=C1.O=S(C(F)(F)F)([O-])=O",
  "1-Fluoro-2,6-dichloropyridinium Tetrafluoroborate": "ClC1=[N+](F)C(Cl)=CC=C1.F[B-](F)(F)F",
  "1-Fluoro-3,3-dimethyl-1,2-benziodoxole": "CC1(C)OI(F)C2=CC=CC=C12",
  "1-Fluoropyridinium Tetrafluoroborate": "F[N+]1=CC=CC=C1.F[B-](F)(F)F",
  "1-Fluoropyridinium Trifluoromethanesulfonate": "F[N+]1=CC=CC=C1.O=S(C(F)(F)F)([O-])=O",
  "1-Trifluoromethyl-1,2-benziodoxol-3(1H)-one": "O=C1OI(C(F)(F)F)C2=CC=CC=C12",
  "1-Trifluoromethyl-3,3-dimethyl-1,2-benziodoxole": "CC1(C)OI(C(F)(F)F)C2=CC=CC=C12",
  "2,2,2-Trifluoro-1,1-ethanediol": "OC(O)C(F)(F)F",
  "2,2,2-Trifluoroethyl Methanesulfonate": "CS(=O)(OCC(F)(F)F)=O",
  "2,2,2-Trifluoroethyl Trifluoromethanesulfonate": "O=S(C(F)(F)F)(OCC(F)(F)F)=O",
  "2,2,2-Trifluoroethyl p-Toluenesulfonate": "CC1=CC=C(S(=O)(OCC(F)(F)F)=O)C=C1",
  "2,2,2-Trifluoro-N,N-dimethylacetamide": "O=C(N(C)C)C(F)(F)F",
  "2,2,3,3,3-Pentafluoropropyl p-Toluenesulfonate": "CC1=CC=C(S(=O)(OCC(F)(F)C(F)(F)F)=O)C=C1",
  "2,2-Difluoro-2-(fluorosulfonyl)acetic Acid": "O=C(O)C(F)(F)S(=O)(F)=O",
  "2,2-Difluoroethyl Trifluoromethanesulfonate": "O=S(C(F)(F)F)(OCC(F)F)=O",
  "2,2-Difluoroethylamine": "NCC(F)F",
  "2,8-Difluoro-5-(trifluoromethyl)-5H-dibenzo[b,d]thiophen-5-ium Trifluoromethanesulfonate": "FC([S+]1C2=CC=C(F)C=C2C3=CC(F)=CC=C31)(F)F.O=S(C(F)(F)F)([O-])=O",
  "2-Chloro-1,3-bis(2,6-diisopropylphenyl)-1H-imidazolium Chloride": "CC(C1=C([N+]2=C(Cl)N(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C.[Cl-]",
  "2-Fluoro-1-methylpyridinium p-Toluenesulfonate": "C[N+]1=CC=CC=C1F.CC2=CC=C(S(=O)([O-])=O)C=C2",
  "4-[2,2,2-Trifluoro-1-[(trimethylsilyl)oxy]ethyl]morpholine": "C[Si](OC(N1CCOCC1)C(F)(F)F)(C)C",
  "4-tert-Butyl-2,6-dimethylphenylsulfur Trifluoride": "CC1=C(S(F)(F)F)C(C)=CC(C(C)(C)C)=C1",
  "AlkylFluor": "FC(N(C=C1)C2=C(C(C)C)C=CC=C2C(C)C)=[N+]1C(C(C(C)C)=CC=C3)=C3C(C)C.F[B-](F)(F)F",
  "Benzoyl(phenyliodonio)(trifluoromethanesulfonyl)methanide": "O=S([C-](C(C1=CC=CC=C1)=O)[I+]C2=CC=CC=C2)(C(F)(F)F)=O",
  "Bistrifluoroacetamide": "O=C(C(F)(F)F)NC(C(F)(F)F)=O",
  "Copper(I) Trifluoromethanethiolate": "[S-]C(F)(F)F.[Cu+]",
  "Diethyl (Bromodifluoromethyl)phosphonate": "FC(Br)(P(OCC)(OCC)=O)F",
  "Difluoroacetic Anhydride": "O=C(OC(C(F)F)=O)C(F)F",
  "Difluoromethyl 2-Pyridyl Sulfone": "O=S(C1=NC=CC=C1)(C(F)F)=O",
  "Difluoromethyl Phenyl Sulfide": "FC(SC1=CC=CC=C1)F",
  "Difluoromethyl Phenyl Sulfone": "O=S(C1=CC=CC=C1)(C(F)F)=O",
  "Heptafluorobutyric Anhydride": "O=C(OC(C(F)(F)C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)C(F)(F)F",
  "Heptafluorobutyryl Chloride": "O=C(Cl)C(F)(F)C(F)(F)C(F)(F)F",
  "Methyl Chlorodifluoroacetate": "O=C(OC)C(F)(Cl)F",
  "Methyl Difluoro(fluorosulfonyl)acetate": "O=C(OC)C(F)(F)S(=O)(F)=O",
  "Morpholinosulfur Trifluoride": "FS(F)(F)N1CCOCC1",
  "N-(Trifluoromethylthio)phthalimide": "O=C(N1SC(F)(F)F)C2=CC=CC=C2C1=O",
  "N-Fluorobenzenesulfonimide": "FN(S(=O)(C1=CC=CC=C1)=O)S(=O)(C2=CC=CC=C2)=O",
  "N-Fluoro-N'-(chloromethyl)triethylenediamine Bis(tetrafluoroborate)": "F[N+]1(CC2)CC[N+]2(CCl)CC1.F[B-](F)(F)F.F[B-](F)(F)F",
  "N-Methylbis(trifluoroacetamide)": "O=C(C(F)(F)F)N(C)C(C(F)(F)F)=O",
  "N-Methyl-N-(trifluoromethylthio)aniline": "FC(F)(F)SN(C)C1=CC=CC=C1",
  "Pentafluorobenzoyl Chloride": "O=C(Cl)C1=C(F)C(F)=C(F)C(F)=C1F",
  "Pentafluorophenyl Trifluoroacetate": "O=C(OC1=C(F)C(F)=C(F)C(F)=C1F)C(F)(F)F",
  "Pentafluoropropionic Anhydride": "O=C(OC(C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)F",
  "Phenyl Trifluoromethyl Sulfone": "O=S(C(F)(F)F)(C1=CC=CC=C1)=O",
  "Phenyl Trifluoromethyl Sulfoxide": "O=S(C1=CC=CC=C1)C(F)(F)F",
  "Potassium Trifluoroacetate": "O=C([O-])C(F)(F)F.[K+]",
  "Potassium Trimethoxy(trifluoromethyl)borate": "FC([B-](OC)(OC)OC)(F)F.[K+]",
  "Pyridine-2-sulfonyl Fluoride": "O=S(C1=NC=CC=C1)(F)=O",
  "Pyrimidine-2-sulfonyl Fluoride": "O=S(C1=NC=CC=N1)(F)=O",
  "S-Ethyl Trifluorothioacetate": "O=C(SCC)C(F)(F)F",
  "Silver(I) Trifluoromethanethiolate": "[S-]C(F)(F)F.[Ag+]",
  "Sodium Bromodifluoroacetate": "O=C([O-])C(F)(Br)F.[Na+]",
  "Sodium Chlorodifluoroacetate": "O=C([O-])C(F)(Cl)F.[Na+]",
  "Sodium Trifluoroacetate": "O=C([O-])C(F)(F)F.[Na+]",
  "Sodium Trifluoromethanesulfinate": "O=S(C(F)(F)F)[O-].[Na+]",
  "Tetrabutylammonium Bifluoride": "CCCC[N+](CCCC)(CCCC)CCCC.[H]F.[F-]",
  "Tetrabutylammonium Difluorotriphenylstannate": "CCCC[N+](CCCC)(CCCC)CCCC.F[Sn-](C1=CC=CC=C1)(C2=CC=CC=C2)(F)C3=CC=CC=C3",
  "Tetrabutylammonium Dihydrogen Trifluoride": "CCCC[N+](CCCC)(CCCC)CCCC.[H+].[H+].[F-].[F-].[F-]",
  "Tetrabutylammonium Fluoride": "CCCC[N+](CCCC)(CCCC)CCCC.[F-]",
  "Tetrabutylammonium Fluoride Hydrate": "CCCC[N+](CCCC)(CCCC)CCCC.[H]O[H].[F-]",
  "Tetraethylammonium Fluoride Tetrahydrofluoride": "CC[N+](CC)(CC)CC.[H]F.[H]F.[H]F.[H]F.[F-]",
  "Tetraethylammonium Fluoride Trihydrofluoride": "CC[N+](CC)(CC)CC.[H]F.[H]F.[H]F.[F-]",
  "Tetramethylammonium Fluoride Tetrahydrate": "C[N+](C)(C)C.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[F-]",
  "Triethyl 2-Fluoro-2-phosphonoacetate": "O=C(OCC)C(F)P(OCC)(OCC)=O",
  "Triethyl(trifluoromethyl)silane": "FC([Si](CC)(CC)CC)(F)F",
  "Triethylamine Trihydrofluoride": "CCN(CC)CC.[H]F.[H]F.[H]F",
  "Trifluoroacetic Anhydride": "O=C(OC(C(F)(F)F)=O)C(F)(F)F",
  "Trifluoroacetyl Triflate": "O=S(OC(C(F)(F)F)=O)(C(F)(F)F)=O",
  "Trimethyl(pentafluoroethyl)silane": "FC([Si](C)(C)C)(F)C(F)(F)F",
  "Trimethylsilyl Difluoro(fluorosulfonyl)acetate": "O=C(O[Si](C)(C)C)C(F)(F)S(=O)(F)=O",
  "Zinc(II) Bis(trifluoromethanesulfinate) Dihydrate": "O=S(C(F)(F)F)[O-].O=S(C(F)(F)F)[O-].[H]O[H].[H]O[H].[Zn+2]",
  "IF5-Pyridine-HF": "[H]F.FI(F)(F)(F)F.C1=CN=CC=C1",
  "Ishikawa's Reagent": "FC(N(CC)CC)(C(C(F)(F)F)F)F",
  "PhenoFluor™ Mix": "[Cs]F.Cl[C+](N(C=C1)C2=C(C(C)C)C=CC=C2C(C)C)=[N]1C(C(C(C)C)=CC=C3)=C3C(C)C.[Cl-]"
}