import React from 'react';

const BlockEa = () => (
  <div className="card-qc">
    <h5>
      <span>5 Analysis of the provided EA analysis:</span>
    </h5>
    <div className="card-qc">
      <p>TBD</p>
    </div>
    <br />
  </div>
);

export default BlockEa;
