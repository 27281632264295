export const relationTypes = [
  {label: 'IsCitedBy', value: 'IS_CITED_BY'},
  {label: 'Cites', value: 'CITES'},
  {label: 'IsSupplementTo', value: 'IS_SUPPLEMENT_TO'},
  {label: 'IsSupplementedBy', value: 'IS_SUPPLEMENTED_BY'},
  {label: 'IsContinuedBy', value: 'IS_CONTINUED_BY'},
  {label: 'Continues', value: 'CONTINUES'},
  // {label: 'IsDescribedBy', value: ''},
  // {label: 'Describes', value: ''},
  {label: 'HasMetadata', value: 'HAS_METADATA'},
  {label: 'IsMetadataFor', value: 'IS_METADATA_FOR'},
  // {label: 'HasVersion', value: ''},
  // {label: 'IsVersionOf', value: ''},
  {label: 'IsNewVersionOf', value: 'IS_NEW_VERSION_OF'},
  {label: 'IsPreviousVersionOf', value: 'IS_PREVIOUS_VERSION_OF'},
  {label: 'IsPartOf', value: 'IS_PART_OF'},
  {label: 'HasPart', value: 'HAS_PART'},
  // {label: 'IsPublishedIn', value: ''},
  {label: 'IsReferencedBy', value: 'IS_REFERENCED_BY'},
  {label: 'References', value: 'REFERENCES'},
  {label: 'IsDocumentedBy', value: 'IS_DOCUMENTED_BY'},
  {label: 'Documents', value: 'DOCUMENTS'},
  {label: 'IsCompiledBy', value: 'IS_COMPILED_BY'},
  {label: 'Compiles', value: 'COMPILES'},
  {label: 'IsVariantFormOf', value: 'IS_VARIANT_FORM_OF'},
  {label: 'IsOriginalFormOf', value: 'IS_ORIGINAL_FORM_OF'},
  {label: 'IsIdenticalTo', value: 'IS_IDENTICAL_TO'},
  {label: 'IsReviewedBy', value: 'IS_REVIEWED_BY'},
  {label: 'Reviews', value: 'REVIEWS'},
  {label: 'IsDerivedFrom', value: 'IS_DERIVED_FROM'},
  {label: 'IsSourceOf', value: 'IS_SOURCE_OF'},
  // {label: 'IsRequiredBy', value: ''},
  // {label: 'Requires', value: ''},
  // {label: 'IsObsoletedBy', value: ''},
  // {label: 'Obsoletes', value: ''}
]
