export const SET_CDD_INSTANCE = 'SET_CDD_INSTANCE';
export const SCAN_FILE_FOR_MOLECULES = 'SCAN_FILE_FOR_MOLECULES';
export const SCAN_FILE_FOR_REACTIONS = 'SCAN_FILE_FOR_REACTIONS';
export const REMOVE_FILE = 'REMOVE_FILE';

export const REMOVE_MOLECULE = 'REMOVE_MOLECULE';
export const REMOVE_REACTION = 'REMOVE_REACTION';

export const EDIT_MOLECULE_COMMENT = 'EDIT_MOLECULE_COMMENT';
export const EDIT_REACTION_COMMENT = 'EDIT_REACTION_COMMENT';

export const SELECT_MOLECULE = 'SELECT_MOLECULE';
export const SELECT_REACTION = 'SELECT_REACTION';

export const TOGGLE_RESIN_IN_REACTION = 'TOGGLE_RESIN_IN_REACTION';
export const TOGGLE_MOLECULE_RESIN = 'TOGGLE_MOLECULE_RESIN';

export const CLEAN_UP = 'CLEAN_UP';
export const CLEAN_UP_REQUEST_SVG = 'CLEAN_UP_REQUEST_SVG';

export const TOGGLE_ABB_VIEW = 'TOGGLE_ABB_VIEW';

export const ADD_REAGENTS_SMILES = 'ADD_REAGENTS_SMILES';

export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
