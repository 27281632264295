export const couplingReagents = {
  "(6-Chloro-1H-benzotriazol-1-yloxy)tripyrrolidinophosphonium Hexafluorophosphate": "ClC1=CC=C2N=NN(O[P+](N3CCCC3)(N4CCCC4)N5CCCC5)C2=C1.F[P-](F)(F)(F)(F)F",
  "(7-Azabenzotriazol-1-yloxy)tris(dimethylamino)phosphonium Hexafluorophosphate": "CN([P+](N(C)C)(ON1N=NC2=CC=CN=C21)N(C)C)C.F[P-](F)(F)(F)(F)F",
  "1-(2,4,6-Triisopropylbenzenesulfonyl)-1,2,4-triazole": "O=S(N1N=CN=C1)(C2=C(C(C)C)C=C(C(C)C)C=C2C(C)C)=O",
  "1-(2,4,6-Triisopropylbenzenesulfonyl)imidazole": "O=S(N1C=CN=C1)(C2=C(C(C)C)C=C(C(C)C)C=C2C(C)C)=O",
  "1-(2-Mesitylenesulfonyl)imidazole": "O=S(N1C=CN=C1)(C(C(C)=CC(C)=C2)=C2C)=O",
  "1-(3-Dimethylaminopropyl)-3-ethylcarbodiimide": "CCN=C=NCCCN(C)C",
  "1-(3-Dimethylaminopropyl)-3-ethylcarbodiimide Hydrochloride": "CCN=C=NCCCN(C)C.[H]Cl",
  "1-(Chloro-1-pyrrolidinylmethylene)pyrrolidinium Hexafluorophosphate": "ClC(N1CCCC1)=[N+]2CCCC2.F[P-](F)(F)(F)(F)F",
  "1-(Chloro-1-pyrrolidinylmethylene)pyrrolidinium Tetrafluoroborate": "ClC(N1CCCC1)=[N+]2CCCC2.F[B-](F)(F)F",
  "1-(Cyanomethyl)piperidinium Tetrafluoroborate": "N#CC[NH+]1CCCCC1.F[B-](F)(F)F",
  "1-(p-Toluenesulfonyl)imidazole": "O=S(N1C=CN=C1)(C2=CC=C(C)C=C2)=O",
  "1-(Trifluoromethanesulfonyl)-1H-benzotriazole": "O=S(N1N=NC2=CC=CC=C21)(C(F)(F)F)=O",
  "1-(Trifluoromethanesulfonyl)imidazole": "O=S(N1C=CN=C1)(C(F)(F)F)=O",
  "1,1'-Carbonyldi(1,2,4-triazole)": "O=C(N1N=CN=C1)N2N=CN=C2",
  "1,1'-Carbonyldiimidazole": "O=C(N1C=CN=C1)N2C=CN=C2",
  "1,1'-Oxalyldiimidazole": "O=C(N1C=CN=C1)C(N2C=CN=C2)=O",
  "1,2,4-Triazole": "N1N=CN=C1",
  "1-Cyclohexyl-3-(2-morpholinoethyl)carbodiimide Metho-p-toluenesulfonate": "C[N+]1(CCN=C=NC2CCCCC2)CCOCC1.O=S(C3=CC=C(C)C=C3)([O-])=O",
  "1-Ethoxycarbonyl-2-ethoxy-1,2-dihydroquinoline": "CCOC1N(C(OCC)=O)C2=C(C=CC=C2)C=C1",
  "1H-Benzotriazol-1-yloxytripyrrolidinophosphonium Hexafluorophosphate": "F[P-](F)(F)(F)(F)F.N1([P+](N2CCCC2)(ON3N=NC4=CC=CC=C43)N5CCCC5)CCCC1",
  "1H-Benzotriazol-1-yloxytris(dimethylamino)phosphonium Hexafluorophosphate": "CN([P+](N(C)C)(ON1N=NC2=CC=CC=C21)N(C)C)C.F[P-](F)(F)(F)(F)F",
  "1-Hydroxy-6-(trifluoromethyl)benzotriazole": "FC(C1=CC=C2N=NN(O)C2=C1)(F)F",
  "2-(5-Norbornene-2,3-dicarboximido)-1,1,3,3-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N(C1=O)C(C2C1C3C=CC2C3)=O.F[B-](F)(F)F",
  "2,2'-Carbonylbis(3,5-dioxo-4-methyl-1,2,4-oxadiazolidine)": "O=C(N(C(N1C)=O)OC1=O)N(C(N2C)=O)OC2=O",
  "2,2'-Dipyridyl Disulfide": "C1(SSC2=NC=CC=C2)=NC=CC=C1",
  "2,4,5-Trichlorophenol": "OC1=CC(Cl)=C(Cl)C=C1Cl",
  "2,4,6-Trichlorobenzoyl Chloride": "O=C(Cl)C1=C(Cl)C=C(Cl)C=C1Cl",
  "2,4,6-Triisopropylbenzenesulfonyl Chloride": "O=S(C1=C(C(C)C)C=C(C(C)C)C=C1C(C)C)(Cl)=O",
  "2,4-Mesitylenedisulfonyl Dichloride": "CC1=C(S(=O)(Cl)=O)C(C)=CC(C)=C1S(=O)(Cl)=O",
  "2-[N,N-Bis(trifluoromethanesulfonyl)amino]-5-chloropyridine": "ClC1=CN=C(N(S(=O)(C(F)(F)F)=O)S(=O)(C(F)(F)F)=O)C=C1",
  "2-Bromo-1-ethylpyridinium Tetrafluoroborate": "CC[N+]1=CC=CC=C1Br.F[B-](F)(F)F",
  "2-Chloro-1,3-dimethylimidazolinium Chloride": "C[N+]1=C(Cl)N(C)CC1.[Cl-]",
  "2-Chloro-1,3-dimethylimidazolinium Hexafluorophosphate": "C[N+]1=C(Cl)N(C)CC1.F[P-](F)(F)(F)(F)F",
  "2-Chloro-1,3-dimethylimidazolinium Tetrafluoroborate": "C[N+]1=C(Cl)N(C)CC1.F[B-](F)(F)F",
  "2-Chloro-1-methylpyridinium Iodide": "C[N+]1=CC=CC=C1Cl.[I-]",
  "2-Chloro-1-methylpyridinium p-Toluenesulfonate": "C[N+]1=CC=CC=C1Cl.CC2=CC=C(S(=O)([O-])=O)C=C2",
  "2-Fluoro-1-methylpyridinium p-Toluenesulfonate": "C[N+]1=CC=CC=C1F.CC2=CC=C(S(=O)([O-])=O)C=C2",
  "2-Hydroxypyridine N-Oxide": "OC1=CC=CC=[N+]1[O-]",
  "2-Methyl-6-nitrobenzoic Anhydride": "O=C(OC(C1=C([N+]([O-])=O)C=CC=C1C)=O)C2=C([N+]([O-])=O)C=CC=C2C",
  "3-(Diethoxyphosphoryloxy)-1,2,3-benzotriazin-4(3H)-one": "O=C1N(OP(OCC)(OCC)=O)N=NC2=CC=CC=C12",
  "3,4-Dihydro-2H-pyrido[1,2-a]pyrimidin-2-one": "O=C1N=C(C=CC=C2)N2CC1",
  "3,4-Dihydro-3-hydroxy-4-oxo-1,2,3-benzotriazine": "O=C1N(O)N=NC2=CC=CC=C12",
  "3-Nitro-1,2,4-triazole": "O=[N+](C1=NNC=N1)[O-]",
  "3-Pyridinecarboxylic Anhydride": "O=C(OC(C1=CC=CN=C1)=O)C2=CC=CN=C2",
  "4-(4,6-Dimethoxy-1,3,5-triazin-2-yl)-4-methylmorpholinium Chloride": "C[N+]1(C2=NC(OC)=NC(OC)=N2)CCOCC1.[Cl-]",
  "4,6-Diphenylthieno[3,4-d]-1,3-dioxol-2-one 5,5-Dioxide": "O=C(OC1=C2C3=CC=CC=C3)OC1=C(C4=CC=CC=C4)S2(=O)=O",
  "4-Dimethylaminopyridine": "CN(C1=CC=NC=C1)C",
  "4-Nitrophenol": "OC1=CC=C([N+]([O-])=O)C=C1",
  "4-Nitrophenyl Trifluoromethanesulfonate": "O=S(C(F)(F)F)(OC1=CC=C([N+]([O-])=O)C=C1)=O",
  "4-Pyrrolidinopyridine": "C1(N2CCCC2)=CC=NC=C1",
  "4-Trifluoromethylbenzoic Anhydride": "O=C(OC(C1=CC=C(C(F)(F)F)C=C1)=O)C2=CC=C(C(F)(F)F)C=C2",
  "9-Methyl-3,4-dihydro-2H-pyrido[1,2-a]pyrimidin-2-one": "O=C1N=C(C(C)=CC=C2)N2CC1",
  "Bis(4-nitrophenyl) Carbonate": "O=C(OC1=CC=C([N+]([O-])=O)C=C1)OC2=CC=C([N+]([O-])=O)C=C2",
  "Bis(pentafluorophenyl) Carbonate": "O=C(OC1=C(F)C(F)=C(F)C(F)=C1F)OC2=C(F)C(F)=C(F)C(F)=C2F",
  "Bromotripyrrolidinophosphonium Hexafluorophosphate": "Br[P+](N1CCCC1)(N2CCCC2)N3CCCC3.F[P-](F)(F)(F)(F)F",
  "Bromotris(dimethylamino)phosphonium Hexafluorophosphate": "CN([P+](N(C)C)(Br)N(C)C)C.F[P-](F)(F)(F)(F)F",
  "Chloro-N,N,N',N'-tetramethylformamidinium Hexafluorophosphate": "C[N+](C)=C(Cl)N(C)C.F[P-](F)(F)(F)(F)F",
  "Chlorotripyrrolidinophosphonium Hexafluorophosphate": "Cl[P+](N1CCCC1)(N2CCCC2)N3CCCC3.F[P-](F)(F)(F)(F)F",
  "Cyanomethylenetributylphosphorane": "CCCCP(CCCC)(CCCC)=CC#N",
  "Di(N-succinimidyl) Carbonate": "O=C(ON1C(CCC1=O)=O)ON2C(CCC2=O)=O",
  "Di-2-pyridyl Carbonate": "O=C(OC1=NC=CC=C1)OC2=NC=CC=C2",
  "Diethyl Cyanophosphonate": "N#CP(OCC)(OCC)=O",
  "Dimesitylammonium Pentafluorobenzenesulfonate": "CC1=CC(C)=CC(C)=C1[NH2+]C2=C(C)C=C(C)C=C2C.O=S(C3=C(F)C(F)=C(F)C(F)=C3F)([O-])=O",
  "Dimethylthiophosphinoyl Chloride": "ClP(C)(C)=S",
  "Diphenylphosphinic Chloride": "O=P(C1=CC=CC=C1)(C2=CC=CC=C2)Cl",
  "Diphenylphosphoryl Azide": "O=P(C1=CC=CC=C1)(N=[N+]=[N-])C2=CC=CC=C2",
  "Ethyl 1-Hydroxy-1H-1,2,3-triazole-4-carboxylate": "O=C(C1=CN(O)N=N1)OCC",
  "Ethyl Cyano(hydroxyimino)acetate": "O=C(OCC)/C(C#N)=N/O",
  "Fluoro-N,N,N',N'-tetramethylformamidinium Hexafluorophosphate": "C[N+](C)=C(F)N(C)C.F[P-](F)(F)(F)(F)F",
  "Iodosodilactone": "O=C1OI2C3=C1C=CC=C3C(O2)=O",
  "N-(2-Pyridyl)bis(trifluoromethanesulfonimide)": "O=S(N(C1=NC=CC=C1)S(=O)(C(F)(F)F)=O)(C(F)(F)F)=O",
  "N,N,N',N'-Tetramethyl-O-(N-succinimidyl)uronium Hexafluorophosphate": "O=C(N1[O+]=C(N(C)C)N(C)C)CCC1=O.F[P-](F)(F)(F)(F)F",
  "N,N,N',N'-Tetramethyl-O-(N-succinimidyl)uronium Tetrafluoroborate": "O=C(N1[O+]=C(N(C)C)N(C)C)CCC1=O.F[B-](F)(F)F",
  "N,N,N',N'-Tetramethyl-S-(1-oxido-2-pyridyl)thiouronium Hexafluorophosphate": "[O-][N+]1=CC=CC=C1[S+]=C(N(C)C)N(C)C.F[P-](F)(F)(F)(F)F",
  "N,N,N',N'-Tetramethyl-S-(1-oxido-2-pyridyl)thiouronium Tetrafluoroborate": "[O-][N+]1=CC=CC=C1[S+]=C(N(C)C)N(C)C.F[B-](F)(F)F",
  "N,N'-Dicyclohexylcarbodiimide": "C1(N=C=NC2CCCCC2)CCCCC1",
  "N,N'-Diisopropylcarbodiimide": "CC(N=C=NC(C)C)C",
  "N,N'-Di-tert-butylcarbodiimide": "CC(N=C=NC(C)(C)C)(C)C",
  "N-Hydroxy-5-norbornene-2,3-dicarboximide": "O=C(C1C2C3C=CC1C3)N(O)C2=O",
  "N-Hydroxyphthalimide": "O=C(N1O)C2=CC=CC=C2C1=O",
  "N-Hydroxysuccinimide": "O=C(N1O)CCC1=O",
  "N-Hydroxysulfosuccinimide Sodium Salt": "O=C(N1O)C(S(=O)([O-])=O)CC1=O.[Na+]",
  "N-Phenylbis(trifluoromethanesulfonimide)": "O=S(N(C1=CC=CC=C1)S(C(F)(F)F)(=O)=O)(C(F)(F)F)=O",
  "N-tert-Butyl-5-methylisoxazolium Perchlorate": "CC1=CC=[N+](C(C)(C)C)O1.O=Cl(=O)([O-])=O",
  "O-(3,4-Dihydro-4-oxo-1,2,3-benzotriazin-3-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C2C1=O.F[B-](F)(F)F",
  "O-(6-Chlorobenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=C(Cl)C=C21.F[P-](F)(F)(F)(F)F",
  "O-(6-Chlorobenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=C(Cl)C=C21.F[B-](F)(F)F",
  "O-(7-Azabenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CN=C21.F[P-](F)(F)(F)(F)F",
  "O-(7-Azabenzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CN=C21.F[B-](F)(F)F",
  "O-(Benzotriazol-1-yl)-N,N,N',N'-bis(pentamethylene)uronium Hexafluorophosphate": "F[P-](F)(F)(F)(F)F.N1(OC(N2CCCCC2)=[N+]3CCCCC3)N=NC4=C1C=CC=C4",
  "O-(Benzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Hexafluorophosphate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C21.F[P-](F)(F)(F)(F)F",
  "O-(Benzotriazol-1-yl)-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N1N=NC2=CC=CC=C21.F[B-](F)(F)F",
  "O,O'-Di-2-pyridyl Thiocarbonate": "S=C(OC1=NC=CC=C1)OC2=NC=CC=C2",
  "O-[(Ethoxycarbonyl)cyanomethylenamino]-N,N,N',N'-tetramethyluronium Hexafluorophosphate": "CN(C)C(N(C)C)=[O+]/N=C(C(OCC)=O)\\C#N.F[P-](F)(F)(F)(F)F",
  "O-[(Ethoxycarbonyl)cyanomethylenamino]-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]/N=C(C(OCC)=O)\\C#N.F[B-](F)(F)F",
  "O-[2-Oxo-1(2H)-pyridyl]-N,N,N',N'-tetramethyluronium Tetrafluoroborate": "CN(C)C(N(C)C)=[O+]N1C=CC=CC1=O.F[B-](F)(F)F",
  "Pentafluorophenol": "OC1=C(F)C(F)=C(F)C(F)=C1F",
  "Pentafluorophenyl 4-Nitrobenzenesulfonate": "O=S(C1=CC=C([N+]([O-])=O)C=C1)(OC2=C(F)C(F)=C(F)C(F)=C2F)=O",
  "Trifluoromethanesulfonanilide": "O=S(C(F)(F)F)(NC1=CC=CC=C1)=O",
  "Trifluoromethanesulfonic Anhydride": "O=S(C(F)(F)F)(OS(C(F)(F)F)(=O)=O)=O",
  "Trifluoromethanesulfonyl Chloride": "O=S(C(F)(F)F)(Cl)=O",
  "Triphosgene": "ClC(Cl)(OC(OC(Cl)(Cl)Cl)=O)Cl",
}