export const ligands = {
  "(-)-2,2'-Methylenebis[(3aS,8aR)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": "[H][C@@]12N=C(CC3=N[C@]4([H])[C@](CC5=C4C=CC=C5)([H])O3)O[C@]1([H])CC6=C2C=CC=C6",
  "(-)-Sparteine": "[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3",
  "(+)-2,2'-Methylenebis[(3aR,8aS)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": "[H][C@]12N=C(CC3=N[C@@]4([H])[C@@](CC5=C4C=CC=C5)([H])O3)O[C@@]1([H])CC6=C2C=CC=C6",
  "(+)-Sparteine": "[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3",
  "(1R,2R)-(-)-1,2-Cyclohexanediamine": "N[C@H]1[C@H](N)CCCC1",
  "(1R,2R)-(-)-N,N'-Dimethylcyclohexane-1,2-diamine": "CN[C@H]1[C@H](NC)CCCC1",
  "(1R,2R)-(+)-1,2-Diphenylethylenediamine": "N[C@H](C1=CC=CC=C1)[C@@H](C2=CC=CC=C2)N",
  "(1R,2R)-1,2-Bis(2,4,6-trimethylphenyl)ethylenediamine": "N[C@H](C1=C(C)C=C(C)C=C1C)[C@@H](C2=C(C)C=C(C)C=C2C)N",
  "(1R,2R)-1,2-Bis(2-hydroxyphenyl)ethylenediamine": "N[C@H](C1=CC=CC=C1O)[C@@H](C2=CC=CC=C2O)N",
  "(1R,2R,4R,5R)-2,5-Bis(3,5-di-tert-butyl-2-hydroxybenzylideneamino)bicyclo[2.2.1]heptane": "OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@H]2[C@@]3([H])C[C@@H](/N=C/C4=CC(C(C)(C)C)=CC(C(C)(C)C)=C4O)[C@@](C3)([H])C2",
  "(1S)-3-(1,10-Phenanthrolin-2-yl)-2'-phenyl-[1,1'-binaphthalen]-2-ol": "OC1=C(C2=NC3=C4N=CC=CC4=CC=C3C=C2)C=C5C=CC=CC5=C1C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8",
  "(1S,2S)-(-)-1,2-Diphenylethylenediamine": "N[C@@H](C1=CC=CC=C1)[C@H](C2=CC=CC=C2)N",
  "(1S,2S)-(+)-1,2-Cyclohexanediamine": "N[C@@H]1[C@@H](N)CCCC1",
  "(1S,2S)-(+)-N,N'-Dimethylcyclohexane-1,2-diamine": "CN[C@@H]1[C@@H](NC)CCCC1",
  "(1S,2S)-1,2-Bis(2,4,6-trimethylphenyl)ethylenediamine": "N[C@@H](C1=C(C)C=C(C)C=C1C)[C@H](C2=C(C)C=C(C)C=C2C)N",
  "(1S,2S)-1,2-Bis(2-hydroxyphenyl)ethylenediamine": "N[C@@H](C1=CC=CC=C1O)[C@H](C2=CC=CC=C2O)N",
  "(1S,2S)-N,N'-Dihydroxy-N,N'-bis(diphenylacetyl)cyclohexane-1,2-diamine": "O=C(N(O)[C@@H]1[C@@H](N(O)C(C(C2=CC=CC=C2)C3=CC=CC=C3)=O)CCCC1)C(C4=CC=CC=C4)C5=CC=CC=C5",
  "(1S,2S,4S,5S)-2,5-Bis(3,5-di-tert-butyl-2-hydroxybenzylideneamino)bicyclo[2.2.1]heptane": "OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@@H]2[C@]3([H])C[C@H](/N=C/C4=CC(C(C)(C)C)=CC(C(C)(C)C)=C4O)[C@](C3)([H])C2",
  "(4S,4'S)-2,2'-(Pentane-3,3'-diyl)bis(4-benzyl-4,5-dihydrooxazole)": "CCC(C1=N[C@H](CO1)CC2=CC=CC=C2)(C3=N[C@H](CO3)CC4=CC=CC=C4)CC",
  "(R)-3,3'-Bis[[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]-1,1'-bi-2-naphthol": "OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C6C=CC=CC6=C1C7=C8C=CC=CC8=CC(/C=N/[C@H](C9=CC=CC=C9)[C@H](N%10CC%11=C(C=CC=C%11)C%10)C%12=CC=CC=C%12)=C7O",
  "(R,R)-(-)-2,3-Dimethoxy-1,4-bis(dimethylamino)butane": "CN(C[C@@H](OC)[C@H](OC)CN(C)C)C",
  "(R,R)-(-)-N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,2-cyclohexanediamine": "OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@H]2[C@H](/N=C/C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3O)CCCC2",
  "(R,R)-(+)-2,2'-Isopropylidenebis(4-tert-butyl-2-oxazoline)": "CC(C1=N[C@H](C(C)(C)C)CO1)(C2=N[C@H](C(C)(C)C)CO2)C",
  "(R,R)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": "CC(C1=N[C@H](C2=CC=CC=C2)CO1)(C3=N[C@H](C4=CC=CC=C4)CO3)C",
  "(R,R)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": "CC([C@H]1N=C(C2=CC=CC(C3=N[C@H](C(C)C)CO3)=N2)OC1)C",
  "(R,R)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": "C1(C2=N[C@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@H](C5=CC=CC=C5)CO4)=CC=C1",
  "(R,R)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": "CC1=CC=C(S(=O)(N[C@H](C2=CC=CC=C2)[C@H](N)C3=CC=CC=C3)=O)C=C1",
  "(S)-1-(Diphenylphosphino)-2-[(S)-4-isopropyloxazolin-2-yl]ferrocene": "CC(C)[C@@H]1N=C(c2c(P(C3=CC=CC=C3)C4=CC=CC=C4)ccc2)OC1.[Del][Fe][Del].c5cccc5",
  "(S)-2,4-Diphenyl-4,5-dihydrooxazole": "C1(C2=CC=CC=C2)=N[C@@H](C3=CC=CC=C3)CO1",
  "(S)-4-tert-Butyl-2-(2-pyridyl)oxazoline": "CC([C@@H]1N=C(C2=NC=CC=C2)OC1)(C)C",
  "(S,S)-(-)-2,2'-Isopropylidenebis(4-tert-butyl-2-oxazoline)": "CC(C1=N[C@@H](C(C)(C)C)CO1)(C2=N[C@@H](C(C)(C)C)CO2)C",
  "(S,S)-(+)-2,3-Dimethoxy-1,4-bis(dimethylamino)butane": "CN(C[C@H](OC)[C@@H](OC)CN(C)C)C",
  "(S,S'')-2,2''-Bis[(S)-4-isopropyloxazolin-2-yl]-1,1''-biferrocene": "CC(C)[C@H]1OC(c2c(c3cccc3C4=N[C@@H](C(C)C)CO4)ccc2)=NC1.[Del][Fe][Del].[Del][Fe][Del].c5cccc5.c6cccc6",
  "(S,S)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": "CC(C1=N[C@@H](C2=CC=CC=C2)CO1)(C3=N[C@@H](C4=CC=CC=C4)CO3)C",
  "(S,S)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": "CC([C@@H]1N=C(C2=CC=CC(C3=N[C@@H](C(C)C)CO3)=N2)OC1)C",
  "(S,S)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": "C1(C2=N[C@@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@@H](C5=CC=CC=C5)CO4)=CC=C1",
  "(S,S)-4,6-Bis(4-isopropyl-2-oxazolin-2-yl)-m-xylene": "CC1=C(C2=N[C@@H](C(C)C)CO2)C=C(C3=N[C@@H](C(C)C)CO3)C(C)=C1",
  "(S,S)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": "CC1=CC=C(S(=O)(N[C@@H](C2=CC=CC=C2)[C@@H](N)C3=CC=CC=C3)=O)C=C1",
  "1,1'-(2,6-Pyridinediyl)bis(3-methylimidazolium) Dibromide": "C[N+]1=CN(C2=NC(N3C=C[N+](C)=C3)=CC=C2)C=C1.[Br-].[Br-]",
  "1,1,4,7,10,10-Hexamethyltriethylenetetramine": "CN(CCN(C)C)CCN(C)CCN(C)C",
  "1,10-Phenanthroline Monohydrate": "[H]O[H].C12=CC=C3C=CC=NC3=C1N=CC=C2",
  "1,10-Phenanthroline-5,6-dione": "O=C1C2=C(N=CC=C2)C3=C(C=CC=N3)C1=O",
  "1,2-Bis(4'-methyl-2,2'-bipyridin-4-yl)ethane": "CC1=CC(C2=NC=CC(CCC3=CC(C4=NC=CC(C)=C4)=NC=C3)=C2)=NC=C1",
  "1,2-Phenylenediamine": "NC1=CC=CC=C1N",
  "1,4,7,10,13,16-Hexaazacyclooctadecane": "N1CCNCCNCCNCCNCCNCC1",
  "1,4,7,10,13,16-Hexaazacyclooctadecane Hexahydrochloride": "[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]Cl.N1CCNCCNCCNCCNCCNCC1",
  "1,4,7,10-Tetraazacyclododecane": "N1CCNCCNCCNCC1",
  "1,4,7,10-Tetraazacyclododecane Tetrahydrochloride": "[H]Cl.[H]Cl.[H]Cl.[H]Cl.N1CCNCCNCCNCC1",
  "1,4,7,10-Tetraazacyclododecane-1,4,7,10-tetraacetic Acid": "O=C(O)CN1CCN(CC(O)=O)CCN(CC(O)=O)CCN(CC(O)=O)CC1",
  "1,4,7,10-Tetrabenzyl-1,4,7,10-tetraazacyclododecane": "N1(CC2=CC=CC=C2)CCN(CC3=CC=CC=C3)CCN(CC4=CC=CC=C4)CCN(CC5=CC=CC=C5)CC1",
  "1,4,7-Triazacyclononane": "N1CCNCCNCC1",
  "1,4,7-Trimethyl-1,4,7-triazacyclononane (stabilized with NaHCO3)": "CN1CCN(C)CCN(C)CC1",
  "1,4,8,11-Tetramethyl-1,4,8,11-tetraazacyclotetradecane": "CN1CCN(C)CCCN(C)CCN(C)CCC1",
  "1,4-Di[[2,2':6',2''-terpyridin]-4'-yl]benzene": "C1(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)=CC=C(C5=CC(C6=NC=CC=C6)=NC(C7=NC=CC=C7)=C5)C=C1",
  "1,4-Diazabicyclo[2.2.2]octane": "N1(CC2)CCN2CC1",
  "1,5,9-Triazacyclododecane": "N1CCCNCCCNCCC1",
  "2-(1H-1,2,4-Triazol-3-yl)pyridine": "C1(C2=NNC=N2)=NC=CC=C1",
  "2-(2-Hydroxyphenyl)benzothiazole": "OC1=CC=CC=C1C2=NC3=CC=CC=C3S2",
  "2-(2-Hydroxyphenyl)benzoxazole": "OC1=CC=CC=C1C2=NC3=CC=CC=C3O2",
  "2-(2-Pyridyl)benzoxazole": "C1(C2=NC=CC=C2)=NC3=CC=CC=C3O1",
  "2-(2-Pyridyl)indole": "C(N1)(C2=NC=CC=C2)=CC3=C1C=CC=C3",
  "2-(4-Thiazolyl)benzimidazole": "C1(C2=CSC=N2)=NC3=CC=CC=C3N1",
  "2,2'-(Methylimino)bis(N,N-di-n-octylacetamide)": "CN(CC(N(CCCCCCCC)CCCCCCCC)=O)CC(N(CCCCCCCC)CCCCCCCC)=O",
  "2,2':6',2''-Terpyridine": "C1(C2=NC(C3=NC=CC=C3)=CC=C2)=NC=CC=C1",
  "2,2':6',2''-Terpyridine-4'-carboxylic Acid": "O=C(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)O",
  "2,2'-Bi(1,8-naphthyridine)": "C1(C2=NC3=NC=CC=C3C=C2)=NC4=NC=CC=C4C=C1",
  "2,2'-Bi-4-lepidine": "CC1=CC(C2=NC3=CC=CC=C3C(C)=C2)=NC4=C1C=CC=C4",
  "2,2'-Bicinchoninic Acid Dipotassium Salt Hydrate [for Determination of Cu]": "O=C([O-])C1=CC(C2=NC3=CC=CC=C3C(C([O-])=O)=C2)=NC4=CC=CC=C14.[H]O[H].[K+].[K+]",
  "2,2'-Biimidazole": "C1(C2=NC=CN2)=NC=CN1",
  "2,2'-Bipyrazine": "C1(C2=NC=CN=C2)=NC=CN=C1",
  "2,2'-Bipyridine-3,3'-dicarboxylic Acid": "O=C(C1=CC=CN=C1C2=NC=CC=C2C(O)=O)O",
  "2,2'-Bipyridine-3,3'-diol": "OC1=CC=CN=C1C2=NC=CC=C2O",
  "2,2'-Bipyridine-4,4'-dicarboxylic Acid": "O=C(C1=CC(C2=NC=CC(C(O)=O)=C2)=NC=C1)O",
  "2,2'-Bipyridine-5,5'-dicarboxylic Acid": "O=C(C1=CN=C(C2=NC=C(C(O)=O)C=C2)C=C1)O",
  "2,2'-Bipyridine-6,6'-dicarboxylic Acid": "O=C(C1=CC=CC(C2=NC(C(O)=O)=CC=C2)=N1)O",
  "2,2'-Bipyridine-6,6'-diol": "OC1=CC=CC(C2=NC(O)=CC=C2)=N1",
  "2,2'-Bipyridine-6-carbonitrile": "N#CC1=CC=CC(C2=NC=CC=C2)=N1",
  "2,2'-Bipyridyl": "C1(C2=NC=CC=C2)=NC=CC=C1",
  "2,2'-Bipyridyl 1,1'-Dioxide": "[O-][N+]1=CC=CC=C1C2=CC=CC=[N+]2[O-]",
  "2,2'-Bipyrimidyl": "C1(C2=NC=CC=N2)=NC=CC=N1",
  "2,2'-Biquinoline": "C1(C2=NC3=CC=CC=C3C=C2)=NC4=CC=CC=C4C=C1",
  "2,2'-Diamino-4,4'-bithiazole": "NC1=NC(C2=CSC(N)=N2)=CS1",
  "2,2'-Methylenebisbenzothiazole": "C1(CC2=NC3=CC=CC=C3S2)=NC4=CC=CC=C4S1",
  "2,3,7,8,12,13,17,18-Octaethylporphyrin": "CCC1=C(CC)C2=NC1=CC3=C(CC)C(CC)=C(N3)C=C4C(CC)=C(CC)C(C=C5C(CC)=C(CC)C(N5)=C2)=N4",
  "2,3,7,8,12,13,17,18-Octafluoro-5,10,15,20-tetrakis(pentafluorophenyl)porphyrin": "FC1=C(F)C(F)=C(F)C(F)=C1C2=C3C(F)=C(F)C(C(C4=C(F)C(F)=C(F)C(F)=C4F)=C(N5)C(F)=C(F)C5=C(C6=C(F)C(F)=C(F)C(F)=C6F)C7=NC(C(F)=C7F)=C(C8=C(F)C(F)=C(F)C(F)=C8F)C9=C(F)C(F)=C2N9)=N3",
  "2,3-Lutidine": "CC1=NC=CC=C1C",
  "2,4,6-Tri(2-pyridyl)-1,3,5-triazine": "C1(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=N1",
  "2,4,6-Tri(4-pyridyl)-1,3,5-triazine (purified by sublimation)": "C1(C2=CC=NC=C2)=NC(C3=CC=NC=C3)=NC(C4=CC=NC=C4)=N1",
  "2,4-Dibromo-6-[(E)-[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]phenol": "OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C(Br)C=C1Br",
  "2,4-Dibromo-6-[[[[(4S,5S)-4,5-dihydro-4,5-diphenyl-1-tosyl-1H-imidazol-2-yl]methyl][(S)-1-phenylethyl]amino]methyl]phenol": "OC1=C(CN(CC2=N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2S(=O)(C5=CC=C(C)C=C5)=O)[C@H](C6=CC=CC=C6)C)C=C(Br)C=C1Br",
  "2,4-Lutidine": "CC1=NC=CC(C)=C1",
  "2,5-Diphenylpyridine": "C1(C2=CC=CC=C2)=NC=C(C3=CC=CC=C3)C=C1",
  "2,5-Lutidine": "CC1=NC=C(C)C=C1",
  "2,6-Bis(2-benzimidazolyl)pyridine": "C1(C2=NC3=CC=CC=C3N2)=NC(C4=NC5=CC=CC=C5N4)=CC=C1",
  "2,6-Bis(2-pyridyl)-4(1H)-pyridone": "O=C1C=C(C2=NC=CC=C2)NC(C3=NC=CC=C3)=C1",
  "2,6-Bis[(2R,4S,5S)-1-benzyl-4,5-diphenylimidazolidin-2-yl]pyridine": "C1([C@@H]2N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2CC5=CC=CC=C5)=NC([C@@H]6N[C@@H](C7=CC=CC=C7)[C@H](C8=CC=CC=C8)N6CC9=CC=CC=C9)=CC=C1",
  "2,6-Bis[(2S,4S)-4-methyl-5,5-diphenyloxazolidin-2-yl]pyridine": "C[C@@H]1N[C@H](C2=CC=CC([C@@H]3OC(C4=CC=CC=C4)(C5=CC=CC=C5)[C@H](C)N3)=N2)OC1(C6=CC=CC=C6)C7=CC=CC=C7",
  "2,6-Bis[(2S,5S)-4,4-diphenyl-1-aza-3-oxabicyclo[3.3.0]octan-2-yl]pyridine": "[H][C@]12C(C3=CC=CC=C3)(C4=CC=CC=C4)O[C@@H](C5=CC=CC([C@H]6N7CCC[C@@]7([H])C(C8=CC=CC=C8)(C9=CC=CC=C9)O6)=N5)N1CCC2",
  "2,6-Di(1-pyrazolyl)pyridine": "C1(N2N=CC=C2)=NC(N3N=CC=C3)=CC=C1",
  "2,6-Di(2H-1,2,3-triazol-4-yl)pyridine": "C1(C2=NNN=C2)=NC(C3=NNN=C3)=CC=C1",
  "2,6-Lutidine": "CC1=NC(C)=CC=C1",
  "2,9-Dibutyl-1,10-phenanthroline": "CCCCC1=NC2=C3N=C(CCCC)C=CC3=CC=C2C=C1",
  "2,9-Dichloro-1,10-phenanthroline": "ClC1=NC2=C3N=C(Cl)C=CC3=CC=C2C=C1",
  "2,9-Diphenyl-1,10-phenanthroline": "C1(C2=CC=CC=C2)=NC3=C4N=C(C5=CC=CC=C5)C=CC4=CC=C3C=C1",
  "2-Bromo-1,10-phenanthroline": "BrC1=NC2=C3N=CC=CC3=CC=C2C=C1",
  "2-Chloro-1,10-phenanthroline": "ClC1=NC2=C3N=CC=CC3=CC=C2C=C1",
  "2-Hydroxybenzaldehyde Phenylhydrazone": "OC1=CC=CC=C1/C=N/NC2=CC=CC=C2",
  "2-Methyl-1,10-phenanthroline": "CC1=NC2=C3N=CC=CC3=CC=C2C=C1",
  "2-Salicylideneaminophenol": "OC1=CC=CC=C1/N=C/C2=CC=CC=C2O",
  "3,3'-Iminobis(N,N-dimethylpropylamine)": "CN(C)CCCNCCCN(C)C",
  "3,4,7,8-Tetramethyl-1,10-phenanthroline": "CC1=C(C)C2=CC=C3C(C)=C(C)C=NC3=C2N=C1",
  "3,4-Lutidine": "CC1=C(C)C=CN=C1",
  "3,5,6,8-Tetrabromo-1,10-phenanthroline": "BrC1=CC2=C(Br)C(Br)=C3C=C(Br)C=NC3=C2N=C1",
  "3,5-Di(2-pyridyl)pyrazole": "C1(C2=NC=CC=C2)=NNC(C3=NC=CC=C3)=C1",
  "3,5-Lutidine": "CC1=CC(C)=CN=C1",
  "3,6-Di(2-pyridyl)-1,2,4,5-tetrazine": "C1(C2=NC=CC=C2)=NN=C(C3=NC=CC=C3)N=N1",
  "3,8-Dibromo-1,10-phenanthroline": "BrC1=CC2=CC=C3C=C(Br)C=NC3=C2N=C1",
  "3-Bromo-1,10-phenanthroline": "BrC1=CC2=CC=C3C=CC=NC3=C2N=C1",
  "4-([2,2':6',2''-Terpyridin]-4'-yl)benzoic Acid": "O=C(O)C1=CC=C(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)C=C1",
  "4'-(4-Bromophenyl)-2,2':6',2''-terpyridine": "BrC1=CC=C(C2=CC(C3=NC=CC=C3)=NC(C4=NC=CC=C4)=C2)C=C1",
  "4'-(4-Pyridyl)-2,2':6',2''-terpyridine": "C1(C2=NC(C3=NC=CC=C3)=CC(C4=CC=NC=C4)=C2)=NC=CC=C1",
  "4-(Bromomethyl)-4'-methyl-2,2'-bipyridyl": "CC1=CC(C2=NC=CC(CBr)=C2)=NC=C1",
  "4-(Chloromethyl)-4'-methyl-2,2'-bipyridyl": "CC1=CC(C2=NC=CC(CCl)=C2)=NC=C1",
  "4,4'-Bis(5-hexyl-2-thienyl)-2,2'-bipyridyl": "CCCCCCC1=CC=C(C2=CC(C3=NC=CC(C4=CC=C(CCCCCC)S4)=C3)=NC=C2)S1",
  "4,4'-Bis(chloromethyl)-2,2'-bipyridyl": "ClCC1=CC(C2=NC=CC(CCl)=C2)=NC=C1",
  "4,4'-Bis(hydroxymethyl)-2,2'-bipyridine": "OCC1=CC(C2=NC=CC(CO)=C2)=NC=C1",
  "4,4'-Diamino-2,2'-bipyridyl": "NC1=CC(C2=NC=CC(N)=C2)=NC=C1",
  "4,4'-Dibromo-2,2'-bipyridyl": "BrC1=CC(C2=NC=CC(Br)=C2)=NC=C1",
  "4,4'-Dimethoxy-2,2'-bipyridyl": "COC1=CC(C2=NC=CC(OC)=C2)=NC=C1",
  "4,4'-Dimethyl-2,2'-bipyridyl": "CC1=CC(C2=NC=CC(C)=C2)=NC=C1",
  "4,4'-Dimethyl-2,2'-bipyridyl 1-Oxide": "CC1=CC(C2=CC(C)=CC=[N+]2[O-])=NC=C1",
  "4,4'-Dinonyl-2,2'-bipyridyl": "CCCCCCCCCC1=CC(C2=NC=CC(CCCCCCCCC)=C2)=NC=C1",
  "4,4'-Di-tert-butyl-2,2'-bipyridyl": "CC(C1=CC(C2=NC=CC(C(C)(C)C)=C2)=NC=C1)(C)C",
  "4,5-Diazafluoren-9-one": "O=C1C2=CC=CN=C2C3=C1C=CC=N3",
  "4,5-Diazafluorene-9-one O-(p-Toluenesulfonyl)oxime": "O=S(ON=C1C2=CC=CN=C2C3=C1C=CC=N3)(C4=CC=C(C)C=C4)=O",
  "4,7-Dibromo-1,10-phenanthroline Hydrate": "BrC1=CC=NC2=C3N=CC=C(Br)C3=CC=C12.[H]O[H]",
  "4,7-Dichloro-1,10-phenanthroline Hydrate": "ClC1=CC=NC2=C3N=CC=C(Cl)C3=CC=C12.[H]O[H]",
  "4,7-Dihydroxy-1,10-phenanthroline": "OC1=CC=NC2=C3N=CC=C(O)C3=CC=C12",
  "4,7-Dimethyl-1,10-phenanthroline": "CC1=CC=NC2=C3N=CC=C(C)C3=CC=C12",
  "4'-Bromo-2,2':6',2''-terpyridine": "BrC1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1",
  "4'-Chloro-2,2':6',2''-terpyridine": "ClC1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1",
  "4-Fluoro-N-salicylideneaniline": "OC1=CC=CC=C1/C=N/C2=CC=C(F)C=C2",
  "4-Hydroxymethyl-4'-methyl-2,2'-bipyridyl": "CC1=CC(C2=NC=CC(CO)=C2)=NC=C1",
  "5,10,15,20-Tetra(4-pyridyl)porphyrin": "C1(C2=CC=NC=C2)=C3C=CC(C(C4=CC=NC=C4)=C5C=CC(N5)=C(C6=CC=NC=C6)C(C=C7)=NC7=C(C8=CC=NC=C8)C9=CC=C1N9)=N3",
  "5,10,15,20-Tetrakis(2,4,6-trimethylphenyl)porphyrin": "CC1=C(C2=C3C=CC(C(C4=C(C)C=C(C)C=C4C)=C(N5)C=CC5=C(C6=C(C)C=C(C)C=C6C)C7=NC(C=C7)=C(C8=C(C)C=C(C)C=C8C)C9=CC=C2N9)=N3)C(C)=CC(C)=C1",
  "5,10,15,20-Tetrakis(2,6-dichlorophenyl)porphyrin": "ClC1=C(C2=C3C=CC(C(C4=C(Cl)C=CC=C4Cl)=C(N5)C=CC5=C(C6=C(Cl)C=CC=C6Cl)C7=NC(C=C7)=C(C8=C(Cl)C=CC=C8Cl)C9=CC=C2N9)=N3)C(Cl)=CC=C1",
  "5,10,15,20-Tetrakis(3,5-dihydroxyphenyl)porphyrin": "OC1=CC(O)=CC(C2=C3C=CC(C(C4=CC(O)=CC(O)=C4)=C(N5)C=CC5=C(C6=CC(O)=CC(O)=C6)C7=NC(C=C7)=C(C8=CC(O)=CC(O)=C8)C9=CC=C2N9)=N3)=C1",
  "5,10,15,20-Tetrakis(3,5-dimethoxyphenyl)porphyrin": "COC1=CC(OC)=CC(C2=C3C=CC(C(C4=CC(OC)=CC(OC)=C4)=C(N5)C=CC5=C(C6=CC(OC)=CC(OC)=C6)C7=NC(C=C7)=C(C8=CC(OC)=CC(OC)=C8)C9=CC=C2N9)=N3)=C1",
  "5,10,15,20-Tetrakis(4-carboxymethyloxyphenyl)porphyrin": "O=C(COC1=CC=C(C2=C3C=CC(C(C4=CC=C(OCC(O)=O)C=C4)=C(N5)C=CC5=C(C6=CC=C(OCC(O)=O)C=C6)C7=NC(C=C7)=C(C8=CC=C(OCC(O)=O)C=C8)C9=CC=C2N9)=N3)C=C1)O",
  "5,10,15,20-Tetrakis(4-hydroxyphenyl)porphyrin": "OC1=CC=C(C2=C3C=CC(C(C4=CC=C(O)C=C4)=C(N5)C=CC5=C(C6=CC=C(O)C=C6)C7=NC(C=C7)=C(C8=CC=C(O)C=C8)C9=CC=C2N9)=N3)C=C1",
  "5,10,15,20-Tetrakis(4-methoxyphenyl)porphyrin": "COC1=CC=C(C2=C3C=CC(C(C4=CC=C(OC)C=C4)=C(N5)C=CC5=C(C6=CC=C(OC)C=C6)C7=NC(C=C7)=C(C8=CC=C(OC)C=C8)C9=CC=C2N9)=N3)C=C1",
  "5,10,15,20-Tetrakis(pentafluorophenyl)porphyrin": "FC1=C(F)C(F)=C(F)C(F)=C1C2=C3C=CC(C(C4=C(F)C(F)=C(F)C(F)=C4F)=C(N5)C=CC5=C(C6=C(F)C(F)=C(F)C(F)=C6F)C7=NC(C=C7)=C(C8=C(F)C(F)=C(F)C(F)=C8F)C9=CC=C2N9)=N3",
  "5,10,15,20-Tetrakis(p-tolyl)porphyrin": "CC1=CC=C(C2=C3C=CC(C(C4=CC=C(C)C=C4)=C(N5)C=CC5=C(C6=CC=C(C)C=C6)C7=NC(C=C7)=C(C8=CC=C(C)C=C8)C9=CC=C2N9)=N3)C=C1",
  "5,5'-Bis(trifluoromethyl)-2,2'-bipyridyl": "FC(C1=CN=C(C2=NC=C(C(F)(F)F)C=C2)C=C1)(F)F",
  "5,5'-Dibromo-2,2'-bipyridyl": "BrC1=CN=C(C2=NC=C(Br)C=C2)C=C1",
  "5,5'-Dimethyl-2,2'-bipyridyl": "CC1=CN=C(C2=NC=C(C)C=C2)C=C1",
  "5,6-Dimethyl-1,10-phenanthroline": "CC1=C2C=CC=NC2=C3N=CC=CC3=C1C",
  "5-Bromo-1,10-phenanthroline": "BrC1=C2C=CC=NC2=C3N=CC=CC3=C1",
  "5-Chloro-1,10-phenanthroline": "ClC1=C2C=CC=NC2=C3N=CC=CC3=C1",
  "5-Methyl-1,10-phenanthroline Hydrate [for Colorimetric Determination of Iron]": "CC1=C2C=CC=NC2=C3N=CC=CC3=C1.[H]O[H]",
  "5-Nitro-1,10-phenanthroline": "O=[N+](C1=C2C=CC=NC2=C3N=CC=CC3=C1)[O-]",
  "6,6'-Bis(chloromethyl)-2,2'-bipyridyl": "ClCC1=CC=CC(C2=NC(CCl)=CC=C2)=N1",
  "6,6'-Diamino-2,2'-bipyridyl": "NC1=CC=CC(C2=NC(N)=CC=C2)=N1",
  "6,6'-Dibromo-2,2'-bipyridyl": "BrC1=CC=CC(C2=NC(Br)=CC=C2)=N1",
  "6,6'-Dicyano-2,2'-bipyridyl": "N#CC1=CC=CC(C2=NC(C#N)=CC=C2)=N1",
  "6,6'-Dimethyl-2,2'-bipyridyl": "CC1=CC=CC(C2=NC(C)=CC=C2)=N1",
  "6-Bromo-2,2'-bipyridyl": "BrC1=CC=CC(C2=NC=CC=C2)=N1",
  "6-Bromo-4,4'-dimethyl-2,2'-bipyridyl": "CC1=CC(C2=NC(Br)=CC(C)=C2)=NC=C1",
  "Bathophenanthroline": "C1(C2=CC=CC=C2)=CC=NC3=C4N=CC=C(C5=CC=CC=C5)C4=CC=C13",
  "Bathophenanthroline (purified by sublimation)": "C1(C2=CC=CC=C2)=CC=NC3=C4N=CC=C(C5=CC=CC=C5)C4=CC=C13",
  "Bathophenanthrolinedisulfonic Acid Disodium Salt Hydrate [for Determination of Ferrous Ion]": "O=S(C1=NC2=C3N=CC=C(C4=CC=CC=C4)C3=CC=C2C(C5=CC=CC=C5)=C1S(=O)([O-])=O)([O-])=O.[H]O[H].[Na+].[Na+]",
  "Bicinchoninic Acid Disodium Salt Hydrate": "O=C([O-])C1=C(C2=C(C([O-])=O)C3=CC=CC=C3N=C2)C=NC4=CC=CC=C14.[H]O[H].[Na+].[Na+]",
  "Diethyl 2,2':6',2''-Terpyridine-4'-phosphonate": "O=P(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)(OCC)OCC",
  "Diethylenetriamine": "NCCNCCN",
  "Dimethyl 2,2'-Bipyridine-4,4'-dicarboxylate": "O=C(C1=CC(C2=NC=CC(C(OC)=O)=C2)=NC=C1)OC",
  "Dimethyl 2,2'-Bipyridine-6,6'-dicarboxylate": "O=C(C1=CC=CC(C2=NC(C(OC)=O)=CC=C2)=N1)OC",
  "Dipyrido[3,2-a:2',3'-c]phenazine": "C12=CC=CN=C1C3=NC=CC=C3C4=NC5=CC=CC=C5N=C42",
  "Hexamethylenetetramine": "N12CN3CN(C2)CN(C3)C1",
  "Imidazole": "C1=NC=CN1",
  "meso-Tetraphenylchlorin": "C1(C2=CC=CC=C2)=C(N3)CCC3=C(C4=CC=CC=C4)C5=NC(C=C5)=C(C6=CC=CC=C6)C(N7)=CC=C7C(C8=CC=CC=C8)=C9CCC1=N9",
  "Methyl 2,2':6',2''-Terpyridine-4'-carboxylate": "O=C(C1=CC(C2=NC=CC=C2)=NC(C3=NC=CC=C3)=C1)OC",
  "N,N,N',N'-Tetraethylethylenediamine": "CCN(CC)CCN(CC)CC",
  "N,N,N',N'-Tetrakis(2-pyridylmethyl)ethylenediamine": "N(CC1=NC=CC=C1)(CC2=NC=CC=C2)CCN(CC3=NC=CC=C3)CC4=NC=CC=C4",
  "N,N,N',N'-Tetramethyl-1,3-diaminopropane": "CN(C)CCCN(C)C",
  "N,N,N',N'-Tetramethyl-1,4-diaminobutane": "CN(C)CCCCN(C)C",
  "N,N,N',N'-Tetramethyl-1,6-diaminohexane": "CN(C)CCCCCCN(C)C",
  "N,N,N',N'-Tetramethylethylenediamine": "CN(C)CCN(C)C",
  "N,N',N''-Trimethyldiethylenetriamine": "CN(CCNC)CCNC",
  "N,N'-Bis(2-aminobenzal)ethylenediamine": "NC1=CC=CC=C1/C=N/CC/N=C/C2=CC=CC=C2N",
  "N,N'-Bis(2-pyridylmethyl)-1,2-ethylenediamine Tetrahydrochloride Dihydrate": "[H]Cl.[H]Cl.[H]Cl.[H]Cl.[H]O[H].[H]O[H].C1(CNCCNCC2=NC=CC=C2)=NC=CC=C1",
  "N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,1,2,2-tetramethylethylenediamine": "CC(/N=C/C1=CC(C(C)(C)C)=CC(C(C)(C)C)=C1O)(C)C(C)(C)/N=C/C2=CC(C(C)(C)C)=CC(C(C)(C)C)=C2O",
  "N,N'-Bis(5-hydroxysalicylidene)ethylenediamine": "OC1=CC=C(O)C=C1/C=N/CC/N=C/C2=CC(O)=CC=C2O",
  "N,N'-Bis(salicylidene)-1,2-phenylenediamine": "OC1=CC=CC=C1/C=N/C2=CC=CC=C2/N=C/C3=CC=CC=C3O",
  "N,N'-Bis(salicylidene)-1,2-propanediamine": "CC(/N=C/C1=CC=CC=C1O)C/N=C/C2=CC=CC=C2O",
  "N,N'-Bis(salicylidene)-1,3-propanediamine": "OC1=CC=CC=C1/C=N/CCC/N=C/C2=CC=CC=C2O",
  "N,N'-Bis(salicylidene)ethylenediamine": "OC1=CC=CC=C1/C=N/CC/N=C/C2=CC=CC=C2O",
  "N,N'-Dicyclohexyl-1,2-ethanediamine Hydrate": "[H]O[H].C1(NCCNC2CCCCC2)CCCCC1",
  "N,N'-Dimethylethylenediamine": "CNCCNC",
  "N,N'-Diphenylethylenediamine": "C1(NCCNC2=CC=CC=C2)=CC=CC=C1",
  "N,N'-Di-tert-butylethylenediamine": "CC(NCCNC(C)(C)C)(C)C",
  "N-Salicylideneaniline": "OC1=CC=CC=C1/C=N/C2=CC=CC=C2",
  "Phthalocyanine": "C1(C2=N3)=C(C=CC=C1)C(N2)=NC4=NC(C5=C4C=CC=C5)=NC(N6)=C7C=CC=CC7=C6N=C8C9=C(C=CC=C9)C3=N8",
  "Phthalocyanine (purified by sublimation)": "C1(C2=N3)=C(C=CC=C1)C(N2)=NC4=NC(C5=C4C=CC=C5)=NC(N6)=C7C=CC=CC7=C6N=C8C9=C(C=CC=C9)C3=N8",
  "Quinuclidine": "N12CCC(CC2)CC1",
  "Tetraethyl 1,4,8,11-Tetraazacyclotetradecane-1,4,8,11-tetraacetate": "O=C(OCC)CN1CCN(CC(OCC)=O)CCCN(CC(OCC)=O)CCN(CC(OCC)=O)CCC1",
  "Tetraphenylporphyrin (Chlorin free)": "C1(C2=CC=CC=C2)=C3C=CC(C(C4=CC=CC=C4)=C5C=CC(N5)=C(C6=CC=CC=C6)C(C=C7)=NC7=C(C8=CC=CC=C8)C9=CC=C1N9)=N3",
  "trans-2,6-Diisopropyl-N-(2-pyridylmethylene)aniline": "CC(C1=C(C(C(C)C)=CC=C1)/N=C/C2=NC=CC=C2)C",
  "Triethylenetetramine": "NCCNCCNCCN",
  "Trimethyl 2,2':6',2''-Terpyridine-4,4',4''-tricarboxylate": "O=C(C1=CC(C2=NC(C3=NC=CC(C(OC)=O)=C3)=CC(C(OC)=O)=C2)=NC=C1)OC",
  "Tris(1-pyrazolyl)methane": "C(N1N=CC=C1)(N2N=CC=C2)N3N=CC=C3",
  "Tris(2-aminoethyl)amine": "NCCN(CCN)CCN",
  "Tris(2-benzimidazolylmethyl)amine": "N(CC1=NC2=CC=CC=C2N1)(CC3=NC4=CC=CC=C4N3)CC5=NC6=CC=CC=C6N5",
  "Tris(2-pyridylmethyl)amine": "N(CC1=NC=CC=C1)(CC2=NC=CC=C2)CC3=NC=CC=C3",
  "Tris(3,5-dimethyl-1-pyrazolyl)methane": "CC1=CC(C)=NN1C(N2N=C(C)C=C2C)N3N=C(C)C=C3C",
  "Tris(3-hydroxypropyltriazolylmethyl)amine": "OCCCC(N(C(CCCO)C1=CNN=N1)C(CCCO)C2=CNN=N2)C3=CNN=N3",
  "Tris[(1-benzyl-1H-1,2,3-triazol-4-yl)methyl]amine": "N(CC1=CN(CC2=CC=CC=C2)N=N1)(CC3=CN(CC4=CC=CC=C4)N=N3)CC5=CN(CC6=CC=CC=C6)N=N5",
  "Tris[2-(dimethylamino)ethyl]amine": "CN(CCN(CCN(C)C)CCN(C)C)C",
  "Tri-tert-butyl 1,4,7,10-Tetraazacyclododecane-1,4,7,10-tetraacetate": "O=C(OC(C)(C)C)CN1CCN(CC(OC(C)(C)C)=O)CCN(CC(OC(C)(C)C)=O)CCN(CC([O-])=O)CC1",
  "Tri-tert-butyl 1,4,7,10-Tetraazacyclododecane-1,4,7-triacetate": "O=C(OC(C)(C)C)CN1CCN(CC(OC(C)(C)C)=O)CCN(CC(OC(C)(C)C)=O)CCNCC1",
  "Ethylene diamine": "NCCN",
  "1,1'-Bi-2-naphthol": "OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3O",
  "6,8-Bis(benzylthio)octanoic Acid": "O=C(O)CCCCC(SCC1=CC=CC=C1)CCSCC2=CC=CC=C2",
  "(2-Bromophenyl)diphenylphosphine": "BrC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "(2R,3R)-(-)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@H]([C@@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(2R,3R)-(-)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": "C1(C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1",
  "(2S,3S)-(-)-Bis(diphenylphosphino)butane": "C[C@H](P(C1=CC=CC=C1)C2=CC=CC=C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)C",
  "(2S,3S)-(+)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@@H]([C@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(2S,3S)-(+)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": "C1(C2)[C@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1",
  "(4-Dimethylaminophenyl)di-tert-butylphosphine": "CC(P(C1=CC=C(N(C)C)C=C1)C(C)(C)C)(C)C",
  "(Pentafluorophenyl)diphenylphosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "(R)-(+)-2-Diphenylphosphino-2'-methoxy-1,1'-binaphthyl": "COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3P(C5=CC=CC=C5)C6=CC=CC=C6",
  "(R)-N,N-Dimethyl-1-[(S)-1',2-bis(diphenylphosphino)ferrocenyl]ethylamine": "C[C@@H](N(C)C)c1c(P(C2=CC=CC=C2)C3=CC=CC=C3)ccc1.[Del][Fe][Del].c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "(R)-N,N-Dimethyl-1-[(S)-2-(diphenylphosphino)ferrocenyl]ethylamine": "CN(C)[C@H](c1cccc1)CP(C2=CC=CC=C2)C3=CC=CC=C3.[Del][Fe][Del].c4cccc4",
  "(R,R)-(-)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@@](C1=NC2=CC=CC=C2N=C1[P@@](C(C)(C)C)C)C(C)(C)C",
  "(R,R)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@](CC[P@@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "(R,R'')-2,2''-Bis(diphenylphosphino)-1,1''-biferrocene": "[Del][Fe][Del].[Del][Fe][Del].P(C1=CC=CC=C1)(c2cccc2c3cccc3P(C4=CC=CC=C4)C5=CC=CC=C5)C6=CC=CC=C6.c7cccc7.c8cccc8",
  "(S)-N,N-Dimethyl-1-[(R)-1',2-bis(diphenylphosphino)ferrocenyl]ethylamine": "C[C@@H](N(C)C)c1c(P(C2=CC=CC=C2)C3=CC=CC=C3)ccc1.[Del][Fe][Del].c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "(S)-N,N-Dimethyl-1-[(R)-2-(diphenylphosphino)ferrocenyl]ethylamine": "CN(C)[C@@H](CP(C1=CC=CC=C1)C2=CC=CC=C2)c3cccc3.[Del][Fe][Del].c4cccc4",
  "(S,S)-(+)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@](C1=NC2=CC=CC=C2N=C1[P@](C(C)(C)C)C)C(C)(C)C",
  "(S,S)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@@](CC[P@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "(S,S)-1,2-Bis[(tert-butyl)methylphosphino]ethane Bis(borane)": "C[P@@](CC[P@@](C(C)(C)C)C)C(C)(C)C.B.B",
  "1,1'-Bis(diisopropylphosphino)ferrocene": "CC(C)P(c1cccc1)C(C)C.CC(C)P(c2cccc2)C(C)C.[Del][Fe][Del]",
  "1,1'-Bis(diphenylphosphino)ferrocene": "[Del][Fe][Del].c1(P(C2=CC=CC=C2)C3=CC=CC=C3)cccc1.c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "1,1'-Bis(di-tert-butylphosphino)ferrocene": "CC(C)(C)P(c1cccc1)C(C)(C)C.CC(C)(C)P(c2cccc2)C(C)(C)C.[Del][Fe][Del]",
  "1,2,3,4,5-Pentaphenyl-1'-(di-tert-butylphosphino)ferrocene": "CC(C)(C)P(C(C)(C)C)c1cccc1.[Del][Fe][Del].c2(C3=CC=CC=C3)c(C4=CC=CC=C4)c(C5=CC=CC=C5)c(C6=CC=CC=C6)c2C7=CC=CC=C7",
  "1,2-Bis(dimethylphosphino)ethane": "CP(CCP(C)C)C",
  "1,2-Bis(diphenylphosphino)benzene": "C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=CC=C1P(C4=CC=CC=C4)C5=CC=CC=C5",
  "1,2-Bis(diphenylphosphino)ethane": "P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,2-Bis[bis(pentafluorophenyl)phosphino]ethane": "FC1=C(F)C(F)=C(F)C(F)=C1P(CCP(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F)C4=C(F)C(F)=C(F)C(F)=C4F",
  "1,3-Bis(2,6-diisopropylphenyl)-1,3,2-diazaphospholidine 2-Oxide": "CC(C1=C(N2P(N(C3=C(C(C)C)C=CC=C3C(C)C)CC2)=O)C(C(C)C)=CC=C1)C",
  "1,3-Bis(diphenylphosphino)propane": "P(CCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,3-Bis[(di-tert-butylphosphino)oxy]benzene": "CC(P(OC1=CC(OP(C(C)(C)C)C(C)(C)C)=CC=C1)C(C)(C)C)(C)C",
  "1,4-Bis(diphenylphosphino)butane": "P(CCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,5-Bis(diphenylphosphino)pentane": "P(CCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,6-Bis(diphenylphosphino)hexane": "P(CCCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,8-Bis(diphenylphosphino)naphthalene": "C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=C4C(P(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC4=CC=C1",
  "1-[2-(Di-tert-butylphosphino)phenyl]-3,5-diphenyl-1H-pyrazole": "CC(P(C1=CC=CC=C1N2N=C(C3=CC=CC=C3)C=C2C4=CC=CC=C4)C(C)(C)C)(C)C",
  "1-Methyl-1-[4-(diphenylphosphino)benzyl]pyrrolidinium Bromide": "C[N+]1(CC2=CC=C(P(C3=CC=CC=C3)C4=CC=CC=C4)C=C2)CCCC1.[Br-]",
  "2-(Dicyclohexylphosphino)-2'-(dimethylamino)biphenyl": "CN(C1=CC=CC=C1C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C",
  "2-(Dicyclohexylphosphino)biphenyl": "C1(C2=CC=CC=C2)=CC=CC=C1P(C3CCCCC3)C4CCCCC4",
  "2-(Diphenylphosphino)benzaldehyde": "O=CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "2-(Diphenylphosphino)benzoic Acid": "O=C(O)C1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "2-(Diphenylphosphino)benzonitrile": "N#CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "2-(Diphenylphosphino)biphenyl": "C1(C2=CC=CC=C2)=CC=CC=C1P(C3=CC=CC=C3)C4=CC=CC=C4",
  "2-(Di-tert-butylphosphino)biphenyl": "CC(P(C1=CC=CC=C1C2=CC=CC=C2)C(C)(C)C)(C)C",
  "2,2'-Bis(diphenylphosphino)biphenyl": "C1(C2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6",
  "2-Dicyclohexylphosphino-2',4',6'-triisopropylbiphenyl": "CC(C1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(C(C)C)=CC(C(C)C)=C1)C",
  "2-Dicyclohexylphosphino-2',6'-diisopropoxybiphenyl": "CC(OC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC(C)C)=CC=C1)C",
  "2-Dicyclohexylphosphino-2',6'-dimethoxybiphenyl": "COC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC)=CC=C1",
  "2-Di-tert-butylphosphino-2',4',6'-triisopropylbiphenyl": "CC(C1=C(C2=CC=CC=C2P(C(C)(C)C)C(C)(C)C)C(C(C)C)=CC(C(C)C)=C1)C",
  "3-(Diphenylphosphino)-1-propylamine": "NCCCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "4-(Dimethylamino)phenyldiphenylphosphine": "CN(C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1)C",
  "4-(Diphenylphosphino)benzoic Acid": "O=C(O)C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1",
  "4,4,5,5-Tetramethyl-1,3,2-dioxaphospholane 2-Oxide": "CC1(C)C(C)(C)OP(O1)=O",
  "4,5-Bis(diphenylphosphino)-9,9-dimethylxanthene": "CC1(C)C2=C(OC3=C1C=CC=C3P(C4=CC=CC=C4)C5=CC=CC=C5)C(P(C6=CC=CC=C6)C7=CC=CC=C7)=CC=C2",
  "4,6-Bis(diphenylphosphino)phenoxazine": "C(C=CC=C1N2)(P(C3=CC=CC=C3)C4=CC=CC=C4)=C1OC5=C2C=CC=C5P(C6=CC=CC=C6)C7=CC=CC=C7",
  "Bis(diphenylphosphino)methane": "P(CP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "Bis(pentafluorophenyl)phenylphosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=CC=CC=C3",
  "Bis[2-(diphenylphosphino)phenyl] Ether": "C1(OC2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6",
  "Cyclohexyldiphenylphosphine": "P(C1=CC=CC=C1)(C2CCCCC2)C3=CC=CC=C3",
  "Di-1-adamantylphosphine": "C12CC3(PC45CC6CC(C5)CC(C6)C4)CC(C2)CC(C3)C1",
  "Dicyclohexyl(2,4,6-triisopropylphenyl)phosphine": "CC(C1=C(P(C2CCCCC2)C3CCCCC3)C(C(C)C)=CC(C(C)C)=C1)C",
  "Dicyclohexylphenylphosphine": "P(C1CCCCC1)(C2CCCCC2)C3=CC=CC=C3",
  "Diethylphenylphosphine": "CCP(CC)C1=CC=CC=C1",
  "Diphenyl-2-pyridylphosphine": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=NC=CC=C3",
  "Diphenylpropylphosphine": "CCCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Di-tert-butylphenylphosphine": "CC(P(C(C)(C)C)C1=CC=CC=C1)(C)C",
  "Ethyldiphenylphosphine": "CCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Isopropyldiphenylphosphine": "CC(P(C1=CC=CC=C1)C2=CC=CC=C2)C",
  "Methoxymethyl(diphenyl)phosphine Oxide": "COCP(C1=CC=CC=C1)(C2=CC=CC=C2)=O",
  "Methyl(diphenyl)phosphine Oxide": "CP(C1=CC=CC=C1)(C2=CC=CC=C2)=O",
  "Methyldiphenylphosphine": "CP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Sodium Diphenylphosphinobenzene-3-sulfonate": "O=S(C1=CC(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=C1)([O-])=O.[Na+]",
  "trans-1,2-Bis(diphenylphosphino)ethylene": "P(/C=C/P(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "Tri(2-furyl)phosphine": "P(C1=CC=CO1)(C2=CC=CO2)C3=CC=CO3",
  "Tri(2-thienyl)phosphine": "P(C1=CC=CS1)(C2=CC=CS2)C3=CC=CS3",
  "Tri(m-tolyl)phosphine": "CC1=CC(P(C2=CC=CC(C)=C2)C3=CC=CC(C)=C3)=CC=C1",
  "Tri(o-tolyl)phosphine": "CC1=CC=CC=C1P(C2=CC=CC=C2C)C3=CC=CC=C3C",
  "Tri(p-tolyl)phosphine": "CC1=CC=C(P(C2=CC=C(C)C=C2)C3=CC=C(C)C=C3)C=C1",
  "Tributylphosphine Oxide": "CCCCP(CCCC)(CCCC)=O",
  "Tricyclohexylphosphine": "P(C1CCCCC1)(C2CCCCC2)C3CCCCC3",
  "Tricyclohexylphosphonium Tetrafluoroborate": "F[B-](F)(F)F.[PH+](C1CCCCC1)(C2CCCCC2)C3CCCCC3",
  "Trihexylphosphine": "CCCCCCP(CCCCCC)CCCCCC",
  "Trimethylphosphine": "CP(C)C",
  "Tri-n-octylphosphine": "CCCCCCCCP(CCCCCCCC)CCCCCCCC",
  "Tri-n-octylphosphine Oxide": "CCCCCCCCP(CCCCCCCC)(CCCCCCCC)=O",
  "Triphenyl Phosphite": "P(OC1=CC=CC=C1)(OC2=CC=CC=C2)OC3=CC=CC=C3",
  "Triphenylphosphine": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylphosphine Oxide": "O=P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Tris(2,4-di-tert-butylphenyl) Phosphite": "CC(C1=CC=C(OP(OC2=CC=C(C(C)(C)C)C=C2C(C)(C)C)OC3=CC=C(C(C)(C)C)C=C3C(C)(C)C)C(C(C)(C)C)=C1)(C)C",
  "Tris(2,6-dimethoxyphenyl)phosphine": "COC1=C(P(C2=C(OC)C=CC=C2OC)C3=C(OC)C=CC=C3OC)C(OC)=CC=C1",
  "Tris(2-carboxyethyl)phosphine Hydrochloride": "O=C(CCP(CCC(O)=O)CCC(O)=O)O.[H]Cl",
  "Tris(2-methoxyphenyl)phosphine": "COC1=CC=CC=C1P(C2=CC=CC=C2OC)C3=CC=CC=C3OC",
  "Tris(4-fluorophenyl)phosphine": "FC1=CC=C(P(C2=CC=C(F)C=C2)C3=CC=C(F)C=C3)C=C1",
  "Tris(4-methoxyphenyl)phosphine": "COC1=CC=C(P(C2=CC=C(OC)C=C2)C3=CC=C(OC)C=C3)C=C1",
  "Tris(pentafluorophenyl)phosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F",
  "Tris[3,5-bis(trifluoromethyl)phenyl]phosphine": "FC(C1=CC(C(F)(F)F)=CC(P(C2=CC(C(F)(F)F)=CC(C(F)(F)F)=C2)C3=CC(C(F)(F)F)=CC(C(F)(F)F)=C3)=C1)(F)F",
  "1-(2-Mesitylene)-1,3-butanedione": "CC(CC(C1=C(C)C=C(C)C=C1C)=O)=O",
  "1-(4-Bromophenyl)-1,3-butanedione": "CC(CC(C1=CC=C(Br)C=C1)=O)=O",
  "1-(4-Chlorophenyl)-4,4,4-trifluoro-1,3-butanedione": "O=C(C1=CC=C(Cl)C=C1)CC(C(F)(F)F)=O",
  "1-(4-Fluorophenyl)-1,3-butanedione": "CC(CC(C1=CC=C(F)C=C1)=O)=O",
  "1-(4-tert-Butylphenyl)-3-(4-methoxyphenyl)-1,3-propanedione": "O=C(C1=CC=C(C(C)(C)C)C=C1)CC(C2=CC=C(OC)C=C2)=O",
  "1,1,5,5-Tetrafluoro-2,4-pentanedione": "O=C(CC(C(F)F)=O)C(F)F",
  "1,3-Bis(4-methoxyphenyl)-1,3-propanedione": "O=C(C1=CC=C(OC)C=C1)CC(C2=CC=C(OC)C=C2)=O",
  "1,3-Di(2-pyridyl)-1,3-propanedione": "O=C(C1=NC=CC=C1)CC(C2=NC=CC=C2)=O",
  "1,3-Diphenyl-1,3-propanedione": "O=C(C1=CC=CC=C1)CC(C2=CC=CC=C2)=O",
  "1-Phenyl-1,3-butanedione": "CC(CC(C1=CC=CC=C1)=O)=O",
  "2,2-Dimethyl-6,6,7,7,8,8,8-heptafluoro-3,5-octanedione": "CC(C)(C)C(CC(C(F)(F)C(F)(F)C(F)(F)F)=O)=O",
  "2,6-Dimethyl-3,5-heptanedione": "CC(C)C(CC(C(C)C)=O)=O",
  "2-Acetylcyclohexanone": "O=C1C(C(C)=O)CCCC1",
  "2-Acetylcyclopentanone": "O=C1C(C(C)=O)CCC1",
  "2-Furoyltrifluoroacetone": "O=C(CC(C1=CC=CO1)=O)C(F)(F)F",
  "3,4-Diacetyl-2,5-hexanedione": "CC(C(C(C)=O)C(C(C)=O)C(C)=O)=O",
  "3,5-Heptanedione": "CCC(CC(CC)=O)=O",
  "3-Chloroacetylacetone": "CC(C(Cl)C(C)=O)=O",
  "3-Ethyl-2,4-pentanedione": "CC(C(CC)C(C)=O)=O",
  "3-Methyl-2,4-pentanedione": "CC(C(C)C(C)=O)=O",
  "3-Phenyl-2,4-pentanedione": "CC(C(C1=CC=CC=C1)C(C)=O)=O",
  "4,4,4-Trifluoro-1-(2-naphthyl)-1,3-butanedione": "O=C(C1=CC=C2C=CC=CC2=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(2-thienyl)-1,3-butanedione": "O=C(C1=CC=CS1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(4-methoxyphenyl)-1,3-butanedione": "O=C(C1=CC=C(OC)C=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(p-tolyl)-1,3-butanedione": "O=C(C1=CC=C(C)C=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-phenyl-1,3-butanedione": "O=C(C1=CC=CC=C1)CC(C(F)(F)F)=O",
  "6-Methyl-2,4-heptanedione": "CC(CC(CC(C)C)=O)=O",
  "9H,9H-Triacontafluoro-8,10-heptadecanedione": "O=C(CC(C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F",
  "Acetylacetone": "CC(CC(C)=O)=O",
  "Dehydroacetic Acid": "CC(C(C(C=C(O1)C)=O)C1=O)=O",
  "Dipivaloylmethane": "CC(C)(C)C(CC(C(C)(C)C)=O)=O",
  "Hexafluoroacetylacetone": "O=C(C(F)(F)C(CF)=O)C(F)(F)F",
  "Trifluoroacetylacetone": "O=C(CC(C)=O)C(F)(F)F",
  "1,2,3,4,5-Pentamethylcyclopentadiene": "CC1C(C)=C(C)C(C)=C1C",
  "1,2,3,4,5-Pentaphenyl-1,3-cyclopentadiene": "C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1C6=CC=CC=C6",
  "1,2,3,4-Tetraphenyl-1,3-cyclopentadiene": "C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1",
  "1,2-Bis(3-indenyl)ethane": "C1(CCC2=CCC3=C2C=CC=C3)=CCC4=C1C=CC=C4",
  "1,3,5,7-Cyclooctatetraene (stabilized with HQ)": "C1=C\\C=C/C=C\\C=C/1",
  "1,3,5-Cycloheptatriene": "C1=CC=CC=CC1",
  "1,3,5-Trimethylbenzene": "CC1=CC(C)=CC(C)=C1",
  "1,4-Cyclohexadiene (stabilized with BHT)": "C1=CCC=CC1",
  "1,5-Cyclooctadiene [stabilized with Octadecyl 3-(3',5'-Di-tert-butyl-4'-hydroxyphenyl)propionate]": "C1=C\\CC/C=C\\CC/1",
  "1,5-Hexadiene": "C=CCCC=C",
  "1H-Cyclopenta[l]phenanthrene": "C12=CC=CC=C1C(CC=C3)=C3C4=C2C=CC=C4",
  "2,5-Norbornadiene (stabilized with BHT)": "C12C=CC(C2)C=C1",
  "2-Methylbenzo[e]indene (mixture of 1H- and 3H-form)": "N/A",
  "2-Methylcyclopenta[l]phenanthrene": "CC1=CC2=C3CC=CC=C3C4=C(C2=C1)C=CC=C4",
  "2-Norbornene": "C12C=CC(C2)CC1",
  "Acenaphthylene": "C12=CC=CC3=C1C(C=C2)=CC=C3",
  "Cyclooctene": "C1=C\\CCCCCC/1",
  "Dibenzo[a,e]cyclooctene": "C12=CC=CC=C1/C=C\\C3=CC=CC=C3/C=C\\2",
  "Dicyclopentadiene (stabilized with BHT) [precursor to Cyclopentadiene]": "C12C=CCC1C3C=CC2C3",
  "Hexamethylbenzene": "CC1=C(C)C(C)=C(C)C(C)=C1C",
  "Indan": "C12=C(CCC2)C=CC=C1",
  "Indene": "C12=C(CC=C2)C=CC=C1",
  "p-Cymene": "CC1=CC=C(C(C)C)C=C1",
  "Tetraphenylcyclopentadienone": "O=C1C(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C1C5=CC=CC=C5",
  "trans,trans-1,5-Bis(4-fluorophenyl)-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(F)C=C1)/C=C/C2=CC=C(F)C=C2",
  "trans,trans-1,5-Bis(4-methoxyphenyl)-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(OC)C=C1)/C=C/C2=CC=C(OC)C=C2",
  "trans,trans-1,5-Bis[4-(trifluoromethyl)phenyl]-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(C(F)(F)F)C=C1)/C=C/C2=CC=C(C(F)(F)F)C=C2",
  "trans,trans-1,5-Diphenyl-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2",
}