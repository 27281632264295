export const lewisAcids = {
  "(±)-10-Camphorsulfonic Acid": "O=C(CC(C(C)1C)CC2)C21CS(=O)(O)=O",
  "1,2-Benzenedisulfonic Imide": "O=S(C1=CC=CC=C1S2(=O)=O)(N2)=O",
  "2-Hydroxybenzoic Acid": "O=C(O)C1=CC=CC=C1O",
  "5-Sulfosalicylic Acid Dihydrate": "OC1=CC=C(S(=O)(O)=O)C=C1C(O)=O.[H]O[H].[H]O[H]",
  "Acetic Acid": "CC(O)=O",
  "Aluminum Bromide Anhydrous": "[Al+3].[Br-].[Br-].[Br-]",
  "Aluminum Isopropoxide": "CC([O-])C.CC([O-])C.CC([O-])C.[Al+3]",
  "Aluminum(III) Chloride": "[Al+3].[Cl-].[Cl-].[Cl-]",
  "Aminomethanesulfonic Acid": "O=S(CN)(O)=O",
  "Benzenesulfonic Acid Anhydrous": "O=S(C1=CC=CC=C1)(O)=O",
  "Benzenesulfonic Acid Monohydrate": "O=S(C1=CC=CC=C1)(O)=O.[H]O[H]",
  "Benzoic Acid": "O=C(O)C1=CC=CC=C1",
  "Bis(trifluoromethanesulfonyl)imide": "O=S(C(F)(F)F)(NS(=O)(C(F)(F)F)=O)=O",
  "Boron Trifluoride": "FB(F)F",
  "Bromoacetic Acid": "O=C(O)CBr",
  "Cerium(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Ce+3]",
  "Chloroacetic Acid": "O=C(O)CCl",
  "Chlorodifluoroacetic Acid": "O=C(O)C(F)(Cl)F",
  "Citric Acid": "OC(C(O)=O)(CC(O)=O)CC(O)=O",
  "Copper(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Cu+2]",
  "Croconic Acid": "OC1=C(C(C(C1=O)=O)=O)O",
  "Dichloroacetic Acid": "O=C(O)C(Cl)Cl",
  "Dicyclohexyl(trifluoromethanesulfonyloxy)borane": "O=S(OB(C1CCCCC1)C2CCCCC2)(C(F)(F)F)=O",
  "Difluoroacetic Acid": "O=C(O)C(F)F",
  "DL-Lactic Acid": "OC(C)C(O)=O",
  "DL-Malic Acid": "OC(CC(O)=O)C(O)=O",
  "DL-α-Lipoic Acid": "O=C(CCCCC1SSCC1)O",
  "Ethylenediaminetetraacetic Acid": "O=C(CN(CCN(CC(O)=O)CC(O)=O)CC(O)=O)O",
  "Folic Acid Hydrate": "O=C(O)C(NC(C1=CC=C(NCC2=NC3=C(N=C2)N=C(N)NC3=O)C=C1)=O)CCC(O)=O.[H]O[H]",
  "Fumaric Acid": "O=C(O)/C=C/C(O)=O",
  "Gallic Acid Hydrate": "OC1=CC(C(O)=O)=CC(O)=C1O.[H]O[H]",
  "Glycolic Acid": "OCC(O)=O",
  "Hafnium(IV) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Hf+4]",
  "Hydriodic Acid": "[H]I",
  "Hydrochloric Acid": "[H]Cl",
  "Hydrogen Bromide": "[H]Br",
  "Hydrogen Chloride": "[H]Cl",
  "Hydroxylamine-O-sulfonic Acid": "NOS(O)(=O)=O",
  "L-(+)-Tartaric Acid": "O[C@H]([C@H](C(O)=O)O)C(O)=O",
  "Lanthanum(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[La+3]",
  "L-Ascorbic Acid": "OC([C@H](O1)[C@H](CO)O)=C(O)C1=O",
  "L-Lactic Acid": "OC(C)C(O)=O",
  "Maleic Acid": "O=C(O)/C=C\\C(O)=O",
  "Malonic Acid": "O=C(O)CC(O)=O",
  "Methanesulfonic Acid": "CS(=O)(O)=O",
  "Neodymium(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Nd+3]",
  "Nitrilotriacetic Acid": "O=C(O)CN(CC(O)=O)CC(O)=O",
  "Palmitic Acid": "CCCCCCCCCCCCCCCC(O)=O",
  "Phthalic Acid": "O=C(O)C1=CC=CC=C1C(O)=O",
  "Propionic Acid": "CCC(O)=O",
  "p-Toluenesulfonic Acid Monohydrate": "CC1=CC=C(S(=O)(O)=O)C=C1.[H]O[H]",
  "Pyridinium p-Toluenesulfonate": "CC1=CC=C(S(=O)([O-])=O)C=C1.C2=CC=CC=[NH+]2",
  "Scandium(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Sc+3]",
  "Silver Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.[Ag+]",
  "Sorbic Acid": "C/C=C/C=C/C(O)=O",
  "Succinic Acid": "O=C(O)CCC(O)=O",
  "Sulfanilic Acid": "O=S(O)(C1=CC=C(N)C=C1)=O",
  "Tetraisopropyl Orthotitanate": "CC(O[Ti](OC(C)C)(OC(C)C)OC(C)C)C",
  "Thioacetic Acid": "CC(O)=S",
  "Thioglycolic Acid": "SCC(O)=O",
  "Thulium(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Tm+3]",
  "Tin(IV) Chloride": "[Sn+4].[Cl-].[Cl-].[Cl-].[Cl-]",
  "Titanium(IV) Chloride": "[Ti+4].[Cl-].[Cl-].[Cl-].[Cl-]",
  "Tribromoacetic Acid": "O=C(O)C(Br)(Br)Br",
  "Trichloroacetic Acid": "O=C(O)C(Cl)(Cl)Cl",
  "Trifluoroacetic Acid": "O=C(O)C(F)(F)F",
  "Trifluoromethanesulfonic Acid": "O=S(C(F)(F)F)(O)=O",
  "Ytterbium(III) Trifluoromethanesulfonate Hydrate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[H]O[H].[Yb+3]",
  "Yttrium(III) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Y+3]",
  "Zinc(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Zn+2]",
}