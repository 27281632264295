export const oxidation = {
  "[Bis(trifluoroacetoxy)iodo]pentafluorobenzene": "FC1=C(I(OC(C(F)(F)F)=O)OC(C(F)(F)F)=O)C(F)=C(F)C(F)=C1F",
  "[Hydroxy(tosyloxy)iodo]benzene": "O=S(OI(C1=CC=CC=C1)O)(C2=CC=C(C)C=C2)=O",
  "1-Acetoxy-5-bromo-1,2-benziodoxol-3(1H)-one": "O=C1OI(OC(C)=O)C2=CC=C(Br)C=C12",
  "2,2,6,6-Tetramethylpiperidine 1-Oxyl Free Radical": "CC1(C)CCCC(C)(C)N1[O]",
  "2-Hydroxy-2-azaadamantane": "ON1C2CC3CC(C2)CC1C3",
  "2-Iodosobenzoic Acid": "O=C(O)C1=CC=CC=C1I=O",
  "2-Iodoxybenzoic Acid": "O=C(O)C1=CC=CC=C1I(=O)=O",
  "4-[2-[2-(4-Iodophenoxy)ethoxy]carbonyl]benzoyloxy-2,2,6,6-tetramethylpiperidin-1-oxyl": "[O]N1C(C)(C)CC(OC(C2=CC=CC=C2C(OCCOC3=CC=C(I)C=C3)=O)=O)CC1(C)C",
  "4-Acetamido-2,2,6,6-tetramethyl-1-oxopiperidinium Tetrafluoroborate": "O=[N+]1C(C)(C)CC(NC(C)=O)CC1(C)C.F[B-](F)(F)F",
  "4-Methylmorpholine N-Oxide": "C[N+]1([O-])CCOCC1",
  "Ammonium Cerium(IV) Nitrate": "[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[O-][N+]([O-])=O.[NH4+].[Ce+4]",
  "Bis(pyridine)iodonium Tetrafluoroborate": "[IH+]C1=NC=CC=C1.F[B-](F)(F)F.C2=NC=CC=C2",
  "Bis(tetrabutylammonium) Dichromate": "CCCC[N+](CCCC)(CCCC)CCCC.CCCC[N+](CCCC)(CCCC)CCCC.O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O",
  "Chloramine T Trihydrate": "CC1=CC=C(S(=O)([N-]Cl)=O)C=C1.[H]O[H].[H]O[H].[H]O[H].[Na+]",
  "Chloranil": "O=C(C(Cl)=C1Cl)C(Cl)=C(Cl)C1=O",
  "Cumene Hydroperoxide": "CC(C1=CC=C(OO)C=C1)C",
  "Dess-Martin Periodinane": "CC(OI1(OC(C)=O)(OC(C)=O)c2ccccc2C(O1)=O)=O",
  "Dichloramine T": "CC1=CC=C(S(=O)([N-]Cl)=O)C=C1.[Na+]",
  "Di-tert-butyl Peroxide": "CC(OOC(C)(C)C)(C)C",
  "Iodobenzene Diacetate": "IC1=CC=CC=C1.CC(O)=O.CC(O)=O",
  "Iodomesitylene Diacetate": "CC1=C(I)C(C)=CC(C)=C1.CC(O)=O.CC(O)=O",
  "N-tert-Butylbenzenesulfenamide": "CC(NSC1=CC=CC=C1)(C)C",
  "N-tert-Butylbenzenesulfinimidoyl Chloride ": "CC(/N=S(Cl)/C1=CC=CC=C1)(C)C",
  "Oxalyl Chloride": "O=C(Cl)C(Cl)=O",
  "Phosphomolybdic Acid Ethanol Solution": "O=[Mo](OP(O)(O)=O)(O)=O.CCO",
  "Phosphomolybdic Acid Hydrate": "O=[Mo](OP(O)(O)=O)(O)=O.[H]O[H]",
  "Pyridine - Sulfur Trioxide Complex": "O=S(=O)=O.C1=CC=NC=C1",
  "Pyridinium Chlorochromate": "[O-][Cr](=O)(Cl)=O.C1=CC=CC=[NH+]1",
  "Pyridinium Dichromate": "O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.C1=CC=CC=[NH+]1.C2=CC=CC=[NH+]2",
  "Pyridinium Fluorochromate": "[O-][Cr](=O)(F)=O.C1=CC=CC=[NH+]1",
  "Quinolinium Dichromate": "O=[Cr]([O-])(O[Cr](=O)([O-])=O)=O.C12=CC=C[NH+]=C1C=CC=C2.C34=CC=C[NH+]=C3C=CC=C4",
  "Silver(II) Pyridine-2-carboxylate": "O=C(C1=NC=CC=C1)[O-].O=C(C2=NC=CC=C2)[O-].[Ag+2]",
  "Sodium Dichloroisocyanurate": "ClN1C([N-]C(N(C1=O)Cl)=O)=O.[Na+]",
  "Sodium Hypochlorite Pentahydrate": "Cl[O-].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Na+]",
  "Sulfur Trioxide - Triethylamine Complex": "O=S(=O)=O.CCN(CC)CC",
  "tert-Butyl Hydroperoxide ": "CC(C)(OO)C",
  "Tetracyanoethylene": "N#CC(C#N)=C(C#N)C#N",
  "Tetrapropylammonium Perruthenate": "CCC[N+](CCC)(CCC)CCC.O=[Ru](=O)([O-])=O",
  "Trimethylamine N-Oxide Anhydrous": "C[N+](C)([O-])C",
  "Trimethylamine N-Oxide Dihydrate": "C[N+](C)([O-])C.[H]O[H].[H]O[H]",
  "Trisodium Paraperiodate": "O=I([O-])([O-])(O)(O)[O-].[Na+].[Na+].[Na+]",
}