export const catalyst = {
  "Aluminum(III) Bromide": "[Al+3].[Br-].[Br-].[Br-]",
  "Aluminum(III) Chloride": "[Al+3].[Cl-].[Cl-].[Cl-]",
  "Triphenylarsine": "[As](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Copper(I) Cyanide": "[C-]#N.[Cu+]",
  "Bromodicarbonyl[bis[2-(diisopropylphosphino)ethyl]amine]manganese(I)": "[C]=O.[C]=O.CC(P(CCNCCP(C(C)C)C(C)C)C(C)C)C.[Mn]Br",
  "Carbonyl(dihydrido)tris(triphenylphosphine)ruthenium(II)": "[C]=O.[H][Ru][H].P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9",
  "Carbonylhydridotris(triphenylphosphine)iridium(I)": "[C]=O.[Ir][H].P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9",
  "Carbonylchlorobis(triphenylphosphine)iridium(I)": "[C]=O.[Ir]Cl.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6",
  "Tetraphenylarsonium Chloride Hydrochloride Hydrate": "[H]O[H].[H]Cl.C1([As+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Cl-]",
  "Iridium(III) Chloride Hydrate": "[H]O[H].[Ir+3].[Cl-].[Cl-].[Cl-]",
  "Chloro(1,5-cyclooctadiene)iridium(I)": "[Ir]Cl.C1=C\\CC/C=C\\CC/1",
  "Chlorobis(cyclooctene)iridium(I)": "[Ir]Cl.C1=C\\CCCCCC/1.C2=C\\CCCCCC/2",
  "(1,5-Cyclooctadiene)(methoxy)iridium(I)": "[Ir]OC.C1=C\\CC/C=C\\CC/1",
  "Lead(II) Iodide": "[Pb+2].[I-].[I-]",
  "Palladium Carbon ": "[Pd]",
  "Allylpalladium(II) Chloride": "[Pd+]CC=C.[Cl-]",
  "Palladium(II) Chloride": "[Pd+2].[Cl-].[Cl-]",
  "Palladium Hydroxide": "[Pd+2].[OH-].[OH-]",
  "Platinum(IV) Oxide": "[Pt+4].[O-2].[O-2]",
  "Chloro(1,5-cyclooctadiene)rhodium(I)": "[Rh]Cl.C1=C\\CC/C=C\\CC/1",
  "Chlorobis(cyclooctene)rhodium(I)": "[Rh]Cl.C1=C\\CCCCCC/1.C2=C\\CCCCCC/2",
  "(Pentamethylcyclopentadienyl)rhodium(III) Dichloride": "[Rh+2]C1=C(C)C(C)=C(C)C1(C)C.[Cl-].[Cl-]",
  "Cyclopentadienylbis(triphenylphosphine)ruthenium(II) Chloride": "[Ru+]C1=CC=CC1.P(C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.P(C5=CC=CC=C5)(C6=CC=CC=C6)C7=CC=CC=C7.[Cl-]",
  "Benzeneruthenium(II) Chloride": "[Ru+2].C1=CC=CC=C1.[Cl-].[Cl-]",
  "Ruthenium(III) Chloride": "[Ru+3].[Cl-].[Cl-].[Cl-]",
  "Triphenylantimony": "[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylphosphine Selenide": "[Se]=P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Tin(IV) Chloride": "[Sn+4].[Cl-].[Cl-].[Cl-].[Cl-]",
  "Tellurium(IV) Oxide": "[Te+4].[O-2].[O-2]",
  "Cyclopentadienyl Thallium": "[Tl]C1=CC=CC1",
  "Zinc Bromide": "[Zn+2].[Br-].[Br-]",
  "Zinc Chloride": "[Zn+2].[Cl-].[Cl-]",
  "Lithium (E)-4-oxopent-2-en-2-olate": "C/C([O-])=C\\C(C)=O.[Li+]",
  "Methyltriphenylarsonium Iodide": "C[As+](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.[I-]",
  "2-Chloro-1,3-dimethylimidazolinium Tetrafluoroborate": "C[N+]1=C(Cl)N(C)CC1.F[B-](F)(F)F",
  "1,1'-(2,6-Pyridinediyl)bis(3-methylimidazolium) Dibromide": "C[N+]1=CN(C2=NC(N3C=C[N+](C)=C3)=CC=C2)C=C1.[Br-].[Br-]",
  "Di-tert-butylmethylphosphonium Tetraphenylborate": "C[PH+](C(C)(C)C)C(C)(C)C.C1([B-](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Chloro(1,5-hexadiene)rhodium(I)": "C=CCCC=C.[Rh]Cl",
  "Tetraphenylgermane": "C1([Ge](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Tetraphenyl Lead": "C1([Pb](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "(R)-(+)-2,2'-bis(diphenylphosphino)-1,1'-binaphthyl": "C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8",
  "[(S)-2,2'-Bis(diphenylphosphino)-1,1'-binaphthyl]ruthenium(II) Dichloride": "C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8.[Ru+2].[Cl-].[Cl-]",
  "Norbornadiene Rhodium(I) Chloride": "C12C=CC(C2)C=C1.[Rh+].[Cl-]",
  "(S)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": "CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@@]4([H])N1CCC4",
  "(R)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": "CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@]4([H])N1CCC4",
  "Bis(acetonitrile)palladium(II) Dichloride": "CC#N.CC#N.[Pd+2].[Cl-].[Cl-]",
  "Tetrakis(acetonitrile)copper(I) Tetrafluoroborate": "CC#N.CC#N.CC#N.CC#N.F[B-](F)(F)F.[Cu+]",
  "Tetrakis(acetonitrile)copper(I) Hexafluorophosphate": "CC#N.CC#N.CC#N.CC#N.F[P-](F)(F)(F)(F)F.[Cu+]",
  "Tetrakis(acetonitrile)palladium(II) Bis(trifluoromethanesulfonate)": "CC#N.CC#N.CC#N.CC#N.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Pd+2]",
  "1,3-Di-tert-butylimidazolium Tetrafluoroborate": "CC([N+]1=CN(C(C)(C)C)C=C1)(C)C.F[B-](F)(F)F",
  "1,3-Diisopropylimidazolium Tetrafluoroborate": "CC([N+]1=CN(C(C)C)C=C1)C.F[B-](F)(F)F",
  "1,3-Diisopropylimidazolinium Tetrafluoroborate": "CC([N+]1=CN(C(C)C)CC1)C.F[B-](F)(F)F",
  "Copper(I) Acetate": "CC([O-])=O.[Cu+]",
  "Iron(II) Acetate": "CC([O-])=O.CC([O-])=O.[Fe+2]",
  "Copper(II) Acetate Monohydrate": "CC([O-])=O.CC([O-])=O.[H]O[H].[Cu+2]",
  "Rhodium(II) Acetate": "CC([O-])=O.CC([O-])=O.[Rh+2]",
  "Lead Tetraacetate ": "CC([O-])=O.CC([O-])=O.CC([O-])=O.CC([O-])=O.[Pb+4]",
  "Bis(triphenylphosphine)palladium(II) Diacetate": "CC([O-])=O.CC([O-])=O.P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Pd+2]",
  "2-Chloro-1,3-bis(2,6-diisopropylphenyl)-1H-imidazolium Chloride": "CC(C1=C([N+]2=C(Cl)N(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C.[Cl-]",
  "1,3-Bis(2,6-diisopropylphenyl)imidazolium Chloride": "CC(C1=C([N+]2=CN(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C.[Cl-]",
  "1,3-Bis(2,6-diisopropylphenyl)imidazolinium Chloride": "CC(C1=C([N+]2=CN(C3=C(C(C)C)C=CC=C3C(C)C)CC2)C(C(C)C)=CC=C1)C.[Cl-]",
  "Chloro[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene]copper(I)": "CC(C1=C(N(C=CN2C3=C(C(C)C)C=CC=C3C(C)C)C2=[Cu-2]Cl)C(C(C)C)=CC=C1)C",
  "1,3-Bis(2,6-diisopropylphenyl)imidazol-2-ylidene": "CC(C1=C(N2[C]N(C3=C(C(C)C)C=CC=C3C(C)C)C=C2)C(C(C)C)=CC=C1)C",
  "1,3-Bis(2,6-diisopropylphenyl)imidazolidin-2-ylidene": "CC(C1=C(N2[C]N(C3=C(C(C)C)C=CC=C3C(C)C)CC2)C(C(C)C)=CC=C1)C",
  "3,3'-Methylenebis[1-(2,6-diisopropylphenyl)-3-imidazolium Bromide]": "CC(C1=C(N2C=C[N+](C[N+]3=CN(C4=C(C(C)C)C=CC=C4C(C)C)C=C3)=C2)C(C(C)C)=CC=C1)C.[Br-].[Br-]",
  "1,3-Di-tert-butylimidazol-2-ylidene": "CC(N1[C]N(C(C)(C)C)C=C1)(C)C",
  "3,3'-Methylenebis(1-tert-butyl-3-imidazolium Bromide)": "CC(N1C=C[N+](C[N+]2=CN(C(C)(C)C)C=C2)=C1)(C)C.[Br-].[Br-]",
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](acetanilide)palladium(II)]": "CC(NC1=CC=CC=C1)=O.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C",
  "Triphenylantimony Diacetate": "CC(O)=O.CC(O)=O.[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Bis(tri-tert-butylphosphine)palladium(0)": "CC(P(C(C)(C)C)C(C)(C)C)(C)C.CC(P(C(C)(C)C)C(C)(C)C)(C)C.[Pd]",
  "Dichloro[9,9-dimethyl-4,5-bis(diphenylphosphino)xanthene]palladium(II)": "CC1(C)C2=C(OC3=C1C=CC=C3P(C4=CC=CC=C4)C5=CC=CC=C5)C(P(C6=CC=CC=C6)C7=CC=CC=C7)=CC=C2.Cl[Pd]Cl",
  "1,3-Bis(2,4,6-trimethylphenyl)imidazolinium Chloride": "CC1=C([N+]2=CN(C3=C(C)C=C(C)C=C3C)CC2)C(C)=CC(C)=C1.[Cl-]",
  "Chloro(pentamethylcyclopentadienyl)ruthenium(II)": "CC1=C([Ru]Cl)C(C)(C)C(C)=C1C",
  "(Hexamethylbenzene)ruthenium(II) Dichloride": "CC1=C(C)C(C)=C(C)C(C)=C1C.[Ru+2].[Cl-].[Cl-]",
  "1,3-Dimesitylimidazolium Chloride": "CC1=CC(C)=CC(C)=C1[N+]2=CN(C3=C(C)C=C(C)C=C3C)C=C2.[Cl-]",
  "1,3-Dimesitylimidazol-2-ylidene": "CC1=CC(C)=CC(C)=C1N2[C]N(C3=C(C)C=C(C)C=C3C)C=C2",
  "Dichloro(p-cymene)ruthenium(II)": "CC1=CC=C(C(C)C)C=C1.Cl[Ru]Cl",
  "Nickel(II) p-Toluenesulfonate Hexahydrate": "CC1=CC=C(S(=O)([O-])=O)C=C1.CC2=CC=C(S(=O)([O-])=O)C=C2.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ni+2]",
  "Bis(tri-o-tolylphosphine)palladium(II) Dichloride": "CC1=CC=CC=C1P(C2=CC=CC=C2C)C3=CC=CC=C3C.CC4=CC=CC=C4P(C5=CC=CC=C5C)C6=CC=CC=C6C.[Pd+2].[Cl-].[Cl-]",
  "1-Methyl-3-propylimidazolium Tetrafluoroborate": "CCC[N+]1=CN(C)C=C1.F[B-](F)(F)F",
  "Tetrabutylgermane": "CCCC[Ge](CCCC)(CCCC)CCCC",
  "Tin(II) 2-Ethylhexanoate": "CCCCC(CC)C([O-])=O.CCCCC(CC)C([O-])=O.[Sn+2]",
  "1-Methyl-3-pentylimidazolium Tetrafluoroborate": "CCCCC[N+]1=CN(C)C=C1.F[B-](F)(F)F",
  "Rhodium(II) Octanoate": "CCCCCCCC([O-])=O.CCCCCCCC([O-])=O.[Rh+2]",
  "Dilithium Tetrachlorocopper(II)": "Cl[Cu-](Cl)(Cl)Cl.Cl[Cu-](Cl)(Cl)Cl.[Li+].[Li+]",
  "Dichloro(1,10-phenanthroline)copper(II)": "Cl[Cu]Cl.C12=CC=C3C=CC=NC3=C1N=CC=C2",
  "Chlorobis(ethylene)iridium(I)": "Cl[Ir-4]12(CC2)CC1",
  "Sodium Tetrachloropalladate(II)": "Cl[Pd-2](Cl)(Cl)Cl.[Na+].[Na+]",
  "Chlorobis(ethylene)rhodium(I)": "Cl[Rh-4]12(CC2)CC1",
  "Triphenylantimony Dichloride": "Cl[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)(Cl)C3=CC=CC=C3",
  "Boron Trichloride": "ClB(Cl)Cl",
  "[Di-tert-butyl(chloro)phosphine]palladium(II) Dichloride": "ClP(C(C)(C)C)C(C)(C)C.[Pd+2].[Cl-].[Cl-]",
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](N,N-dimethylbenzylamine)palladium(II)]": "CN(C)CC1=CC=CC=C1.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C",
  "Dichloro(N,N,N',N'-tetramethylethylenediamine)zinc(II)": "CN(C)CCN(C)C.Cl[Zn]Cl",
  "Bromo[(2,6-pyridinediyl)bis(3-methyl-1-imidazolyl-2-ylidene)]nickel Bromide": "CN(C=CN12)C1=[Ni-3](Br)=C3N(C)C=CN3C4=CC=CC2=N4.[Br-]",
  "Bis[di-tert-butyl(4-dimethylaminophenyl)phosphine]palladium(0)": "CN(C1=CC=C(P(C(C)(C)C)C(C)(C)C)C=C1)C.CN(C2=CC=C(P(C(C)(C)C)C(C)(C)C)C=C2)C.[Pd]",
  "4-(Dimethylamino)phenyldiphenylphosphine": "CN(C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1)C",
  "1,4-Dimethyl-1,2,4-triazolium Iodide": "CN1C=N[N+](C)=C1.[I-]",
  "Chloro[[1,3-bis(2,6-diisopropylphenyl)imidazol-2-ylidene](N,N-dimethyl-3,5-dimethoxybenzylamine)palladium(II)]": "COC1=CC(OC)=CC(CN(C)C)=C1.CC(C2=C(N(C=CN3C4=C(C(C)C)C=CC=C4C(C)C)C3=[Pd-]Cl)C(C(C)C)=CC=C2)C",
  "Dimethylphenylphosphine Borane": "CP(C)C1=CC=CC=C1.B",
  "Potassium Hexafluoroarsenate": "F[As-](F)(F)(F)(F)F.[K+]",
  "1,3-Di(1-adamantyl)imidazolium Tetrafluoroborate": "F[B-](F)(F)F.[N+]1(C23CC4CC(C3)CC(C4)C2)=CN(C56CC7CC(C6)CC(C7)C5)C=C1",
  "1,3-Di(1-adamantyl)imidazolinium Tetrafluoroborate": "F[B-](F)(F)F.[N+]1(C23CC4CC(C3)CC(C4)C2)=CN(C56CC7CC(C6)CC(C7)C5)CC1",
  "1,3-Dicyclohexylimidazolium Tetrafluoroborate": "F[B-](F)(F)F.[N+]1(C2CCCCC2)=CN(C3CCCCC3)C=C1",
  "Sodium Tetrafluoroborate": "F[B-](F)(F)F.[Na+]",
  "Bis(1,5-cyclooctadiene)rhodium(I) Tetrafluoroborate": "F[B-](F)(F)F.[Rh+].C1=C\\CC/C=C\\CC/1.C2=C\\CC/C=C\\CC/2",
  "Copper(II) Tetrafluoroborate": "F[B-](F)(F)F.F[B-](F)(F)F.[Cu+2]",
  "(1,5-Cyclooctadiene)(pyridine)(tricyclohexylphosphine)iridium(I) Hexafluorophosphate": "F[P-](F)(F)(F)(F)F.P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.[Ir+].C4=C\\CC/C=C\\CC/4.C5=NC=CC=C5",
  "Boron Trifluoride - Propanol Reagent": "FB(F)F.CCCO",
  "Boron Trifluoride - Methanol Reagent": "FB(F)F.CO",
  "6,7-Dihydro-2-pentafluorophenyl-5H-pyrrolo[2,1-c][1,2,4]triazolium Tetrafluoroborate": "FC1=C(F)C(F)=C(F)C(F)=C1[N+]2=CN3C(CCC3)=N2.F[B-](F)(F)F",
  "Bis(benzonitrile)palladium(II) Dichloride": "N#CC1=CC=CC=C1.N#CC2=CC=CC=C2.[Pd+2].[Cl-].[Cl-]",
  "Chloro[(tri-tert-butylphosphine)-2-(2-aminobiphenyl)]palladium(II)": "NC1([Pd+2])=CC=CC=C1C2=CC=CC=C2.CC(P(C(C)(C)C)(Cl)C(C)(C)C)(C)C",
  "Aluminum Glycinate": "NCC([O-])=O.NCC([O-])=O.NCC([O-])=O.[Al+3]",
  "Bis(1,3-propanediamine) Copper(II) Dichloride": "NCCCN.NCCCN.[Cu+2].[Cl-].[Cl-]",
  "Calcium L-Threonate": "O[C@H]([C@H](CO)O)C([O-])=O.O[C@H]([C@H](CO)O)C([O-])=O.[Ca+2]",
  "Cacodylic Acid": "O=[As](C)(C)O",
  "4-(4-Dimethylaminophenylazo)phenylarsonic Acid Hydrochloride": "O=[As](C1=CC=C(/N=N/C2=CC=C(N(C)C)C=C2)C=C1)(O)O.[H]Cl",
  "4-Hydroxy-3-nitrophenylarsonic Acid": "O=[As](C1=CC=C(O)C([N+]([O-])=O)=C1)(O)O",
  "4-Hydroxyphenylarsonic Acid": "O=[As](C1=CC=C(O)C=C1)(O)O",
  "Phenylarsonic Acid": "O=[As](C1=CC=CC=C1)(O)O",
  "2-Nitrophenylarsonic Acid": "O=[As](C1=CC=CC=C1[N+]([O-])=O)(O)O",
  "Phenylarsine Oxide": "O=[As]C1=CC=CC=C1",
  "Triphenylantimony Oxide": "O=[Sb](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Bis(4-methoxyphenyl) Selenoxide": "O=[Se](C1=CC=C(OC)C=C1)C2=CC=C(OC)C=C2",
  "Sodium Tellurite Pentahydrate": "O=[Te]([O-])[O-].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Na+].[Na+]",
  "Telluric Acid": "O=[Te](O)(O)=O",
  "Bis(dibenzylideneacetone)palladium(0)": "O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.[Pd]",
  "Tris(dibenzylideneacetone)dipalladium(0)": "O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2.O=C(/C=C/C3=CC=CC=C3)/C=C/C4=CC=CC=C4.O=C(/C=C/C5=CC=CC=C5)/C=C/C6=CC=CC=C6.[Pd].[Pd]",
  "Thallium(III) Trifluoroacetate": "O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.[Tl+3]",
  "Copper(I) 2-Thiophenecarboxylate": "O=C(C1=CC=CS1)[O-].[Cu+]",
  "Copper(I) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.[Cu+]",
  "Copper(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Cu+2]",
  "Tin(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Sn+2]",
  "Zinc(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Zn+2]",
  "Nickel(II) Benzenesulfonate Hexahydrate": "O=S(C1=CC=CC=C1)([O-])=O.O=S(C2=CC=CC=C2)([O-])=O.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Ni+2]",
  "1,2-Bis(phenylsulfinyl)ethane Palladium(II) Diacetate": "O=S(CCS(C1=CC=CC=C1)=O)C2=CC=CC=C2.CC([O-])=O.CC([O-])=O.[Pd+2]",
  "Bismuth Tripotassium Dicitrate": "OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.[Bi+5].[K+].[K+].[K+]",
  "Trimagnesium Dicitrate Nonahydrate": "OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.OC(C([O-])=O)(CC([O-])=O)CC([O-])=O.[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Mg+2].[Mg+2].[Mg+2]",
  "(Triphenylphosphine)gold(I) Chloride": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.[Au+].[Cl-]",
  "Bis(triphenylphosphine)palladium(II) Dichloride": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Pd+2].[Cl-].[Cl-]",
  "Tris(triphenylphosphine)rhodium(I) Chloride": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.[Rh+].[Cl-]",
  "Tetrakis(triphenylphosphine)palladium(0)": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.P(C%10=CC=CC=C%10)(C%11=CC=CC=C%11)C%12=CC=CC=C%12.[Pd]",
  "Bis(tricyclohexylphosphine)palladium(II) Dichloride": "P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.[Pd+2].[Cl-].[Cl-]",
  "[1,2-Bis(diphenylphosphino)ethane]palladium(II) Dichloride": "P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Pd+2].[Cl-].[Cl-]",
  "Bis[1,2-bis(diphenylphosphino)ethane]palladium(0)": "P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.P(CCP(C5=CC=CC=C5)C6=CC=CC=C6)(C7=CC=CC=C7)C8=CC=CC=C8.[Pd]",
  "1-(2-Mesitylene)-1,3-butanedione": "CC(CC(C1=C(C)C=C(C)C=C1C)=O)=O",
  "1-(4-Bromophenyl)-1,3-butanedione": "CC(CC(C1=CC=C(Br)C=C1)=O)=O",
  "1-(4-Chlorophenyl)-4,4,4-trifluoro-1,3-butanedione": "O=C(C1=CC=C(Cl)C=C1)CC(C(F)(F)F)=O",
  "1-(4-Fluorophenyl)-1,3-butanedione": "CC(CC(C1=CC=C(F)C=C1)=O)=O",
  "1-(4-tert-Butylphenyl)-3-(4-methoxyphenyl)-1,3-propanedione": "O=C(C1=CC=C(C(C)(C)C)C=C1)CC(C2=CC=C(OC)C=C2)=O",
  "1,1,5,5-Tetrafluoro-2,4-pentanedione": "O=C(CC(C(F)F)=O)C(F)F",
  "1,3-Bis(4-methoxyphenyl)-1,3-propanedione": "O=C(C1=CC=C(OC)C=C1)CC(C2=CC=C(OC)C=C2)=O",
  "1,3-Di(2-pyridyl)-1,3-propanedione": "O=C(C1=NC=CC=C1)CC(C2=NC=CC=C2)=O",
  "1,3-Diphenyl-1,3-propanedione": "O=C(C1=CC=CC=C1)CC(C2=CC=CC=C2)=O",
  "1-Phenyl-1,3-butanedione": "CC(CC(C1=CC=CC=C1)=O)=O",
  "2,2-Dimethyl-6,6,7,7,8,8,8-heptafluoro-3,5-octanedione": "CC(C)(C)C(CC(C(F)(F)C(F)(F)C(F)(F)F)=O)=O",
  "2,6-Dimethyl-3,5-heptanedione": "CC(C)C(CC(C(C)C)=O)=O",
  "2-Acetylcyclohexanone": "O=C1C(C(C)=O)CCCC1",
  "2-Acetylcyclopentanone": "O=C1C(C(C)=O)CCC1",
  "2-Furoyltrifluoroacetone": "O=C(CC(C1=CC=CO1)=O)C(F)(F)F",
  "3,4-Diacetyl-2,5-hexanedione": "CC(C(C(C)=O)C(C(C)=O)C(C)=O)=O",
  "3,5-Heptanedione": "CCC(CC(CC)=O)=O",
  "3-Chloroacetylacetone": "CC(C(Cl)C(C)=O)=O",
  "3-Ethyl-2,4-pentanedione": "CC(C(CC)C(C)=O)=O",
  "3-Methyl-2,4-pentanedione": "CC(C(C)C(C)=O)=O",
  "3-Phenyl-2,4-pentanedione": "CC(C(C1=CC=CC=C1)C(C)=O)=O",
  "4,4,4-Trifluoro-1-(2-naphthyl)-1,3-butanedione": "O=C(C1=CC=C2C=CC=CC2=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(2-thienyl)-1,3-butanedione": "O=C(C1=CC=CS1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(4-methoxyphenyl)-1,3-butanedione": "O=C(C1=CC=C(OC)C=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-(p-tolyl)-1,3-butanedione": "O=C(C1=CC=C(C)C=C1)CC(C(F)(F)F)=O",
  "4,4,4-Trifluoro-1-phenyl-1,3-butanedione": "O=C(C1=CC=CC=C1)CC(C(F)(F)F)=O",
  "6-Methyl-2,4-heptanedione": "CC(CC(CC(C)C)=O)=O",
  "9H,9H-Triacontafluoro-8,10-heptadecanedione": "O=C(CC(C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F)=O)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F",
  "Acetylacetone": "CC(CC(C)=O)=O",
  "Dehydroacetic Acid": "CC(C(C(C=C(O1)C)=O)C1=O)=O",
  "Dipivaloylmethane": "CC(C)(C)C(CC(C(C)(C)C)=O)=O",
  "Hexafluoroacetylacetone": "O=C(C(F)(F)C(CF)=O)C(F)(F)F",
  "Trifluoroacetylacetone": "O=C(CC(C)=O)C(F)(F)F",
  "1,2,3,4,5-Pentamethylcyclopentadiene": "CC1C(C)=C(C)C(C)=C1C",
  "1,2,3,4,5-Pentaphenyl-1,3-cyclopentadiene": "C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1C6=CC=CC=C6",
  "1,2,3,4-Tetraphenyl-1,3-cyclopentadiene": "C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)C1",
  "1,2-Bis(3-indenyl)ethane": "C1(CCC2=CCC3=C2C=CC=C3)=CCC4=C1C=CC=C4",
  "1,3,5,7-Cyclooctatetraene": "C1=C\\C=C/C=C\\C=C/1",
  "1,3,5-Cycloheptatriene": "C1=CC=CC=CC1",
  "1,3,5-Trimethylbenzene": "CC1=CC(C)=CC(C)=C1",
  "1,4-Cyclohexadiene ": "C1=CCC=CC1",
  "1,5-Cyclooctadiene [stabilized with Octadecyl 3-(3',5'-Di-tert-butyl-4'-hydroxyphenyl)propionate]": "C1=C\\CC/C=C\\CC/1",
  "1,5-Hexadiene": "C=CCCC=C",
  "1H-Cyclopenta[l]phenanthrene": "C12=CC=CC=C1C(CC=C3)=C3C4=C2C=CC=C4",
  "2,5-Norbornadiene": "C12C=CC(C2)C=C1",
  "2-Methylbenzo[e]indene": "N/A",
  "2-Methylcyclopenta[l]phenanthrene": "CC1=CC2=C3CC=CC=C3C4=C(C2=C1)C=CC=C4",
  "2-Norbornene": "C12C=CC(C2)CC1",
  "Acenaphthylene": "C12=CC=CC3=C1C(C=C2)=CC=C3",
  "Cyclooctene": "C1=C\\CCCCCC/1",
  "Dibenzo[a,e]cyclooctene": "C12=CC=CC=C1/C=C\\C3=CC=CC=C3/C=C\\2",
  "Dicyclopentadiene": "C12C=CCC1C3C=CC2C3",
  "Hexamethylbenzene": "CC1=C(C)C(C)=C(C)C(C)=C1C",
  "Indan": "C12=C(CCC2)C=CC=C1",
  "Indene": "C12=C(CC=C2)C=CC=C1",
  "p-Cymene": "CC1=CC=C(C(C)C)C=C1",
  "Tetraphenylcyclopentadienone": "O=C1C(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C1C5=CC=CC=C5",
  "trans,trans-1,5-Bis(4-fluorophenyl)-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(F)C=C1)/C=C/C2=CC=C(F)C=C2",
  "trans,trans-1,5-Bis(4-methoxyphenyl)-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(OC)C=C1)/C=C/C2=CC=C(OC)C=C2",
  "trans,trans-1,5-Bis[4-(trifluoromethyl)phenyl]-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=C(C(F)(F)F)C=C1)/C=C/C2=CC=C(C(F)(F)F)C=C2",
  "trans,trans-1,5-Diphenyl-1,4-pentadien-3-one": "O=C(/C=C/C1=CC=CC=C1)/C=C/C2=CC=CC=C2",
  "Silver": "[Ag]",
  "Gold": "[Au]",
  "Tricarbonyl(cyclooctatetraene)iron": "[C]=O.[C]=O.[C]=O.[Fe].C1=C\\C=C/C=C\\C=C/1",
  "Cerium": "[Ce]",
  "Cobalt": "[Co]",
  "Chromium": "[Cr]",
  "Copper": "[Cu]",
  "(R,R'')-2,2''-Bis(diphenylphosphino)-1,1''-biferrocene": "[Del][Fe][Del].[Del][Fe][Del].P(C1=CC=CC=C1)(c2cccc2c3cccc3P(C4=CC=CC=C4)C5=CC=CC=C5)C6=CC=CC=C6.c7cccc7.c8cccc8",
  "1,1'-Bis(diphenylphosphino)ferrocene": "[Del][Fe][Del].c1(P(C2=CC=CC=C2)C3=CC=CC=C3)cccc1.c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "[1,1'-Bis(diphenylphosphino)ferrocene]cobalt(II) Dichloride": "[Del][Fe][Del].C1(P(c2cccc2)C3=CC=CC=C3)=CC=CC=C1.C4(P(c5cccc5)C6=CC=CC=C6)=CC=CC=C4.[Co+2].[Cl-].[Cl-]",
  "[1,1'-Bis(diphenylphosphino)ferrocene]nickel(II) Dichloride": "[Del][Fe][Del].P(C1=CC=CC=C1)(c2cccc2)C3=CC=CC=C3.P(C4=CC=CC=C4)(c5cccc5)C6=CC=CC=C6.[Ni+2].[Cl-].[Cl-]",
  "Europium": "[Eu]",
  "Iron": "[Fe]",
  "Tris(2,2'-bipyridyl)ruthenium(II) Chloride Hexahydrate": "[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].[H]O[H].C1(C2=NC=CC=C2)=NC=CC=C1.C3(C4=NC=CC=C4)=NC=CC=C3.C5(C6=NC=CC=C6)=NC=CC=C5.[Ru+2].[Cl-].[Cl-]",
  "Manganese(II) Chloride Tetrahydrate": "[H]O[H].[H]O[H].[H]O[H].[H]O[H].[Mn+2].[Cl-].[Cl-]",
  "Hafnium": "[Hf]",
  "Iridium": "[Ir]",
  "Lanthanum": "[La]",
  "Manganese": "[Mn]",
  "Molybdenum": "[Mo]",
  "Neodymium": "[Nd]",
  "Nickel": "[Ni]",
  "Bis(1,5-cyclooctadiene)nickel(0)": "[Ni].C1=C\\CC/C=C\\CC/1.C2=C\\CC/C=C\\CC/2",
  "(1R,2R)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": "[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@@H]([C@@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]",
  "(1S,2S)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": "[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@H]([C@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]",
  "Tris(2,4-pentanedionato)iron(III)": "[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Fe+3]",
  "Tetrakis(2,4-pentanedionato)titanium(IV) (ca. 63% in Isopropyl Alcohol)": "[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[O-]CCC.[O-]CCC.[Ti+4]",
  "Tris(dibenzoylmethanato) Iron": "[O-]/C(C1=CC=CC=C1)=C\\C(C2=CC=CC=C2)=O.[O-]/C(C3=CC=CC=C3)=C\\C(C4=CC=CC=C4)=O.[O-]/C(C5=CC=CC=C5)=C\\C(C6=CC=CC=C6)=O.[Fe+3]",
  "Salcomine [=N,N'-Bis(salicylidene)ethylenediiminocobalt(II)]": "[O-]C(C=CC=C1)=C1/C=N/CC/N=C/C2=CC=CC=C2[O-].[Co+2]",
  "Chloronitrosyl[N,N'-bis(3,5-di-tert-butylsalicylidene)-1,1,2,2-tetramethylethylenediaminato]ruthenium(IV)": "[O-]C1=C(/C=N/C(C)(C)C(C)(C)/N=C/C2=C([O-])C(C(C)(C)C)=CC(C(C)(C)C)=C2)C=C(C(C)(C)C)C=C1C(C)(C)C.[N-]=O.[Ru+4].[Cl-]",
  "Tetrabutyl Orthotitanate Tetramer": "[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[O-]CCCC.[Ti+4].[Ti+4].[O-2].[O-2].[Ti+4].[O-2].[Ti+4]",
  "4-[2-[2-(4-Iodophenoxy)ethoxy]carbonyl]benzoyloxy-2,2,6,6-tetramethylpiperidin-1-oxyl": "[O]N1C(C)(C)CC(OC(C2=CC=CC=C2C(OCCOC3=CC=C(I)C=C3)=O)=O)CC1(C)C",
  "Osmium": "[Os]",
  "Osmium Tetroxide (4% in Water)": "[Os+8].[O-2].[O-2].[O-2].[O-2]",
  "Platinum": "[Pt]",
  "Rhenium": "[Re]",
  "Rhodium": "[Rh]",
  "Ruthenium": "[Ru]",
  "Scandium": "[Sc]",
  "Samarium": "[Sm]",
  "Terbium": "[Tb]",
  "Titanium": "[Ti]",
  "(Pentamethylcyclopentadienyl)titanium(IV) Trichloride": "[Ti+3]C1=C(C)C(C)=C(C)C1(C)C.[Cl-].[Cl-].[Cl-]",
  "Cyclopentadienyltitanium(IV) Trichloride": "[Ti+3]C1=CC=CC1.[Cl-].[Cl-].[Cl-]",
  "(Indenyl)titanium(IV) Trichloride": "[Ti+3]C1C=CC2=C1C=CC=C2.[Cl-].[Cl-].[Cl-]",
  "Titanium(IV) Chloride": "[Ti+4].[Cl-].[Cl-].[Cl-].[Cl-]",
  "Thulium": "[Tm]",
  "Vanadium": "[V]",
  "Tungsten": "[W]",
  "Yttrium": "[Y]",
  "Ytterbium": "[Yb]",
  "Zirconium": "[Zr]",
  "(2-Bromophenyl)diphenylphosphine": "BrC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "4,6-Bis(diphenylphosphino)phenoxazine": "C(C=CC=C1N2)(P(C3=CC=CC=C3)C4=CC=CC=C4)=C1OC5=C2C=CC=C5P(C6=CC=CC=C6)C7=CC=CC=C7",
  "Bis(trifluoro-2,4-pentanedionato)cobalt(II) Hydrate": "C/C([O-])=C/C(C(F)(F)F)=O.C/C([O-])=C/C(C(F)(F)F)=O.[Co+2]",
  "Bis(2,4-pentanedionato)cobalt(II)": "C/C([O-])=C/C(C)=O.C/C([O-])=C/C(C)=O.[Co+2].O",
  "Bis(2,4-pentanedionato)cobalt(II) Dihydrate": "C/C([O-])=C/C(C)=O.C/C([O-])=C/C(C)=O.[Co+2].O.O",
  "(R)-N,N-Dimethyl-1-[(S)-1',2-bis(diphenylphosphino)ferrocenyl]ethylamine": "C[C@@H](N(C)C)c1c(P(C2=CC=CC=C2)C3=CC=CC=C3)ccc1.[Del][Fe][Del].c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "(2S,3S)-(-)-Bis(diphenylphosphino)butane": "C[C@H](P(C1=CC=CC=C1)C2=CC=CC=C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)C",
  "1-Methyl-1-[4-(diphenylphosphino)benzyl]pyrrolidinium Bromide": "C[N+]1(CC2=CC=C(P(C3=CC=CC=C3)C4=CC=CC=C4)C=C2)CCCC1.[Br-]",
  "9-Mesityl-2,7,10-trimethylacridinium Perchlorate": "C[N+]1=C2C=CC(C)=CC2=C(C3=C(C)C=C(C)C=C3C)C4=C1C=CC(C)=C4.O=Cl(=O)([O-])=O",
  "9-Mesityl-10-methylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=C(C3=C(C)C=C(C)C=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O",
  "9-(2,6-Dimethylphenyl)-10-methylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=C(C3=C(C)C=CC=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O",
  "9-(2,5-Dimethylphenyl)-10-methylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=C(C3=CC(C)=CC=C3C)C4=C1C=CC=C4.O=Cl(=O)([O-])=O",
  "10-Methyl-9-phenylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=C(C3=CC=CC=C3)C4=C1C=CC=C4.O=Cl(=O)([O-])=O",
  "9-(2-Biphenylyl)-10-methylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=C(C3=CC=CC=C3C4=CC=CC=C4)C5=C1C=CC=C5.O=Cl(=O)([O-])=O",
  "10-Methylacridinium Perchlorate": "C[N+]1=C2C=CC=CC2=CC3=C1C=CC=C3.O=Cl(=O)([O-])=O",
  "(R,R)-(-)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@@](C1=NC2=CC=CC=C2N=C1[P@@](C(C)(C)C)C)C(C)(C)C",
  "(S,S)-1,2-Bis[(tert-butyl)methylphosphino]ethane Bis(borane)": "C[P@@](CC[P@@](C(C)(C)C)C)C(C)(C)C.B.B",
  "(S,S)-(+)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@](C1=NC2=CC=CC=C2N=C1[P@](C(C)(C)C)C)C(C)(C)C",
  "Lithium Diphenylphosphide (ca. 0.5mol/L in Tetrahydrofuran)": "C1([P-]C2=CC=CC=C2)=CC=CC=C1.[Li+]",
  "(2R,3R)-(-)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": "C1(C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1",
  "(2S,3S)-(+)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": "C1(C2)[C@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1",
  "2-(Diphenylphosphino)biphenyl": "C1(C2=CC=CC=C2)=CC=CC=C1P(C3=CC=CC=C3)C4=CC=CC=C4",
  "2-(Dicyclohexylphosphino)biphenyl": "C1(C2=CC=CC=C2)=CC=CC=C1P(C3CCCCC3)C4CCCCC4",
  "2,2'-Bis(diphenylphosphino)biphenyl": "C1(C2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6",
  "Bis[2-(diphenylphosphino)phenyl] Ether": "C1(OC2=CC=CC=C2P(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1P(C5=CC=CC=C5)C6=CC=CC=C6",
  "1,8-Bis(diphenylphosphino)naphthalene": "C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=C4C(P(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC4=CC=C1",
  "1,2-Bis(diphenylphosphino)benzene": "C1(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=CC=C1P(C4=CC=CC=C4)C5=CC=CC=C5",
  "Perylene": "C12=CC=CC(C3=CC=CC4=C3C5=CC=C4)=C1C5=CC=C2",
  "Di-1-adamantylphosphine": "C12CC3(PC45CC6CC(C5)CC(C6)C4)CC(C2)CC(C3)C1",
  "Bis(2,4-pentanedionato)vanadium(IV) Oxide": "CC(/C=C([O-])/C)=O.[O-]/C(C)=C\\C(C)=O.[V+4].[O-2]",
  "Bis(2,4-pentanedionato)molybdenum(VI) Dioxide": "CC(/C=C([O-])/C)=O.C/C([O-])=C/C(C)=O.[Mo+6].[O-2].[O-2]",
  "Bis(2,4-pentanedionato)manganese(II) Dihydrate": "CC(/C=C([O-])/C)=O.CC(/C=C([O-])/C)=O.[Mn+2].O.O",
  "Tris(2,4-pentanedionato)cobalt(III)": "CC(/C=C(C)\\[O-])=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Co+3]",
  "Tris(2,4-pentanedionato)manganese(III)": "CC(/C=C(C)\\[O-])=O.[O-]/C(C)=C\\C(C)=O.[O-]/C(C)=C\\C(C)=O.[Mn+3]",
  "Palladium(II) Acetate": "CC([O-])=O.CC([O-])=O.[Pd+2]",
  "(1S,2S)-N,N'-Bis[(R)-2-hydroxy-2'-phenyl-1,1'-binaphthyl-3-ylmethylene]-1,2-diphenylethylenediaminato Manganese(III) Acetate": "CC([O-])=O.OC1=C(C([Mn+]2)=N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N=C2C5=C(O)C(C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8)=C9C=CC=CC9=C5)C=C%10C=CC=CC%10=C1C%11=C%12C=CC=CC%12=CC=C%11C%13=CC=CC=C%13",
  "Tri-tert-butylphosphonium Tetrafluoroborate": "CC([PH+](C(C)(C)C)C(C)(C)C)(C)C.F[B-](F)(F)F",
  "1,2,3,4,5-Pentaphenyl-1'-(di-tert-butylphosphino)ferrocene": "CC(C)(C)P(C(C)(C)C)c1cccc1.[Del][Fe][Del].c2(C3=CC=CC=C3)c(C4=CC=CC=C4)c(C5=CC=CC=C5)c(C6=CC=CC=C6)c2C7=CC=CC=C7",
  "1,1'-Bis(di-tert-butylphosphino)ferrocene": "CC(C)(C)P(c1cccc1)C(C)(C)C.CC(C)(C)P(c2cccc2)C(C)(C)C.[Del][Fe][Del]",
  "(S)-1-(Diphenylphosphino)-2-[(S)-4-isopropyloxazolin-2-yl]ferrocene": "CC(C)[C@@H]1N=C(c2c(P(C3=CC=CC=C3)C4=CC=CC=C4)ccc2)OC1.[Del][Fe][Del].c5cccc5",
  "Tetraisobutyl Orthotitanate": "CC(C)CO[Ti](OCC(C)C)(OCC(C)C)OCC(C)C",
  "1,1'-Bis(diisopropylphosphino)ferrocene": "CC(C)P(c1cccc1)C(C)C.CC(C)P(c2cccc2)C(C)C.[Del][Fe][Del]",
  "2-Di-tert-butylphosphino-2',4',6'-triisopropylbiphenyl": "CC(C1=C(C2=CC=CC=C2P(C(C)(C)C)C(C)(C)C)C(C(C)C)=CC(C(C)C)=C1)C",
  "2-Dicyclohexylphosphino-2',4',6'-triisopropylbiphenyl": "CC(C1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(C(C)C)=CC(C(C)C)=C1)C",
  "[1,3-Bis(2,6-diisopropylphenyl)imidazol-2-ylidene]triphenylphosphine Nickel(II) Dichloride": "CC(C1=C(N(C=CN2C3=C(C(C)C)C=CC=C3C(C)C)C2=P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)C(C(C)C)=CC=C1)C.[Ni+2].[Cl-].[Cl-]",
  "1,3-Bis(2,6-diisopropylphenyl)-1,3,2-diazaphospholidine 2-Oxide": "CC(C1=C(N2P(N(C3=C(C(C)C)C=CC=C3C(C)C)CC2)=O)C(C(C)C)=CC=C1)C",
  "Dicyclohexyl(2,4,6-triisopropylphenyl)phosphine": "CC(C1=C(P(C2CCCCC2)C3CCCCC3)C(C(C)C)=CC(C(C)C)=C1)C",
  "Tris(2,4-di-tert-butylphenyl) Phosphite": "CC(C1=CC=C(OP(OC2=CC=C(C(C)(C)C)C=C2C(C)(C)C)OC3=CC=C(C(C)(C)C)C=C3C(C)(C)C)C(C(C)(C)C)=C1)(C)C",
  "3-Methyl-1-phenyl-2-phospholene 1-Oxide": "CC(CC1)=CP1(C2=CC=CC=C2)=O",
  "Tetraisopropyl Orthotitanate": "CC(O[Ti](OC(C)C)(OC(C)C)OC(C)C)C",
  "2-Dicyclohexylphosphino-2',6'-diisopropoxybiphenyl": "CC(OC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC(C)C)=CC=C1)C",
  "Di-tert-butylphenylphosphine": "CC(P(C(C)(C)C)C1=CC=CC=C1)(C)C",
  "(4-Dimethylaminophenyl)di-tert-butylphosphine": "CC(P(C1=CC=C(N(C)C)C=C1)C(C)(C)C)(C)C",
  "Isopropyldiphenylphosphine": "CC(P(C1=CC=CC=C1)C2=CC=CC=C2)C",
  "2-(Di-tert-butylphosphino)biphenyl": "CC(P(C1=CC=CC=C1C2=CC=CC=C2)C(C)(C)C)(C)C",
  "1-[2-(Di-tert-butylphosphino)phenyl]-3,5-diphenyl-1H-pyrazole": "CC(P(C1=CC=CC=C1N2N=C(C3=CC=CC=C3)C=C2C4=CC=CC=C4)C(C)(C)C)(C)C",
  "Chlorodiisopropylphosphine": "CC(P(Cl)C(C)C)C",
  "1,3-Bis[(di-tert-butylphosphino)oxy]benzene": "CC(P(OC1=CC(OP(C(C)(C)C)C(C)(C)C)=CC=C1)C(C)(C)C)(C)C",
  "4,4,5,5-Tetramethyl-1,3,2-dioxaphospholane 2-Oxide": "CC1(C)C(C)(C)OP(O1)=O",
  "4,5-Bis(diphenylphosphino)-9,9-dimethylxanthene": "CC1(C)C2=C(OC3=C1C=CC=C3P(C4=CC=CC=C4)C5=CC=CC=C5)C(P(C6=CC=CC=C6)C7=CC=CC=C7)=CC=C2",
  "4-Acetamido-2,2,6,6-tetramethylpiperidine 1-Oxyl": "CC1(C)CC(NC(C)=O)CC(C)(C)N1[O]",
  "4-Hydroxy-2,2,6,6-tetramethylpiperidine 1-Oxyl Benzoate": "CC1(C)CC(OC(C2=CC=CC=C2)=O)CC(C)(C)N1[O]",
  "4-Methoxy-2,2,6,6-tetramethylpiperidine 1-Oxyl ": "CC1(C)CC(OC)CC(C)(C)N1[O]",
  "2,2,6,6-Tetramethylpiperidine 1-Oxyl Free Radical": "CC1(C)CCCC(C)(C)N1[O]",
  "(2S,3S)-(+)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@@H]([C@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(2R,3R)-(-)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@H]([C@@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(1R,2R)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": "CC1=C(C(C)=CC(C)=C1)[C@@H](/N=C/C=C(C(C)=O)\\C[O-])[C@@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]",
  "(1S,2S)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": "CC1=C(C(C)=CC(C)=C1)[C@H](/N=C/C=C(C(C)=O)\\C[O-])[C@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]",
  "Tri(m-tolyl)phosphine": "CC1=CC(P(C2=CC=CC(C)=C2)C3=CC=CC(C)=C3)=CC=C1",
  "Tri(p-tolyl)phosphine": "CC1=CC=C(P(C2=CC=C(C)C=C2)C3=CC=C(C)C=C3)C=C1",
  "Chlorobis[dicyclohexyl(phenyl)phosphino](o-tolyl)nickel(II)": "CC1=CC=CC=C1[Ni-2](P(C2CCCCC2)(C3CCCCC3)C4=CC=CC=C4)(Cl)P(C5CCCCC5)(C6CCCCC6)C7=CC=CC=C7",
  "Tri(o-tolyl)phosphine": "CC1=CC=CC=C1P(C2=CC=CC=C2C)C3=CC=CC=C3C",
  "Tetrapropylammonium Perruthenate": "CCC[N+](CCC)(CCC)CCC.O=[Ru](=O)([O-])=O",
  "Tetrabutylammonium Perrhenate": "CCCC[N+](CCCC)(CCCC)CCCC.O=[Re](=O)([O-])=O",
  "Tri-n-octylphosphine Oxide": "CCCCCCCCP(CCCCCCCC)(CCCCCCCC)=O",
  "Tri-n-octylphosphine": "CCCCCCCCP(CCCCCCCC)CCCCCCCC",
  "Trihexylphosphine": "CCCCCCP(CCCCCC)CCCCCC",
  "Tetrabutyl Orthotitanate": "CCCCO[Ti](OCCCC)(OCCCC)OCCCC",
  "Tributylphosphine Oxide": "CCCCP(CCCC)(CCCC)=O",
  "Diphenylpropylphosphine": "CCCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Dichloro(diethylamino)phosphine": "CCN(P(Cl)Cl)CC",
  "Tris(diethylamino)phosphine": "CCN(P(N(CC)CC)N(CC)CC)CC",
  "Ethoxydiphenylphosphine": "CCOP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Ethyldiphenylphosphine": "CCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Diethylphenylphosphine": "CCP(CC)C1=CC=CC=C1",
  "Dichlorotitanium Diisopropoxide": "Cl[Ti+2]Cl.CC([O-])C.CC([O-])C",
  "Bis[tris(2,4-pentanedionato)titanium(IV)] Hexachlorotitanate(IV)": "Cl[Ti-2](Cl)(Cl)(Cl)(Cl)Cl.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.C/C([O-])=C\\C(C)=O.[Ti+4].[Ti+4]",
  "Di-tert-butylchlorophosphine": "ClP(C(C)(C)C)C(C)(C)C",
  "Chlorodiphenylphosphine": "ClP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Chlorodicyclohexylphosphine": "ClP(C1CCCCC1)C2CCCCC2",
  "Dichlorophenylphosphine": "ClP(Cl)C1=CC=CC=C1",
  "1,1'-Bis(dichlorophosphino)ferrocene": "ClP(Cl)c1cccc1.ClP(Cl)c2cccc2.[Del][Fe][Del]",
  "2-Chloro-1,3,2-dioxaphospholane": "ClP1OCCO1",
  "Tetrakis(dimethylamino)titanium(IV)": "CN([Ti](N(C)C)(N(C)C)N(C)C)C",
  "(S)-N,N-Dimethyl-1-[(R)-2-(diphenylphosphino)ferrocenyl]ethylamine": "CN(C)[C@@H](CP(C1=CC=CC=C1)C2=CC=CC=C2)c3cccc3.[Del][Fe][Del].c4cccc4",
  "(R)-N,N-Dimethyl-1-[(S)-2-(diphenylphosphino)ferrocenyl]ethylamine": "CN(C)[C@H](c1cccc1)CP(C2=CC=CC=C2)C3=CC=CC=C3.[Del][Fe][Del].c4cccc4",
  "2-(Dicyclohexylphosphino)-2'-(dimethylamino)biphenyl": "CN(C1=CC=CC=C1C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C",
  "2-Dicyclohexylphosphino-2',6'-dimethoxybiphenyl": "COC1=C(C2=CC=CC=C2P(C3CCCCC3)C4CCCCC4)C(OC)=CC=C1",
  "Tris(2,6-dimethoxyphenyl)phosphine": "COC1=C(P(C2=C(OC)C=CC=C2OC)C3=C(OC)C=CC=C3OC)C(OC)=CC=C1",
  "Tris(4-methoxyphenyl)phosphine": "COC1=CC=C(P(C2=CC=C(OC)C=C2)C3=CC=C(OC)C=C3)C=C1",
  "(R)-(+)-2-Diphenylphosphino-2'-methoxy-1,1'-binaphthyl": "COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3P(C5=CC=CC=C5)C6=CC=CC=C6",
  "(S,S)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@@](CC[P@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "(R,R)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@](CC[P@@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "Tris(2-methoxyphenyl)phosphine": "COC1=CC=CC=C1P(C2=CC=CC=C2OC)C3=CC=CC=C3OC",
  "Methoxymethyl(diphenyl)phosphine Oxide": "COCP(C1=CC=CC=C1)(C2=CC=CC=C2)=O",
  "Methoxydiphenylphosphine": "COP(C1=CC=CC=C1)C2=CC=CC=C2",
  "Trimethylphosphine": "CP(C)C",
  "Methyl(diphenyl)phosphine Oxide": "CP(C1=CC=CC=C1)(C2=CC=CC=C2)=O",
  "Methyldiphenylphosphine": "CP(C1=CC=CC=C1)C2=CC=CC=C2",
  "1,2-Bis(dimethylphosphino)ethane": "CP(CCP(C)C)C",
  "Tricyclohexylphosphonium Tetrafluoroborate": "F[B-](F)(F)F.[PH+](C1CCCCC1)(C2CCCCC2)C3CCCCC3",
  "Tris[3,5-bis(trifluoromethyl)phenyl]phosphine": "FC(C1=CC(C(F)(F)F)=CC(P(C2=CC(C(F)(F)F)=CC(C(F)(F)F)=C2)C3=CC(C(F)(F)F)=CC(C(F)(F)F)=C3)=C1)(F)F",
  "Manganese(II) Bis(trifluoromethanesulfonyl)imide": "FC(F)(S(=O)([N-]S(=O)(C(F)(F)F)=O)=O)F.FC(F)(S(=O)([N-]S(=O)(C(F)(F)F)=O)=O)F.[Mn+2]",
  "Tris(pentafluorophenyl)phosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F",
  "Bis(pentafluorophenyl)phenylphosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=C(F)C(F)=C(F)C(F)=C2F)C3=CC=CC=C3",
  "(Pentafluorophenyl)diphenylphosphine": "FC1=C(F)C(F)=C(F)C(F)=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "1,2-Bis[bis(pentafluorophenyl)phosphino]ethane": "FC1=C(F)C(F)=C(F)C(F)=C1P(CCP(C2=C(F)C(F)=C(F)C(F)=C2F)C3=C(F)C(F)=C(F)C(F)=C3F)C4=C(F)C(F)=C(F)C(F)=C4F",
  "Tris(4-fluorophenyl)phosphine": "FC1=CC=C(P(C2=CC=C(F)C=C2)C3=CC=C(F)C=C3)C=C1",
  "2-(Diphenylphosphino)benzonitrile": "N#CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "(3-Phenyl-1H-inden-1-ylidene)bis(tricyclohexylphosphine)ruthenium(II) Dichloride ": "[Ru+2]=C1C=C(C2=CC=CC=C2)C3=C1C=CC=C3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.P(C7CCCCC7)(C8CCCCC8)C9CCCCC9.[Cl-].[Cl-]",
  "Bis(2,4-pentanedionato)bis(2-propanolato)titanium(IV)": "CC(/C=C(C)\\[O-])=O.CC(/C=C(C)\\[O-])=O.[O-]C(C)C.[O-]C(C)C.[Ti+4]",
  "Cyclopentadienyliron Dicarbonyl Dimer": "[C]=O.[C]=O.[Fe]C1=CC=CC1",
  "Dicobalt Octacarbonyl (stabilized with 1-5% Hexane)": "[CH-]=O.[CH-]=O.[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[C-]#[O+].[Co].[Co+2]",
  "Dimethyltitanocene": "C[Ti]C.c1cccc1.c2cccc2",
  "Tricyclohexylphosphine": "P(C1CCCCC1)(C2CCCCC2)C3CCCCC3",
  "Tris(2-phenylpyridinato)iridium(III)": "C1(C2=CC=CC=C2)=NC=CC=C1.C3(C4=CC=CC=C4)=NC=CC=C3.C5(C6=CC=CC=C6)=NC=CC=C5.[Ir]",
  "3-(Diphenylphosphino)-1-propylamine": "NCCCP(C1=CC=CC=C1)C2=CC=CC=C2",
  "4-Acetamido-2,2,6,6-tetramethyl-1-oxopiperidinium Tetrafluoroborate": "O=[N+]1C(C)(C)CC(NC(C)=O)CC1(C)C.F[B-](F)(F)F",
  "Potassium Perrhenate": "O=[Re](=O)([O-])=O.[K+]",
  "Bis(2,4-pentanedionato)nickel(II) Hydrate": "O=C(/C=C([O-])/C)C.O=C(/C=C([O-])/C)C.[Ni+2].O",
  "Bis(hexafluoroacetylacetonato)nickel(II) Hydrate": "O=C(/C=C(C(F)(F)F)\\[O-])C(F)(F)F.[O-]/C(C(F)(F)F)=C\\C(C(F)(F)F)=O.[Ni+2]",
  "Palladium(II) Trifluoroacetate": "O=C([O-])C(F)(F)F.O=C([O-])C(F)(F)F.[Pd+2]",
  "Tris(2-carboxyethyl)phosphine Hydrochloride": "O=C(CCP(CCC(O)=O)CCC(O)=O)O.[H]Cl",
  "4-(Diphenylphosphino)benzoic Acid": "O=C(O)C1=CC=C(P(C2=CC=CC=C2)C3=CC=CC=C3)C=C1",
  "2-(Diphenylphosphino)benzoic Acid": "O=C(O)C1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "Tetrabromofluorescein": "O=C1OC2(C3=C(OC4=C2C=CC(O)=C4)C=C(O)C=C3)C5=C1C(Br)=C(Br)C(Br)=C5Br",
  "2-Chloro-4H-1,3,2-benzodioxaphosphorin-4-one": "O=C1OP(Cl)OC2=CC=CC=C12",
  "2-(Diphenylphosphino)benzaldehyde": "O=CC1=CC=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylphosphine Oxide": "O=P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Diphenylphosphinic Chloride": "O=P(C1=CC=CC=C1)(C2=CC=CC=C2)Cl",
  "Phenylphosphonic Dichloride": "O=P(C1=CC=CC=C1)(Cl)Cl",
  "Diphenylphosphine Oxide": "O=P(C1=CC=CC=C1)C2=CC=CC=C2",
  "4-Chlorophenyl Phosphorodichloridate": "O=P(Cl)(Cl)OC1=CC=C(Cl)C=C1",
  "Diphosphoryl Chloride": "O=P(OP(O)(O)=O)(Cl)O",
  "(±)-1,1'-Binaphthyl-2,2'-diyl Hydrogenphosphate": "O=P1(OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)O",
  "Nickel(II) Trifluoromethanesulfonate": "O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O.[Ni+2]",
  "Sodium Diphenylphosphinobenzene-3-sulfonate": "O=S(C1=CC(P(C2=CC=CC=C2)C3=CC=CC=C3)=CC=C1)([O-])=O.[Na+]",
  "2-Hydroxy-2-azaadamantane": "ON1C2CC3CC(C2)CC1C3",
  "Thiophosphoryl Chloride": "OP(O)(Cl)=S",
  "trans-1,2-Bis(diphenylphosphino)ethylene": "P(/C=C/P(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "Triphenylphosphine": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Bis(triphenylphosphine)nickel(II) Dichloride": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.[Ni+2].[Cl-].[Cl-]",
  "Tris(triphenylphosphine)ruthenium(II) Dichloride": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3.P(C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6.P(C7=CC=CC=C7)(C8=CC=CC=C8)C9=CC=CC=C9.[Ru+2].[Cl-].[Cl-]",
  "Diphenyl-2-pyridylphosphine": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=NC=CC=C3",
  "Cyclohexyldiphenylphosphine": "P(C1=CC=CC=C1)(C2CCCCC2)C3=CC=CC=C3",
  "Tri(2-furyl)phosphine": "P(C1=CC=CO1)(C2=CC=CO2)C3=CC=CO3",
  "Tri(2-thienyl)phosphine": "P(C1=CC=CS1)(C2=CC=CS2)C3=CC=CS3",
  "Dicyclohexylphenylphosphine": "P(C1CCCCC1)(C2CCCCC2)C3=CC=CC=C3",
  "Bis(tricyclohexylphosphine)nickel(II) Dichloride": "P(C1CCCCC1)(C2CCCCC2)C3CCCCC3.P(C4CCCCC4)(C5CCCCC5)C6CCCCC6.[Ni+2].[Cl-].[Cl-]",
  "1,6-Bis(diphenylphosphino)hexane": "P(CCCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,5-Bis(diphenylphosphino)pentane": "P(CCCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,4-Bis(diphenylphosphino)butane": "P(CCCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "1,3-Bis(diphenylphosphino)propane": "P(CCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "[1,3-Bis(diphenylphosphino)propane]nickel(II) Dichloride": "P(CCCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Ni+2].[Cl-].[Cl-]",
  "1,2-Bis(diphenylphosphino)ethane": "P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "[1,2-Bis(diphenylphosphino)ethane]nickel(II) Dichloride": "P(CCP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4.[Ni+2].[Cl-].[Cl-]",
  "Bis(diphenylphosphino)methane": "P(CP(C1=CC=CC=C1)C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4",
  "Triphenyl Phosphite": "P(OC1=CC=CC=C1)(OC2=CC=CC=C2)OC3=CC=CC=C3",
  "Tebbe reagent": "C[Al-]1(C[Ti+2][Cl+]1)C.[cH-]1cccc1.[cH-]1cccc1"
}