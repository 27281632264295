export const phaseTransferReagents = {
  "(Ferrocenylmethyl)dodecyldimethylammonium Bromide": "C[N+](CCCCCCCCCCCC)(C)Cc1cccc1.[Del][Fe][Del].[Br-].c2cccc2",
  "(Ferrocenylmethyl)trimethylammonium Bromide": "C[N+](C)(C)Cc1cccc1.[Del][Fe][Del].[Br-].c2cccc2",
  "4-Dimethylamino-1-neopentylpyridinium Chloride": "CC(C)(C)C[N+]1=CC=C(N(C)C)C=C1.[Cl-]",
  "Benzylcetyldimethylammonium Chloride Hydrate": "C[N+](CCCCCCCCCCCCCCCC)(CC1=CC=CC=C1)C.[H]O[H].[Cl-]",
  "Benzyldimethyltetradecylammonium Chloride Hydrate": "CCCCCCCCCCCCCC[N+](C)(CC1=CC=CC=C1)C.[H]O[H].[Cl-]",
  "Benzyldodecyldimethylammonium Bromide": "C[N+](CCCCCCCCCCCC)(CC1=CC=CC=C1)C.[Br-]",
  "Benzyldodecyldimethylammonium Chloride Dihydrate": "C[N+](CCCCCCCCCCCC)(CC1=CC=CC=C1)C.[H]O[H].[H]O[H].[Cl-]",
  "Benzyltributylammonium Bromide": "CCCC[N+](CCCC)(CC1=CC=CC=C1)CCCC.[Br-]",
  "Benzyltributylammonium Chloride": "CCCC[N+](CCCC)(CC1=CC=CC=C1)CCCC.[Cl-]",
  "Benzyltriethylammonium Bromide": "CC[N+](CC)(CC1=CC=CC=C1)CC.[Br-]",
  "Benzyltriethylammonium Chloride": "CC[N+](CC)(CC1=CC=CC=C1)CC.[Cl-]",
  "Benzyltriethylammonium Iodide": "CC[N+](CC)(CC1=CC=CC=C1)CC.[I-]",
  "Benzyltrimethylammonium Bromide": "C[N+](C)(CC1=CC=CC=C1)C.[Br-]",
  "Benzyltrimethylammonium Chloride": "C[N+](C)(CC1=CC=CC=C1)C.[Cl-]",
  "Benzyltrimethylammonium Dichloroiodate": "C[N+](C)(CC1=CC=CC=C1)C.Cl[I-]Cl",
  "Bis(2-hydroxyethyl)dimethylammonium Chloride": "C[N+](CCO)(CCO)C.[Cl-]",
  "Bromocholine Bromide": "BrCC[N+](C)(C)C.[Br-]",
  "Carbamylcholine Chloride": "C[N+](C)(C)CCOC(N)=O.[Cl-]",
  "Chlorocholine Chloride": "ClCC[N+](C)(C)C.[Cl-]",
  "Choline Chloride": "OCC[N+](C)(C)C.[Cl-]",
  "Decyltrimethylammonium Bromide": "C[N+](C)(CCCCCCCCCC)C.[Br-]",
  "Decyltrimethylammonium Chloride": "C[N+](C)(CCCCCCCCCC)C.[Cl-]",
  "Diallyldimethylammonium Chloride": "C[N+](CC=C)(CC=C)C.[Cl-]",
  "Didecyldimethylammonium Bromide": "C[N+](CCCCCCCCCC)(CCCCCCCCCC)C.[Br-]",
  "Didodecyldimethylammonium Chloride": "C[N+](CCCCCCCCCCCC)(CCCCCCCCCCCC)C.[Cl-]",
  "Dilauryldimethylammonium Bromide": "C[N+](CCCCCCCCCCCC)(CCCCCCCCCCCC)C.[Br-]",
  "Dimethyldimyristylammonium Bromide": "CCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCC.[Br-]",
  "Dimethyldioctadecylammonium Bromide": "CCCCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCCCC.[Br-]",
  "Dimethyldioctadecylammonium Iodide": "CCCCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCCCC.[I-]",
  "Dimethyldioctylammonium Bromide": "CCCCCCCC[N+](C)(C)CCCCCCCC.[Br-]",
  "Dimethyldipalmitylammonium Bromide": "CCCCCCCCCCCCCCCC[N+](C)(C)CCCCCCCCCCCCCCCC.[Br-]",
  "Dodecyltrimethylammonium Bromide": "C[N+](C)(CCCCCCCCCCCC)C.[Br-]",
  "Dodecyltrimethylammonium Chloride": "C[N+](C)(CCCCCCCCCCCC)C.[Cl-]",
  "Ethyltrimethylammonium Iodide": "C[N+](C)(CC)C.[I-]",
  "Hexadecyltrimethylammonium Bromide": "C[N+](C)(CCCCCCCCCCCCCCCC)C.[Br-]",
  "Hexadecyltrimethylammonium Chloride": "C[N+](C)(CCCCCCCCCCCCCCCC)C.[Cl-]",
  "Hexyltrimethylammonium Bromide": "C[N+](C)(CCCCCC)C.[Br-]",
  "Methyltri-n-octylammonium Chloride": "CCCCCCCC[N+](CCCCCCCC)(C)CCCCCCCC.[Cl-]",
  "Methyltri-n-octylammonium Hydrogen Sulfate": "CCCCCCCC[N+](CCCCCCCC)(C)CCCCCCCC.O=S(O)([O-])=O",
  "n-Octyltrimethylammonium Bromide": "C[N+](C)(CCCCCCCC)C.[Br-]",
  "n-Octyltrimethylammonium Chloride": "C[N+](C)(CCCCCCCC)C.[Cl-]",
  "Tetra(decyl)ammonium Bromide": "CCCCCCCCCC[N+](CCCCCCCCCC)(CCCCCCCCCC)CCCCCCCCCC.[Br-]",
  "Tetraamylammonium Bromide": "CCCCC[N+](CCCCC)(CCCCC)CCCCC.[Br-]",
  "Tetraamylammonium Chloride": "CCCCC[N+](CCCCC)(CCCCC)CCCCC.[Cl-]",
  "Tetrabutylammonium Acetate": "CCCC[N+](CCCC)(CCCC)CCCC.CC([O-])=O",
  "Tetrabutylammonium Bifluoride": "CCCC[N+](CCCC)(CCCC)CCCC.[H]F.[F-]",
  "Tetrabutylammonium Bromide": "CCCC[N+](CCCC)(CCCC)CCCC.[Br-]",
  "Tetrabutylammonium Chloride": "CCCC[N+](CCCC)(CCCC)CCCC.[Cl-]",
  "Tetrabutylammonium Hexafluorophosphate": "CCCC[N+](CCCC)(CCCC)CCCC.F[P-](F)(F)(F)(F)F",
  "Tetrabutylammonium Hydrogen Sulfate": "CCCC[N+](CCCC)(CCCC)CCCC.O=S(O)([O-])=O",
  "Tetrabutylammonium Iodide": "CCCC[N+](CCCC)(CCCC)CCCC.[I-]",
  "Tetrabutylammonium Perchlorate": "CCCC[N+](CCCC)(CCCC)CCCC.O=Cl(=O)([O-])=O",
  "Tetrabutylammonium Tetrafluoroborate": "CCCC[N+](CCCC)(CCCC)CCCC.F[B-](F)(F)F",
  "Tetrabutylammonium Tetraphenylborate": "CCCC[N+](CCCC)(CCCC)CCCC.C1([B-](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Tetrabutylammonium Thiocyanate": "CCCC[N+](CCCC)(CCCC)CCCC.[S-]C#N",
  "Tetrabutylammonium Tribromide": "CCCC[N+](CCCC)(CCCC)CCCC.[Br-].[Br-].[Br-]",
  "Tetrabutylammonium Trifluoromethanesulfonate": "CCCC[N+](CCCC)(CCCC)CCCC.O=S(C(F)(F)F)([O-])=O",
  "Tetrabutylammonium Triiodide": "CCCC[N+](CCCC)(CCCC)CCCC.[I-].[I-].[I-]",
  "Tetrabutylphosphonium Bromide": "CCCC[P+](CCCC)(CCCC)CCCC.[Br-]",
  "Tetrabutylphosphonium Chloride": "CCCC[P+](CCCC)(CCCC)CCCC.[Cl-]",
  "Tetrabutylphosphonium Hexafluorophosphate": "CCCC[P+](CCCC)(CCCC)CCCC.F[P-](F)(F)(F)(F)F",
  "Tetrabutylphosphonium Tetrafluoroborate": "CCCC[P+](CCCC)(CCCC)CCCC.F[B-](F)(F)F",
  "Tetradecyltrimethylammonium Bromide": "C[N+](C)(CCCCCCCCCCCCCC)C.[Br-]",
  "Tetraethylammonium Bromide": "CC[N+](CC)(CC)CC.[Br-]",
  "Tetraethylammonium Chloride": "CC[N+](CC)(CC)CC.[Cl-]",
  "Tetraethylammonium Iodide": "CC[N+](CC)(CC)CC.[I-]",
  "Tetraethylammonium Nitrate": "CC[N+](CC)(CC)CC.[O-][N+]([O-])=O",
  "Tetraethylammonium Tetrafluoroborate": "CC[N+](CC)(CC)CC.F[B-](F)(F)F",
  "Tetraethylammonium Trifluoromethanesulfonate": "CC[N+](CC)(CC)CC.O=S(C(F)(F)F)([O-])=O",
  "Tetraethylammonium p-Toluenesulfonate": "CC[N+](CC)(CC)CC.CC1=CC=C(S(=O)([O-])=O)C=C1",
  "Tetraethylphosphonium Bromide": "CC[P+](CC)(CC)CC.[Br-]",
  "Tetraethylphosphonium Hexafluorophosphate": "CC[P+](CC)(CC)CC.F[P-](F)(F)(F)(F)F",
  "Tetraethylphosphonium Tetrafluoroborate": "CC[P+](CC)(CC)CC.F[B-](F)(F)F",
  "Tetraheptylammonium Bromide": "CCCCCCC[N+](CCCCCCC)(CCCCCCC)CCCCCCC.[Br-]",
  "Tetraheptylammonium Iodide": "CCCCCCC[N+](CCCCCCC)(CCCCCCC)CCCCCCC.[I-]",
  "Tetrahexylammonium Bromide": "CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[Br-]",
  "Tetrahexylammonium Hydrogen Sulfate": "CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.O=S(O)([O-])=O",
  "Tetrahexylammonium Iodide": "CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[I-]",
  "Tetrakis(hydroxymethyl)phosphonium Chloride": "OC[P+](CO)(CO)CO.[Cl-]",
  "Tetrakis(hydroxymethyl)phosphonium Sulfate ": "OC[P+](CO)(CO)CO.OC[P+](CO)(CO)CO.O=S([O-])([O-])=O",
  "Tetramethylammonium Acetate": "C[N+](C)(C)C.CC([O-])=O",
  "Tetramethylammonium Acetate ": "C[N+](C)(C)C.CC([O-])=O",
  "Tetramethylammonium Bromide": "C[N+](C)(C)C.[Br-]",
  "Tetramethylammonium Chloride": "C[N+](C)(C)C.[Cl-]",
  "Tetramethylammonium Hexafluorophosphate": "C[N+](C)(C)C.F[P-](F)(F)(F)(F)F",
  "Tetramethylammonium Iodide": "C[N+](C)(C)C.[I-]",
  "Tetramethylammonium Sulfate": "C[N+](C)(C)C.C[N+](C)(C)C.O=S([O-])([O-])=O",
  "Tetramethylammonium Tetrafluoroborate": "C[N+](C)(C)C.F[B-](F)(F)F",
  "Tetra-n-octylammonium Bromide": "CCCCCCCC[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Br-]",
  "Tetra-n-octylammonium Iodide": "CCCCCCCC[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[I-]",
  "Tetra-n-octylphosphonium Bromide": "CCCCCCCC[P+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Br-]",
  "Tetraphenylphosphonium Bromide": "C1([P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Br-]",
  "Tetraphenylphosphonium Chloride": "C1([P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1.[Cl-]",
  "Tetrapropylammonium Bromide": "CCC[N+](CCC)(CCC)CCC.[Br-]",
  "Tetrapropylammonium Chloride": "CCC[N+](CCC)(CCC)CCC.[Cl-]",
  "Tetrapropylammonium Iodide": "CCC[N+](CCC)(CCC)CCC.[I-]",
  "trans-2-Butene-1,4-bis(triphenylphosphonium Chloride)": "C1([P+](C/C=C/C[P+](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1.[Cl-].[Cl-]",
  "Tributyl(cyanomethyl)phosphonium Chloride": "N#CC[P+](CCCC)(CCCC)CCCC.[Cl-]",
  "Tributyldodecylphosphonium Bromide": "CCCCCCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]",
  "Tributylhexadecylphosphonium Bromide": "CCCCCCCCCCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]",
  "Tributylmethylammonium Chloride": "C[N+](CCCC)(CCCC)CCCC.[Cl-]",
  "Tributyl-n-octylphosphonium Bromide": "CCCCCCCC[P+](CCCC)(CCCC)CCCC.[Br-]",
  "Triethylmethylammonium Chloride": "C[N+](CC)(CC)CC.[Cl-]",
  "Triethylphenylammonium Chloride": "CC[N+](CC)(CC)C1=CC=CC=C1.[Cl-]",
  "Trimethyl[2-[(trimethylsilyl)methyl]benzyl]ammonium Iodide": "C[Si](CC1=CC=CC=C1C[N+](C)(C)C)(C)C.[I-]",
  "Trimethylnonylammonium Bromide": "CCCCCCCCC[N+](C)(C)C.[Br-]",
  "Trimethylphenylammonium Bromide": "C[N+](C)(C)C1=CC=CC=C1.[Br-]",
  "Trimethylphenylammonium Chloride": "C[N+](C)(C)C1=CC=CC=C1.[Cl-]",
  "Trimethylphenylammonium Tribromide": "C[N+](C)(C)C1=CC=CC=C1.[Br-].[Br-].[Br-]",
  "Trimethylpropylammonium Bromide": "CCC[N+](C)(C)C.[Br-]",
  "Trimethylstearylammonium Bromide": "CCCCCCCCCCCCCCCCCC[N+](C)(C)C.[Br-]",
  "Trimethyltetradecylammonium Chloride": "CCCCCCCCCCCCCC[N+](C)(C)C.[Cl-]",
  "Trioctylmethylammonium Chloride": "C[N+](CCCCCCCC)(CCCCCCCC)CCCCCCCC.[Cl-]",
}