export const orgBases = {
  "1,1,3,3-Tetramethylguanidine": "N=C(N(C)C)N(C)C",
  "1,4-Diazabicyclo[2.2.2]octane": "N1(CC2)CCN2CC1",
  "1,5,7-Triazabicyclo[4.4.0]dec-5-ene": "N12CCCN=C1NCCC2",
  "1,5-Diazabicyclo[4.3.0]-5-nonene": "N12CCCN=C1CCC2",
  "1,8-Bis(dimethylamino)naphthalene": "CN(C1=CC=CC2=CC=CC(N(C)C)=C12)C",
  "1,8-Diazabicyclo[5.4.0]-7-undecene": "N12CCCCCC1=NCCC2",
  "2,2,6,6-Tetramethylpiperidine": "CC1(C)CCCC(C)(C)N1",
  "2,6-Lutidine": "CC1=NC(C)=CC=C1",
  "4-Dimethylaminopyridine": "CN(C1=CC=NC=C1)C",
  "7-Methyl-1,5,7-triazabicyclo[4.4.0]dec-5-ene": "CN1C2=NCCCN2CCC1",
  "9-Azajulolidine": "N1(CCC2)CCCC3=CN=CC2=C31",
  "Benzyltrimethylammonium Hydroxide": "C[N+](C)(CC1=CC=CC=C1)C.[OH-]",
  "Butylmagnesium Chloride ": "Cl[Mg]CCCC",
  "Choline ": "OCC[N+](C)(C)C",
  "Dimethylamine": "CNC",
  "Ethylamine ": "NCC",
  "Ethylamine Hydrochloride": "NCC.[H]Cl",
  "Ethylmagnesium Bromide": "Br[Mg]CC",
  "Ethylmagnesium Chloride": "Cl[Mg]CC",
  "Hexadecyltrimethylammonium Hydroxide ": "C[N+](C)(CCCCCCCCCCCCCCCC)C.[OH-]",
  "Hexylmagnesium Bromide": "Br[Mg]CCCCCC",
  "Isobutylmagnesium Bromide": "Br[Mg]CC(C)C",
  "Isopropylmagnesium Chloride": "Cl[Mg]C(C)C",
  "Lithium Bis(trimethylsilyl)amide": "C[Si]([N-][Si](C)(C)C)(C)C.[Li+]",
  "Lithium Diisopropylamide ": "CC([N-]C(C)C)C.[Li+]",
  "Lithium tert-Butoxide ": "CC(C)([O-])C.[Li+]",
  "Methylamine": "NC",
  "Methylmagnesium Bromide": "Br[Mg]C",
  "Methylmagnesium Iodide": "I[Mg]C",
  "N,N-Diisopropylethylamine": "CCN(C(C)C)C(C)C",
  "Pentylmagnesium Bromide": "Br[Mg]CCCCC",
  "Potassium Bis(trimethylsilyl)amide": "C[Si]([N-][Si](C)(C)C)(C)C.[K+]",
  "Potassium tert-Butoxide": "CC(C)([O-])C.[K+]",
  "Potassium tert-Pentoxide": "CCC(C)([O-])C.[K+]",
  "Sodium Bis(trimethylsilyl)amide": "C[Si]([N-][Si](C)(C)C)(C)C.[Na+]",
  "Sodium Ethoxide": "CC[O-].[Na+]",
  "Sodium Methoxide": "C[O-].[Na+]",
  "Sodium tert-Butoxide": "CC(C)([O-])C.[Na+]",
  "Sodium tert-Pentoxide": "CCC(C)([O-])C.[Na+]",
  "tert-Butylmagnesium Chloride": "Cl[Mg]C(C)(C)C",
  "Tetrabutylphosphonium Hydroxide": "CCCC[P+](CCCC)(CCCC)CCCC.[OH-]",
  "Tetraethylammonium Hydroxide": "CC[N+](CC)(CC)CC.[OH-]",
  "Tetrahexylammonium Hydroxide": "CCCCCC[N+](CCCCCC)(CCCCCC)CCCCCC.[OH-]",
  "Tetramethylammonium Hydroxide": "C[N+](C)(C)C.[OH-]",
  "Tetrapropylammonium Hydroxide": "CCC[N+](CCC)(CCC)CCC.[OH-]",
  "Tributylamine": "CCCCN(CCCC)CCCC",
  "Triethylamine": "CCN(CC)CC",
  "Trimethylphenylammonium Hydroxide": "C[N+](C)(C)C1=CC=CC=C1.[OH-]",
  "Tris(hydroxymethyl)aminomethane": "NC(CO)(CO)CO"
}