export const metallorganics = {
  "(1-Bromovinyl)trimethylsilane": "C[Si](C)(C(Br)=C)C",
  "(1E)-1-tert-Butoxy-1-(trimethylsilyloxy)propene": "C/C=C(OC(C)(C)C)/O[Si](C)(C)C",
  "(1R,3R)-5-(Bromomethylene)-1,3-bis(tert-butyldimethylsilyloxy)cyclohexane": "C[Si](O[C@H](C1)C[C@H](O[Si](C)(C(C)(C)C)C)CC1=CBr)(C(C)(C)C)C",
  "(2,5-Dimethylphenyl)magnesium Bromide": "Br[Mg]C1=CC(C)=CC=C1C",
  "(2-Bromo-4,5-dimethoxyphenoxy)trimethylsilane": "C[Si](C)(OC1=CC(OC)=C(OC)C=C1Br)C",
  "(2-Bromoallyl)trimethylsilane": "C[Si](C)(CC(Br)=C)C",
  "(2-Bromophenylethynyl)trimethylsilane": "C[Si](C)(C#CC1=CC=CC=C1Br)C",
  "(3,5-Dibromophenyl)triphenylsilane": "BrC1=CC(Br)=CC([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=C1",
  "(3-Bromopropoxy)(tert-butyl)dimethylsilane": "C[Si](C(C)(C)C)(OCCCBr)C",
  "(3-Bromopropyl)trimethoxysilane": "CO[Si](OC)(CCCBr)OC",
  "(3-Chloropropyl)tris(trimethylsilyloxy)silane": "C[Si](O[Si](O[Si](C)(C)C)(CCCCl)O[Si](C)(C)C)(C)C",
  "(3-Mercaptopropyl)triethoxysilane": "CCO[Si](OCC)(CCCS)OCC",
  "(3-Mercaptopropyl)trimethoxysilane": "CO[Si](OC)(CCCS)OC",
  "(Bromomethyl)trimethylsilane": "C[Si](C)(CBr)C",
  "(Chloromethyl)isopropoxydimethylsilane": "C[Si](OC(C)C)(CCl)C",
  "(Chloromethyl)trimethylsilane": "C[Si](C)(CCl)C",
  "(E)-Trimethyl(3,3,3-trifluoro-1-propenyl)silane": "FC(F)(F)/C=C/[Si](C)(C)C",
  "(Iodomethyl)trimethylsilane": "C[Si](C)(CI)C",
  "(m-Terphenyl-5'-yl)trimethylsilane": "C[Si](C)(C1=CC(C2=CC=CC=C2)=CC(C3=CC=CC=C3)=C1)C",
  "(Phenylthio)trimethylsilane": "C[Si](C)(SC1=CC=CC=C1)C",
  "(Phenylthiomethyl)trimethylsilane": "C[Si](C)(CSC1=CC=CC=C1)C",
  "(tert-Butyldimethylsilyl)acetylene": "C#C[Si](C)(C(C)(C)C)C",
  "(tert-Butyldimethylsilyloxy)malononitrile": "N#CC(O[Si](C)(C(C)(C)C)C)C#N",
  "(Trifluoromethyl)trimethylsilane": "C[Si](C)(C(F)(F)F)C",
  "[(4-Bromobenzyl)oxy](tert-butyl)dimethylsilane": "C[Si](C(C)(C)C)(OCC1=CC=C(Br)C=C1)C",
  "[(4-Methoxyphenyl)ethynyl]trimethylsilane": "C[Si](C)(C#CC1=CC=C(OC)C=C1)C",
  "[3-(6-Aminohexylamino)propyl]trimethoxysilane": "CO[Si](OC)(CCCNCCCCCCN)OC",
  "[3-(Diethylamino)propyl]trimethoxysilane": "CO[Si](OC)(CCCN(CC)CC)OC",
  "[3-(N,N-Dimethylamino)propyl]trimethoxysilane": "CO[Si](OC)(CCCN(C)C)OC",
  "[3-(Trimethoxysilyl)propyl]succinic Anhydride": "O=C(O1)C(CCC[Si](OC)(OC)OC)CC1=O",
  "1-(Triisopropylsilyl)-1-propyne": "CC#C[Si](C(C)C)(C(C)C)C(C)C",
  "1-(Trimethoxysilyl)naphthalene": "CO[Si](C1=C2C=CC=CC2=CC=C1)(OC)OC",
  "1-(Trimethylsilyl)-1-pentyne": "CCCC#C[Si](C)(C)C",
  "1-(Trimethylsilyl)-1-propyne": "CC#C[Si](C)(C)C",
  "1,1,1,3,3-Pentamethyldisiloxane": "C[SiH](C)O[Si](C)(C)C",
  "1,1,1,3,5,5,5-Heptamethyl-3-[(trimethylsilyl)oxy]trisiloxane": "C[Si](O[Si](O[Si](C)(C)C)(C)O[Si](C)(C)C)(C)C",
  "1,1,1,3,5,5,5-Heptamethyltrisiloxane": "C[Si](C)(C)O[SiH](C)O[Si](C)(C)C",
  "1,1,1,3,5,7,7,7-Octamethyltetrasiloxane": "C[Si](C)(C)O[SiH](C)O[SiH](C)O[Si](C)(C)C",
  "1,1,1,5,5,5-Hexamethyl-3-[(trimethylsilyl)oxy]-3-vinyltrisiloxane": "C=C[Si](O[Si](C)(C)C)(O[Si](C)(C)C)O[Si](C)(C)C",
  "1,1,1,5,5,5-Hexamethyl-3-phenyl-3-(trimethylsilyloxy)trisiloxane": "C[Si](O[Si](O[Si](C)(C)C)(C1=CC=CC=C1)O[Si](C)(C)C)(C)C",
  "1,1,2,2-Tetrachloro-1,2-dimethyldisilane": "C[Si](Cl)(Cl)[Si](Cl)(Cl)C",
  "1,1,2,2-Tetraphenyldisilane": "[SiH](C1=CC=CC=C1)(C2=CC=CC=C2)[SiH](C3=CC=CC=C3)C4=CC=CC=C4",
  "1,1,2,3,4,5-Hexaphenylsilole": "C1(C2=CC=CC=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C(C5=CC=CC=C5)[Si]1(C6=CC=CC=C6)C7=CC=CC=C7",
  "1,1,3,3,5,5,7,7,9,9,11,11,13,13-Tetradecamethylheptasiloxane": "C[SiH](C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[SiH](C)C",
  "1,1,3,3,5,5,7,7,9,9,11,11-Dodecamethylhexasiloxane": "C[SiH](C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[SiH](C)C",
  "1,1,3,3,5,5-Hexaethoxy-1,3,5-trisilacyclohexane": "CCO[Si]1(OCC)C[Si](OCC)(OCC)C[Si](OCC)(OCC)C1",
  "1,1,3,3,5,5-Hexamethyltrisiloxane": "C[SiH](C)O[Si](C)(C)O[SiH](C)C",
  "1,1,3,3-Tetramethyl-1,3-diphenyldisiloxane": "C[Si](C1=CC=CC=C1)(C)O[Si](C)(C)C2=CC=CC=C2",
  "1,1,3,3-Tetramethyldisiloxane": "C[SiH](C)O[SiH](C)C",
  "1,1-Dimethyl-1,3-dihydrobenzo[c][1,2]oxasilole": "C[Si]1(C)OCC2=CC=CC=C12",
  "1,2-Bis(trichlorosilyl)ethane": "Cl[Si](CC[Si](Cl)(Cl)Cl)(Cl)Cl",
  "1,2-Bis(triethoxysilyl)ethane": "CCO[Si](CC[Si](OCC)(OCC)OCC)(OCC)OCC",
  "1,2-Bis(trimethylsilyl)benzene": "C[Si](C1=CC=CC=C1[Si](C)(C)C)(C)C",
  "1,2-Dichlorotetramethyldisilane": "Cl[Si](C)(C)[Si](C)(C)Cl",
  "1,3,5-Tris(3,3,3-trifluoropropyl)-1,3,5-trimethylcyclotrisiloxane": "C[Si]1(CCC(F)(F)F)O[Si](C)(CCC(F)(F)F)O[Si](C)(CCC(F)(F)F)O1",
  "1,3-Bis(4-hydroxybutyl)tetramethyldisiloxane": "OCCCC[Si](C)(C)O[Si](C)(C)CCCCO",
  "1,3-Bis(chloromethyl)tetramethyldisiloxane": "ClC[Si](C)(C)O[Si](C)(C)CCl",
  "1,3-Bis[(trimethylsilyl)ethynyl]benzene": "C[Si](C#CC1=CC(C#C[Si](C)(C)C)=CC=C1)(C)C",
  "1,3-Dimethoxy-1,1,3,3-tetraphenyldisiloxane": "CO[Si](C1=CC=CC=C1)(C2=CC=CC=C2)O[Si](C3=CC=CC=C3)(OC)C4=CC=CC=C4",
  "1,3-Divinyltetramethyldisiloxane": "C=C[Si](C)(C)O[Si](C)(C)C=C",
  "1,4-Bis(dimethylsilyl)benzene": "C[SiH](C1=CC=C([SiH](C)C)C=C1)C",
  "1,4-Bis(trimethylsilyl)-1,3-butadiyne": "C[Si](C#CC#C[Si](C)(C)C)(C)C",
  "1,5-Dichloro-1,1,3,3,5,5-hexamethyltrisiloxane": "C[Si](C)(Cl)O[Si](C)(C)O[Si](C)(Cl)C",
  "1,5-Dimethyl-2,4-bis[2-(trimethylsilyl)ethynyl]benzene": "C[Si](C#CC1=C(C)C=C(C)C(C#C[Si](C)(C)C)=C1)(C)C",
  "1,7-Dichloro-1,1,3,3,5,5,7,7-octamethyltetrasiloxane": "C[Si](C)(Cl)O[Si](C)(C)O[Si](C)(C)O[Si](C)(Cl)C",
  "1-[3-(Trimethoxysilyl)propyl]urea": "O=C(N)NCCC[Si](OC)(OC)OC",
  "1-Bromo-3-(trimethylsilyl)benzene": "C[Si](C1=CC(Br)=CC=C1)(C)C",
  "1-Bromo-4-(trimethylsilyl)benzene": "C[Si](C1=CC=C(Br)C=C1)(C)C",
  "1-Methoxy-3-(trimethylsilyloxy)-1,3-butadiene": "C=C(O[Si](C)(C)C)/C=C/OC",
  "1-Phenyl-2-(trimethylsilyl)acetylene": "C[Si](C#CC1=CC=CC=C1)(C)C",
  "2-(3,4-Epoxycyclohexyl)ethyltrimethoxysilane": "CO[Si](OC)(CCC1CC(O2)C2CC1)OC",
  "2-(Dimethylvinylsilyl)pyridine": "C=C[Si](C1=NC=CC=C1)(C)C",
  "2-(Trimethylsilylethynyl)thiophene": "C[Si](C#CC1=CC=CS1)(C)C",
  "2,4,6,8-Tetramethyl-2,4,6,8-tetravinylcyclotetrasiloxane": "C=CO1(C)[SiH2]O(C=C)(C)[SiH2]O(C=C)(C)[SiH2]O(C=C)(C)[SiH2]1",
  "2,4,6,8-Tetramethylcyclotetrasiloxane": "CO1[SiH2]O(C)[SiH2]O(C)[SiH2]O(C)[SiH2]1",
  "2,5-Bis(4-bromophenyl)-1,1-dimethyl-3,4-diphenylsilole": "C[Si]1(C)C(C2=CC=C(Br)C=C2)=C(C3=CC=CC=C3)C(C4=CC=CC=C4)=C1C5=CC=C(Br)C=C5",
  "2,5-Bis[(trimethylsilyl)ethynyl]thiophene": "C[Si](C#CC1=CC=C(C#C[Si](C)(C)C)S1)(C)C",
  "2,5-Dibromo-1,1,3,4-tetraphenylsilole": "BrC1=C(C2=CC=CC=C2)C(C3=CC=CC=C3)=C(Br)[Si]1(C4=CC=CC=C4)C5=CC=CC=C5",
  "2,5-Dibromo-1,1-dimethyl-3,4-diphenylsilole": "C[Si]1(C)C(Br)=C(C2=CC=CC=C2)C(C3=CC=CC=C3)=C1Br",
  "2-Cyanoethyltriethoxysilane": "CCO[Si](OCC)(CCC#N)OCC",
  "2-Trimethylsilyloxy-1,3-butadiene": "C=C(O[Si](C)(C)C)C=C",
  "2-Trimethylsilylthiophene": "C[Si](C1=CC=CS1)(C)C",
  "3-(2-Aminoethylamino)propyltrimethoxysilane": "CO[Si](OC)(CCCNCCN)OC",
  "3-(Dimethylsilyloxy)-1,1,5,5-tetramethyl-3-phenyltrisiloxane": "C[SiH](C)O[Si](C1=CC=CC=C1)(O[SiH](C)C)O[SiH](C)C",
  "3-(Triallylsilyl)propyl Acrylate": "C=CC(OCCC[Si](CC=C)(CC=C)CC=C)=O",
  "3-(Triallylsilyl)propyl Methacrylate": "CC(C(OCCC[Si](CC=C)(CC=C)CC=C)=O)=C",
  "3-(Triethoxysilyl)propyl Isocyanate": "CCO[Si](CCCN=C=O)(OCC)OCC",
  "3-(Trimethoxysilyl)propyl Acrylate": "C=CC(OCCC[Si](OC)(OC)OC)=O",
  "3-(Trimethoxysilyl)propyl Methacrylate": "CC(C(OCCC[Si](OC)(OC)OC)=O)=C",
  "3-(Trimethylsilylethynyl)thiophene": "C[Si](C#CC1=CSC=C1)(C)C",
  "3-[(Dimethylsilyl)oxy]-1,1,3,5,5-pentamethyltrisiloxane": "C[SiH](C)O[Si](C)(O[SiH](C)C)O[SiH](C)C",
  "3-[[Dimethyl(vinyl)silyl]oxy]-1,1,5,5-tetramethyl-3-phenyl-1,5-divinyltrisiloxane": "C=C[Si](C)(C)O[Si](C1=CC=CC=C1)(O[Si](C)(C)C=C)O[Si](C)(C)C=C",
  "3-[[tert-Butyl(dimethyl)silyl]oxy]-1-propanol": "OCCCO[Si](C)(C(C)(C)C)C",
  "3-Aminopropyltriethoxysilane": "CCO[Si](OCC)(CCCN)OCC",
  "3-Aminopropyltrimethoxysilane": "CO[Si](OC)(CCCN)OC",
  "3-Bromo-1-(trimethylsilyl)-1-propyne": "C[Si](C#CCBr)(C)C",
  "3-Chloropropyltriethoxysilane": "CCO[Si](OCC)(CCCCl)OCC",
  "3-Glycidyloxypropyltrimethoxysilane": "CO[Si](OC)(CCCOCC1CO1)OC",
  "3-Mercaptopropyl(dimethoxy)methylsilane": "C[Si](OC)(CCCS)OC",
  "3-Trimethoxysilylpropyl Chloride": "CO[Si](CCCCl)(OC)OC",
  "3-Trimethylsilyl-2-propyn-1-ol": "OCC#C[Si](C)(C)C",
  "4-(Trimethylsilyl)-3-butyn-2-one": "CC(C#C[Si](C)(C)C)=O",
  "4-(Trimethylsilyl)diphenylacetylene": "C[Si](C1=CC=C(C#CC2=CC=CC=C2)C=C1)(C)C",
  "4'-[(tert-Butyldimethylsilyloxy)methyl]-2,2,2-trifluoroacetophenone": "O=C(C1=CC=C(CO[Si](C)(C(C)(C)C)C)C=C1)C(F)(F)F",
  "4-Bromotetraphenylsilane": "BrC1=CC=C([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)C=C1",
  "4-Fluorophenylmagnesium Bromide": "Br[Mg]C1=CC=C(F)C=C1",
  "4-Trimethylsilyl-3-butyn-2-ol": "CC(O)C#C[Si](C)(C)C",
  "5-(Trimethylsilyl)-1,2,3,4,5-pentamethyl-1,3-cyclopentadiene": "CC1([Si](C)(C)C)C(C)=C(C)C(C)=C1C",
  "5,5''-Bis(trimethylstannyl)-2,2':5',2''-terthiophene": "C[Sn](C1=CC=C(C2=CC=C(C3=CC=C([Sn](C)(C)C)S3)S2)S1)(C)C",
  "Allyl(chloromethyl)dimethylsilane": "C[Si](CCl)(CC=C)C",
  "Allyl(tert-butyl)dimethylsilane": "C[Si](C(C)(C)C)(CC=C)C",
  "Allylchlorodimethylsilane": "C[Si](Cl)(CC=C)C",
  "Allylmagnesium Bromide": "Br[Mg]CC=C",
  "Allylmagnesium Chloride": "Cl[Mg]CC=C",
  "Allyloxytrimethylsilane": "C[Si](C)(OCC=C)C",
  "Allyltriethoxysilane": "CCO[Si](OCC)(CC=C)OCC",
  "Allyltriethylsilane": "CC[Si](CC)(CC=C)CC",
  "Allyltriisopropylsilane": "CC([Si](C(C)C)(CC=C)C(C)C)C",
  "Allyltrimethoxysilane": "CO[Si](OC)(CC=C)OC",
  "Allyltrimethylsilane": "C[Si](C)(CC=C)C",
  "Allyltriphenyltin": "C=CC[Sn](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Allyltris(trimethylsilyloxy)silane": "C[Si](O[Si](O[Si](C)(C)C)(CC=C)O[Si](C)(C)C)(C)C",
  "Benzylmagnesium Bromide": "Br[Mg]CC1=CC=CC=C1",
  "Benzylmagnesium Chloride": "Cl[Mg]CC1=CC=CC=C1",
  "Benzyltrimethylsilane": "C[Si](C)(CC1=CC=CC=C1)C",
  "Bis(chloromethyl)dimethylsilane": "C[Si](CCl)(CCl)C",
  "Bis(pentafluorophenyl)dimethylsilane": "C[Si](C1=C(F)C(F)=C(F)C(F)=C1F)(C2=C(F)C(F)=C(F)C(F)=C2F)C",
  "Bis(trichlorosilyl)acetylene": "Cl[Si](C#C[Si](Cl)(Cl)Cl)(Cl)Cl",
  "Bis(trimethylsilyl) Sulfide": "C[Si](S[Si](C)(C)C)(C)C",
  "Bis(trimethylsilyl)acetylene": "C[Si](C#C[Si](C)(C)C)(C)C",
  "Bis(trimethylsilyl)bromomethane": "BrC([Si](C)(C)C)[Si](C)(C)C",
  "Bis(trimethylsilyl)methane": "C[Si](C[Si](C)(C)C)(C)C",
  "Bis(trimethylsilyl)methylamine": "CN([Si](C)(C)C)[Si](C)(C)C",
  "Bis[2-(trimethylsilyloxy)ethyl] Ether": "C[Si](OCCOCCO[Si](C)(C)C)(C)C",
  "Bis[2-carboxyethylgermanium(IV)] Sesquioxide": "O=[Ge](CCC(O)=O)O[Ge](CCC(O)=O)=O",
  "Bis[3-(trimethoxysilyl)propyl]amine": "CO[Si](CCCNCCC[Si](OC)(OC)OC)(OC)OC",
  "Butylmagnesium Bromide": "Br[Mg]CCCC",
  "Butylmagnesium Chloride": "Cl[Mg]CCCC",
  "Butyltin Trichloride": "CCCC[Sn](Cl)(Cl)Cl",
  "Chloro(3-chloropropyl)dimethylsilane": "C[Si](CCCCl)(Cl)C",
  "Chloro(hexyl)dimethylsilane": "C[Si](CCCCCC)(Cl)C",
  "Chloro(methyl)(phenyl)(vinyl)silane": "C=C[Si](C)(Cl)C1=CC=CC=C1",
  "Chlorodimethylvinylsilane": "C=C[Si](C)(Cl)C",
  "Chloromethyldimethylphenylsilane": "C[Si](C)(CCl)C1=CC=CC=C1",
  "Chloropentamethyldisilane": "C[Si](C)(C)[Si](C)(Cl)C",
  "Chlorotriethoxysilane": "CCO[Si](OCC)(Cl)OCC",
  "Cyclohexyl(dimethoxy)methylsilane": "C[Si](OC)(C1CCCCC1)OC",
  "Cyclohexylmagnesium Bromide": "Br[Mg]C1CCCCC1",
  "Cyclopentylmagnesium Bromide": "Br[Mg]C1CCCC1",
  "Cyclopropylmagnesium Bromide": "Br[Mg]C1CC1",
  "Decamethylcyclopentasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Decamethyltetrasiloxane": "C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C",
  "Decyltriethoxysilane": "CCO[Si](OCC)(CCCCCCCCCC)OCC",
  "Decyltrimethoxysilane": "CO[Si](OC)(CCCCCCCCCC)OC",
  "Diacetoxydimethylsilane": "C[Si](OC(C)=O)(OC(C)=O)C",
  "Diallyldimethylsilane": "C[Si](CC=C)(CC=C)C",
  "Dibutyltin Bis(trifluoromethanesulfonate)": "CCCC[Sn+2]CCCC.O=S(C(F)(F)F)([O-])=O.O=S(C(F)(F)F)([O-])=O",
  "Dibutyltin Diacetate": "CCCC[Sn+2]CCCC.CC([O-])=O.CC([O-])=O",
  "Dibutyltin Dichloride": "CCCC[Sn](Cl)(Cl)CCCC",
  "Dibutyltin Dilaurate": "CCCC[Sn+2]CCCC.CCCCCCCCCCCC([O-])=O.CCCCCCCCCCCC([O-])=O",
  "Dibutyltin Oxide": "CCCC[Sn](CCCC)=O",
  "Dichloro(methyl)(3,3,3-trifluoropropyl)silane": "FC(F)(F)CC[Si](Cl)(Cl)C",
  "Dichloro(methyl)-n-octylsilane": "CCCCCCCC[Si](Cl)(Cl)C",
  "Dichlorocyclohexylmethylsilane": "C[Si](Cl)(Cl)C1CCCCC1",
  "Dichlorodecylmethylsilane": "C[Si](Cl)(Cl)CCCCCCCCCC",
  "Dichlorododecylmethylsilane": "C[Si](Cl)(Cl)CCCCCCCCCCCC",
  "Dichlorohexylmethylsilane": "C[Si](Cl)(Cl)CCCCCC",
  "Dichloromethylvinylsilane": "C=C[Si](Cl)(Cl)C",
  "Dicyclopentyl(dimethoxy)silane": "CO[Si](C1CCCC1)(C2CCCC2)OC",
  "Diethoxy(methyl)phenylsilane": "C[Si](OCC)(OCC)C1=CC=CC=C1",
  "Diethoxydimethylsilane": "C[Si](OCC)(OCC)C",
  "Diethoxydiphenylsilane": "CCO[Si](C1=CC=CC=C1)(OCC)C2=CC=CC=C2",
  "Diethoxymethylsilane": "C[SiH](OCC)OCC",
  "Diethoxymethylvinylsilane": "C=C[Si](OCC)(OCC)C",
  "Diethyl(isopropyl)silanol": "O[Si](CC)(CC)C(C)C",
  "Diisobutyldimethoxysilane": "CO[Si](CC(C)C)(CC(C)C)OC",
  "Dimethoxy(methyl)(3,3,3-trifluoropropyl)silane": "FC(F)(F)CC[Si](OC)(OC)C",
  "Dimethoxy(methyl)silane": "C[SiH](OC)OC",
  "Dimethoxydimethylsilane": "C[Si](OC)(OC)C",
  "Dimethoxydiphenylsilane": "CO[Si](C1=CC=CC=C1)(OC)C2=CC=CC=C2",
  "Dimethoxydi-p-tolylsilane": "CC1=CC=C([Si](OC)(OC)C2=CC=C(C)C=C2)C=C1",
  "Dimethoxymethylvinylsilane": "C=C[Si](OC)(OC)C",
  "Dimethylbis(phenylethynyl)silane": "C[Si](C#CC1=CC=CC=C1)(C)C#CC2=CC=CC=C2",
  "Dimethyldiphenylsilane": "C[Si](C1=CC=CC=C1)(C)C2=CC=CC=C2",
  "Dimethyldivinylsilane": "C=C[Si](C)(C)C=C",
  "Dimethylphenylsilane": "C[SiH](C)C1=CC=CC=C1",
  "Dimethyltin Dichloride": "C[Sn](Cl)(Cl)C",
  "Dimethyltin Oxide": "C[Sn](C)=O",
  "Di-n-octyltin Oxide": "CCCCCCCC[Sn](CCCCCCCC)=O",
  "Diphenylbis(phenylethynyl)silane": "C1(C#C[Si](C2=CC=CC=C2)(C3=CC=CC=C3)C#CC4=CC=CC=C4)=CC=CC=C1",
  "Diphenyldichlorogermane": "Cl[Ge](C1=CC=CC=C1)(C2=CC=CC=C2)Cl",
  "Diphenylsilane": "C1([SiH2]C2=CC=CC=C2)=CC=CC=C1",
  "Diphenylsilanediol": "O[Si](C1=CC=CC=C1)(C2=CC=CC=C2)O",
  "Di-sec-butylmagnesium - Lithium Chloride": "CCC([Mg]C(C)CC)C.[Li+].[Cl-]",
  "Di-tert-Butylsilane": "CC([SiH2]C(C)(C)C)(C)C",
  "Dodecamethylcyclohexasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Dodecamethylpentasiloxane": "C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C",
  "Dodecyltriethoxysilane": "CCO[Si](OCC)(CCCCCCCCCCCC)OCC",
  "Dodecyltrimethoxysilane": "CO[Si](OC)(CCCCCCCCCCCC)OC",
  "Ethoxytriethylsilane": "CC[Si](CC)(OCC)CC",
  "Ethylenedithiobis(trimethylsilane)": "C[Si](C)(C)SCCS[Si](C)(C)C",
  "Ethylmagnesium Bromide": "Br[Mg]CC",
  "Ethylmagnesium Chloride": "Cl[Mg]CC",
  "Heptylmagnesium Bromide": "Br[Mg]CCCCCCC",
  "Hexachlorodisilane": "Cl[Si](Cl)(Cl)[Si](Cl)(Cl)Cl",
  "Hexadecamethylcyclooctasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Hexadecyltrimethoxysilane": "CO[Si](OC)(CCCCCCCCCCCCCCCC)OC",
  "Hexamethylcyclotrisiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Hexamethyldisilane": "C[Si](C)(C)[Si](C)(C)C",
  "Hexamethyldisiloxane": "C[Si](C)(C)O[Si](C)(C)C",
  "Hexamethylditin": "C[Sn](C)(C)[Sn](C)(C)C",
  "Hexaphenylcyclotrisiloxane": "C1([Si]2(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)O[Si](C6=CC=CC=C6)(C7=CC=CC=C7)O2)=CC=CC=C1",
  "Hexaphenyldisilane": "C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)[Si](C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1",
  "Hexaphenyldisiloxane": "C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)C6=CC=CC=C6)=CC=CC=C1",
  "Hexylmagnesium Bromide": "Br[Mg]CCCCCC",
  "Hexyltriethoxysilane": "CCO[Si](OCC)(CCCCCC)OCC",
  "Hexyltrimethoxysilane": "CO[Si](OC)(CCCCCC)OC",
  "Isobutylmagnesium Bromide": "Br[Mg]CC(C)C",
  "Isopropenyloxytris(trimethylsilyl)silane": "C[Si]([Si]([Si](C)(C)C)(OC(C)=C)[Si](C)(C)C)(C)C",
  "Isopropylmagnesium Bromide": "Br[Mg]C(C)C",
  "Isopropylmagnesium Chloride - Lithium Chloride": "Cl[Mg]C(C)C.[Li+].[Cl-]",
  "Isopropylmagnesium Chloride": "Cl[Mg]C(C)C",
  "Lithium Acetylide Ethylenediamine Complex": "NCCN.[C-]#[C-].[Li+].[Li+]",
  "Methallyltrimethylsilane": "C[Si](C)(CC(C)=C)C",
  "Methoxydimethyl(phenyl)silane": "C[Si](C)(OC)C1=CC=CC=C1",
  "Methoxymethyltrimethylsilane": "C[Si](C)(COC)C",
  "Methoxytrimethylsilane": "C[Si](C)(OC)C",
  "Methyl 3-(Trimethylsilyloxy)crotonate (cis- and trans- mixture)": "C/C(O[Si](C)(C)C)=C/C(OC)=O",
  "Methyldiphenylsilane": "C[SiH](C1=CC=CC=C1)C2=CC=CC=C2",
  "Methylmagnesium Bromide": "Br[Mg]C",
  "Methylmagnesium Iodide": "I[Mg]C",
  "Monobutyltin Oxide": "CCCC[SnH]=O",
  "m-Tolylmagnesium Bromide ": "Br[Mg]C1=CC=CC(C)=C1",
  "N-[2-(Trimethylsilyl)ethoxycarbonyloxy]succinimide": "O=C(N1OC(OCC[Si](C)(C)C)=O)CCC1=O",
  "n-Octyl p-Toluenesulfonate": "CC1=CC=C(S(=O)(OCCCCCCCC)=O)C=C1",
  "n-Octylmagnesium Bromide": "Br[Mg]CCCCCCCC",
  "N-Trimethylsilylimidazole": "C[Si](N1C=NC=C1)(C)C",
  "Octadecamethylcyclononasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Octadecyltriethoxysilane": "CCO[Si](OCC)(CCCCCCCCCCCCCCCCCC)OCC",
  "Octadecyltrimethoxysilane": "CO[Si](OC)(CCCCCCCCCCCCCCCCCC)OC",
  "Octakis(dimethylsilyloxy)octasilsesquioxane": "C[SiH](C)O[Si]1(O[Si]2(O[SiH](C)C)O[Si](O3)(O[SiH](C)C)O[Si](O4)(O[SiH](C)C)O[Si]5(O[SiH](C)C)O2)O[Si]3(O[SiH](C)C)O[Si]4(O[SiH](C)C)O[Si](O5)(O[SiH](C)C)O1",
  "Octamethylcyclotetrasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Octamethyltrisiloxane": "C[Si](C)(C)O[Si](C)(C)O[Si](C)(C)C",
  "Octaphenylcyclotetrasiloxane": "C1([Si]2(C3=CC=CC=C3)O[Si](C4=CC=CC=C4)(C5=CC=CC=C5)O[Si](C6=CC=CC=C6)(C7=CC=CC=C7)O[Si](C8=CC=CC=C8)(C9=CC=CC=C9)O2)=CC=CC=C1",
  "Octaphenyloctasilsesquioxane": "C1([Si]2(O3)O[Si](O[Si](O4)(C5=CC=CC=C5)O[Si](O6)(C7=CC=CC=C7)O8)(C9=CC=CC=C9)O[Si]8(C%10=CC=CC=C%10)O[Si]3(C%11=CC=CC=C%11)O[Si]6(C%12=CC=CC=C%12)O[Si]4(C%13=CC=CC=C%13)O2)=CC=CC=C1",
  "o-Tolylmagnesium Bromide": "Br[Mg]C1=CC=CC=C1C",
  "Pentadecylmagnesium Bromide": "Br[Mg]CCCCCCCCCCCCCCC",
  "Pentylmagnesium Bromide": "Br[Mg]CCCCC",
  "Pentyltriethoxysilane": "CCO[Si](OCC)(CCCCC)OCC",
  "Phenylgermanium Trichloride": "Cl[Ge](Cl)(Cl)C1=CC=CC=C1",
  "Phenylmagnesium Bromide ": "Br[Mg]C1=CC=CC=C1",
  "Phenylmagnesium Chloride": "Cl[Mg]C1=CC=CC=C1",
  "Phenylmagnesium Iodide ": "I[Mg]C1=CC=CC=C1",
  "Phenylsilane": "[SiH3]C1=CC=CC=C1",
  "Phenyltin Trichloride": "Cl[Sn](Cl)(Cl)C1=CC=CC=C1",
  "Propylmagnesium Bromide": "Br[Mg]CCC",
  "p-Tolylmagnesium Bromide ": "Br[Mg]C1=CC=C(C)C=C1",
  "sec-Butylmagnesium Bromide ": "Br[Mg]C(C)CC",
  "sec-Butylmagnesium Chloride - Lithium Chloride": "Cl[Mg]C(C)CC.[Li+].[Cl-]",
  "tert-Butyldimethylsilane": "C[SiH](C(C)(C)C)C",
  "tert-Butyldimethylsilanol": "O[Si](C)(C(C)(C)C)C",
  "tert-Butyldiphenylsilane": "CC([SiH](C1=CC=CC=C1)C2=CC=CC=C2)(C)C",
  "tert-Butylmagnesium Chloride": "Cl[Mg]C(C)(C)C",
  "tert-Butylmethoxyphenylsilyl Bromide": "CO[Si](C(C)(C)C)(Br)C1=CC=CC=C1",
  "Tetraallyltin": "C=CC[Sn](CC=C)(CC=C)CC=C",
  "Tetrabutyl Orthosilicate": "CCCCO[Si](OCCCC)(OCCCC)OCCCC",
  "Tetrabutyltin": "CCCC[Sn](CCCC)(CCCC)CCCC",
  "Tetradecamethylcycloheptasiloxane": "C[Si]1(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O[Si](C)(C)O1",
  "Tetraethyl Orthosilicate": "CCO[Si](OCC)(OCC)OCC",
  "Tetraethylgermane": "CC[Ge](CC)(CC)CC",
  "Tetraethylsilane": "CC[Si](CC)(CC)CC",
  "Tetraisopropyl Orthosilicate": "CC(O[Si](OC(C)C)(OC(C)C)OC(C)C)C",
  "Tetrakis(dimethylsilyl)silane": "C[SiH]([Si]([SiH](C)C)([SiH](C)C)[SiH](C)C)C",
  "Tetrakis(dimethylsilyloxy)silane": "C[SiH](O[Si](O[SiH](C)C)(O[SiH](C)C)O[SiH](C)C)C",
  "Tetrakis(trimethylsilyl)silane": "C[Si]([Si]([Si](C)(C)C)([Si](C)(C)C)[Si](C)(C)C)(C)C",
  "Tetrakis[dimethyl(vinyl)silyl] Orthosilicate": "C=C[Si](O[Si](O[Si](C)(C)C=C)(O[Si](C)(C)C=C)O[Si](C)(C)C=C)(C)C",
  "Tetramethylgermane": "C[Ge](C)(C)C",
  "Tetramethylsilane": "C[Si](C)(C)C",
  "Tetraphenylgermane": "C1([Ge](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Tetraphenylsilane": "C1([Si](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Tetraphenyltin": "C1([Sn](C2=CC=CC=C2)(C3=CC=CC=C3)C4=CC=CC=C4)=CC=CC=C1",
  "Tetrapropyl Orthosilicate": "CCCO[Si](OCCC)(OCCC)OCCC",
  "TMS-Imidazole (=N-Trimethylsilylimidazole)": "C[Si](N1C=NC=C1)(C)C",
  "Tri(cyclohexa-2,5-dien-1-yl)silane": "[SiH](C1C=CCC=C1)(C2C=CCC=C2)C3C=CCC=C3",
  "Triacetoxyethylsilane": "CC[Si](OC(C)=O)(OC(C)=O)OC(C)=O",
  "Triallyl(methyl)silane": "C[Si](CC=C)(CC=C)CC=C",
  "Triallyl(phenyl)silane": "C=CC[Si](CC=C)(CC=C)C1=CC=CC=C1",
  "Tribenzylsilane": "[SiH](CC1=CC=CC=C1)(CC2=CC=CC=C2)CC3=CC=CC=C3",
  "Tributylchlorosilane": "Cl[Si](CCCC)(CCCC)CCCC",
  "Tributylsilane": "CCCC[SiH](CCCC)CCCC",
  "Trichloro(3,3,3-trifluoropropyl)silane": "FC(F)(F)CC[Si](Cl)(Cl)Cl",
  "Trichloro-2-cyanoethylsilane": "N#CCC[Si](Cl)(Cl)Cl",
  "Trichlorocyclopentylsilane": "Cl[Si](Cl)(Cl)C1CCCC1",
  "Triethoxy(3-glycidyloxypropyl)silane": "CCO[Si](OCC)(OCC)CCCOCC1CO1",
  "Triethoxy(pentafluorophenyl)silane": "FC1=C(F)C(F)=C(F)C(F)=C1[Si](OCC)(OCC)OCC",
  "Triethoxy(propyl)silane": "CCC[Si](OCC)(OCC)OCC",
  "Triethoxy[2-(7-oxabicyclo[4.1.0]heptan-3-yl)ethyl]silane": "CCO[Si](OCC)(OCC)CCC1CC2OC2CC1",
  "Triethoxy-1H,1H,2H,2H-tridecafluoro-n-octylsilane": "F[SiH2]C(F)(F)CC(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(OCC)(OCC)OCC",
  "Triethoxyethylsilane": "CC[Si](OCC)(OCC)OCC",
  "Triethoxyfluorosilane": "F[Si](OCC)(OCC)OCC",
  "Triethoxymethylsilane": "C[Si](OCC)(OCC)OCC",
  "Triethoxy-n-octylsilane": "CCCCCCCC[Si](OCC)(OCC)OCC",
  "Triethoxyphenylsilane": "CCO[Si](OCC)(OCC)C1=CC=CC=C1",
  "Triethoxyvinylsilane": "C=C[Si](OCC)(OCC)OCC",
  "Triethylchlorogermane": "Cl[Ge](CC)(CC)CC",
  "Triethylsilanol": "O[Si](CC)(CC)CC",
  "Triethylsilylacetylene": "C#C[Si](CC)(CC)CC",
  "Triethylvinylsilane": "C=C[Si](CC)(CC)CC",
  "Trihexylsilane": "CCCCCC[SiH](CCCCCC)CCCCCC",
  "Triisopropyl[(trimethylsilyl)ethynyl]silane": "C[Si](C#C[Si](C(C)C)(C(C)C)C(C)C)(C)C",
  "Triisopropylsilane": "CC([SiH](C(C)C)C(C)C)C",
  "Triisopropylsilylacetylene": "C#C[Si](C(C)C)(C(C)C)C(C)C",
  "Trimethoxy(1H,1H,2H,2H-tridecafluoro-n-octyl)silane": "FC(C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F)(F)CC[Si](OC)(OC)OC",
  "Trimethoxy(2-phenylethyl)silane": "CO[Si](OC)(OC)CCC1=CC=CC=C1",
  "Trimethoxy(3,3,3-trifluoropropyl)silane": "FC(F)(F)CC[Si](OC)(OC)OC",
  "Trimethoxy(4-methoxyphenyl)silane": "COC1=CC=C([Si](OC)(OC)OC)C=C1",
  "Trimethoxy(methyl)silane": "C[Si](OC)(OC)OC",
  "Trimethoxy(phenylethyl)silane": "CO[Si](OC)(OC)CCC1=CC=CC=C1",
  "Trimethoxy(propyl)silane": "CCC[Si](OC)(OC)OC",
  "Trimethoxy[3-(methylamino)propyl]silane": "CNCCC[Si](OC)(OC)OC",
  "Trimethoxy[3-(phenylamino)propyl]silane": "CO[Si](OC)(OC)CCCNC1=CC=CC=C1",
  "Trimethoxy-n-octylsilane": "CCCCCCCC[Si](OC)(OC)OC",
  "Trimethoxyphenylsilane": "CO[Si](OC)(OC)C1=CC=CC=C1",
  "Trimethyl(2-pyridyl)tin": "C[Sn](C)(C)C1=NC=CC=C1",
  "Trimethyl(4-pyridyl)tin": "C[Sn](C)(C)C1=CC=NC=C1",
  "Trimethyl(heptafluoropropyl)silane": "FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)F",
  "Trimethyl(nonafluorobutyl)silane": "FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)C(F)(F)F",
  "Trimethyl(pentafluoroethyl)silane": "FC([Si](C)(C)C)(F)C(F)(F)F",
  "Trimethyl(pentafluorophenyl)silane": "FC1=C(F)C(F)=C(F)C(F)=C1[Si](C)(C)C",
  "Trimethyl(tridecafluorohexyl)silane": "FC([Si](C)(C)C)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)C(F)(F)F",
  "Trimethyl[3-(trimethoxysilyl)propyl]ammonium Chloride": "CO[Si](CCC[N+](C)(C)C)(OC)OC.[Cl-]",
  "Trimethylchlorogermane": "Cl[Ge](C)(C)C",
  "Trimethylethoxysilane": "CCO[Si](C)(C)C",
  "Trimethylphenylsilane": "C[Si](C)(C)C1=CC=CC=C1",
  "Trimethylsilyl Acetate": "CC(O[Si](C)(C)C)=O",
  "Trimethylsilyl Propionate": "CCC(O[Si](C)(C)C)=O",
  "Trimethylsilyl Trifluoroacetate": "O=C(O[Si](C)(C)C)C(F)(F)F",
  "Trimethylsilylacetylene": "C#C[Si](C)(C)C",
  "Trimethylsilylcyclopentadiene": "C[Si](C1=CC=CC1)(C)C",
  "Trimethylsilylketene Ethyl Trimethylsilyl Acetal": "C[Si](/C=C(OCC)/O[Si](C)(C)C)(C)C",
  "Trimethylsilylmethylmagnesium Chloride": "Cl[Mg]C[Si](C)(C)C",
  "Trimethyltin Chloride": "C[Sn](C)(Cl)C",
  "Triphenylchlorogermane": "Cl[Ge](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylsilane": "[SiH](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylsilanol": "O[Si](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Triphenylvinylsilane": "C=C[Si](C1=CC=CC=C1)(C2=CC=CC=C2)C3=CC=CC=C3",
  "Tris(trimethylsilyloxy)silane": "C[Si](O[SiH](O[Si](C)(C)C)O[Si](C)(C)C)(C)C",
  "Vinylmagnesium Bromide": "Br[Mg]C=C",
  "Vinyltrimethoxysilane": "CO[Si](OC)(C=C)OC",
  "Vinyltrimethylsilane": "C[Si](C)(C=C)C",
  "Vinyltris(2-methoxyethoxy)silane": "COCCO[Si](OCCOC)(C=C)OCCOC",
}