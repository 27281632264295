export const chiralAuxiliaries = {
  "(-)-10,2-Camphorsultam": "CC1(C)C2(C3)CCC1CC2NS3(=O)=O",
  "(-)-2,2'-Methylenebis[(3aS,8aR)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": "[H][C@@]12N=C(CC3=N[C@]4([H])[C@](CC5=C4C=CC=C5)([H])O3)O[C@]1([H])CC6=C2C=CC=C6",
  "(-)-4,5-Bis[hydroxy(diphenyl)methyl]-2,2-dimethyl-1,3-dioxolane": "CC1(C)OC(C(C2=CC=CC=C2)(O)C3=CC=CC=C3)C(C(C4=CC=CC=C4)(O)C5=CC=CC=C5)O1",
  "(-)-B-Chlorodiisopinocampheylborane": "CC1(C)C2CC(B(Cl)C3CC4CC(C3C)C4(C)C)C(C)C1C2",
  "(-)-Isopinocampheylborane TMEDA Complex": "B[C@H]1[C@H](C)C2C(C(C1)C2)(C)C.B[C@H]3[C@H](C)C4C(C(C3)C4)(C)C.CN(C)CCN(C)C",
  "(-)-Sparteine": "[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3",
  "(+)-10,2-Camphorsultam": "CC1(C)C2(C3)CCC1CC2NS3(=O)=O",
  "(+)-2,2'-Methylenebis[(3aR,8aS)-3a,8a-dihydro-8H-indeno[1,2-d]oxazole]": "[H][C@]12N=C(CC3=N[C@@]4([H])[C@@](CC5=C4C=CC=C5)([H])O3)O[C@@]1([H])CC6=C2C=CC=C6",
  "(+)-4,5-Bis[hydroxy(diphenyl)methyl]-2,2-dimethyl-1,3-dioxolane": "CC1(C)OC(C(C2=CC=CC=C2)(O)C3=CC=CC=C3)C(C(C4=CC=CC=C4)(O)C5=CC=CC=C5)O1",
  "(+)-B-Chlorodiisopinocampheylborane": "CN(C)CCN(C)C.CC1(C)C2CC(BC3CC4CC(C3C)C4(C)C)C(C)C1C2",
  "(+)-Sparteine": "[H][C@]12CCCCN1C[C@H]3[C@]4([H])CCCCN4C[C@@H]2C3",
  "(11bS)-2,6-Bis[bis[3,5-bis(trifluoromethyl)phenyl]hydroxymethyl]-3,5-dihydrospiro[4H-dinaphth[2,1-c:1',2'-e]azepine-4,4'-morpholinium] Bromide": "OC(C1=C2C(C3=C4C=CC=CC4=CC(C(C5=CC(C(F)(F)F)=CC(C(F)(F)F)=C5)(C6=CC(C(F)(F)F)=CC(C(F)(F)F)=C6)O)=C3C(Br)[N]7(CCOCC7)C2)=C8C=CC=CC8=C1)(C9=CC(C(F)(F)F)=CC(C(F)(F)F)=C9)C%10=CC(C(F)(F)F)=CC(C(F)(F)F)=C%10",
  "(11R,12R)-9,10-Dihydro-9,10-ethanoanthracene-11,12-diamine": "N[C@H]1[C@H](N)C2C3=C(C=CC=C3)C1C4=CC=CC=C24",
  "(11S,12S)-9,10-Dihydro-9,10-ethanoanthracene-11,12-diamine": "N[C@@H]1[C@@H](N)C2C3=C(C=CC=C3)C1C4=CC=CC=C24",
  "(1R,2R)-1,2-Bis(2-hydroxyphenyl)ethylenediamine": "N[C@H](C1=CC=CC=C1O)[C@@H](C2=CC=CC=C2O)N",
  "(1R,2R)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": "CC1=C(C(C)=CC(C)=C1)[C@@H](/N=C/C=C(C(C)=O)\\C[O-])[C@@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]",
  "(1R,2R)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": "[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@@H]([C@@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]",
  "(1R,2R)-N,N'-Dihydroxy-N,N'-bis(diphenylacetyl)cyclohexane-1,2-diamine": "O=C(N(O)[C@H]1[C@H](N(O)C(C(C2=CC=CC=C2)C3=CC=CC=C3)=O)CCCC1)C(C4=CC=CC=C4)C5=CC=CC=C5",
  "(1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenyl-1-propanol": "C[C@H](N(CC1=CC=CC=C1)S(=O)(C(C(C)=CC(C)=C2)=C2C)=O)[C@@H](C3=CC=CC=C3)O",
  "(1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Propionate [Reagent for anti-selective asymmetric aldol reaction]": "CCC(O[C@H](C1=CC=CC=C1)[C@@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O",
  "(1R,3S,5R,7R,8aS)-7-Ethylhexahydro-1-(6-hydroxy-4-quinolinyl)-3,7-methano-1H-pyrrolo[2,1-c][1,4]oxazine": "OC1=CC=C2N=CC=C([C@H]3O[C@](C4)([H])C[N@@]5[C@@]3([H])C[C@@]4(CC)C5)C2=C1",
  "(1R,4R,5R)-4,7,7-Trimethyl-6-thiabicyclo[3.2.1]octane": "CC1(C)S[C@@]2([H])[C@H](C)CC[C@]1([H])C2",
  "(1S)-3-(1,10-Phenanthrolin-2-yl)-2'-phenyl-[1,1'-binaphthalen]-2-ol": "OC1=C(C2=NC3=C4N=CC=CC4=CC=C3C=C2)C=C5C=CC=CC5=C1C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8",
  "(1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenyl-1-propanol": "C[C@@H](N(CC1=CC=CC=C1)S(=O)(C(C(C)=CC(C)=C2)=C2C)=O)[C@H](C3=CC=CC=C3)O",
  "(1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Propionate": "CCC(O[C@@H](C1=CC=CC=C1)[C@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O",
  "(1S,2S)-N,N'-Bis(2-acetyl-3-oxo-2-butenylidene)-1,2-dimesitylethylenediaminato Cobalt(II)": "CC1=C(C(C)=CC(C)=C1)[C@H](/N=C/C=C(C(C)=O)\\C[O-])[C@H](C2=C(C)C=C(C)C=C2C)/N=C/C=C(C(C)=O)\\C[O-].[Co+2]",
  "(1S,2S)-N,N'-Bis[(R)-2-hydroxy-2'-phenyl-1,1'-binaphthyl-3-ylmethylene]-1,2-diphenylethylenediaminato Manganese(III) Acetate": "CC([O-])=O.OC1=C(C([Mn+]2)=N[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N=C2C5=C(O)C(C6=C7C=CC=CC7=CC=C6C8=CC=CC=C8)=C9C=CC=CC9=C5)C=C%10C=CC=CC%10=C1C%11=C%12C=CC=CC%12=CC=C%11C%13=CC=CC=C%13",
  "(1S,2S)-N,N'-Bis[3-oxo-2-(2,4,6-trimethylbenzoyl)butylidene]-1,2-diphenylethylenediaminato Cobalt(II)": "[O-]/C(C)=C(C(C1=C(C)C=C(C)C=C1C)=O)\\C=N\\[C@H]([C@H](C2=CC=CC=C2)/N=C/C(C(C3=C(C)C=C(C)C=C3C)=O)=C(C)\\[O-])C4=CC=CC=C4.[Co+2]",
  "(1S,2S)-N,N'-Dihydroxy-N,N'-bis(diphenylacetyl)cyclohexane-1,2-diamine": "O=C(N(O)[C@@H]1[C@@H](N(O)C(C(C2=CC=CC=C2)C3=CC=CC=C3)=O)CCCC1)C(C4=CC=CC=C4)C5=CC=CC=C5",
  "(1S,4S,5S)-4,7,7-Trimethyl-6-thiabicyclo[3.2.1]octane": "CC1(C)S[C@]2([H])[C@@H](C)CC[C@@]1([H])C2",
  "(2R,3R)-(-)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@H]([C@@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(2R,3R)-(-)-2,3-Bis(diphenylphosphino)bicyclo[2.2.1]hept-5-ene": "C1(C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)[C@H](P(C5=CC=CC=C5)C6=CC=CC=C6)C2C=C1",
  "(2R,8aS)-(+)-(Camphorylsulfonyl)oxaziridine": "O=S(N1OC1)(CC23C(CC(C(C)3C)CC2)=O)=O",
  "(2S)-N-[(1S)-1-(Hydroxydiphenylmethyl)-3-methylbutyl]-2-pyrrolidinecarboxamide": "O=C([C@H]1NCCC1)N[C@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CC(C)C",
  "(2S,3S)-(-)-Bis(diphenylphosphino)butane": "C[C@H](P(C1=CC=CC=C1)C2=CC=CC=C2)[C@@H](P(C3=CC=CC=C3)C4=CC=CC=C4)C",
  "(2S,3S)-(+)-1,4-Bis(diphenylphosphino)-2,3-O-isopropylidene-2,3-butanediol": "CC1(O[C@@H]([C@H](O1)CP(C2=CC=CC=C2)C3=CC=CC=C3)CP(C4=CC=CC=C4)C5=CC=CC=C5)C",
  "(2S,5S)-(-)-2-tert-Butyl-3-methyl-5-benzyl-4-imidazolidinone": "O=C1N(C)[C@@H](C(C)(C)C)N[C@H]1CC2=CC=CC=C2",
  "(2S,8aR)-(-)-(Camphorylsulfonyl)oxaziridine": "O=S(N1OC1)(CC23C(CC(C(C)3C)CC2)=O)=O",
  "(4R)-(+)-4-Isopropyl-5,5-diphenyl-2-oxazolidinone": "O=C1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@@H](C(C)C)N1",
  "(4R,5R)-1,3-Dimethyl-4,5-diphenyl-2-[(S)-1-benzyl-2-hydroxyethylimino]imidazolidine": "OC[C@H](CC1=CC=CC=C1)N=C2N(C)[C@H](C3=CC=CC=C3)[C@@H](C4=CC=CC=C4)N2C",
  "(4S)-(-)-4-Isopropyl-5,5-diphenyl-2-oxazolidinone": "O=C1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@H](C(C)C)N1",
  "(4S,5S)-1,3-Dimethyl-4,5-diphenyl-2-[(R)-1-benzyl-2-hydroxyethylimino]imidazolidine": "OC[C@@H](CC1=CC=CC=C1)N=C2N(C)[C@@H](C3=CC=CC=C3)[C@H](C4=CC=CC=C4)N2C",
  "(R)-(-)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a;3,4-a']dinaphthalen-4-yl)dimethylamine": "CN(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)C",
  "(R)-(-)-1-(2-Pyrrolidinylmethyl)pyrrolidine": "N1(C[C@@H]2NCCC2)CCCC1",
  "(R)-(-)-1,1'-Binaphthyl-2,2'-diyl Bis(trifluoromethanesulfonate)": "O=S(C(F)(F)F)(OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OS(C(F)(F)F)(=O)=O)=O",
  "(R)-(-)-1,1'-Binaphthyl-2,2'-diyl Hydrogen Phosphate": "O=P1(OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)O",
  "(R)-(-)-2-[Hydroxy(diphenyl)methyl]-1-methylpyrrolidine": "CN1[C@@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CCC1",
  "(R)-(-)-4-Benzyl-3-propionyl-2-oxazolidinone": "O=C1OC[C@@H](CC2=CC=CC=C2)N1C(CC)=O",
  "(R)-(-)-4-Isopropyl-3-propionyl-2-oxazolidinone": "O=C1OC[C@@H](C(C)C)N1C(CC)=O",
  "(R)-(-)-4-Phenyl-2-oxazolidinone": "O=C1OC[C@@H](C2=CC=CC=C2)N1",
  "(R)-(-)-6,6'-Dibromo-1,1'-bi-2-naphthol": "OC1=CC=C2C=C(Br)C=CC2=C1C3=C4C=CC(Br)=CC4=CC=C3O",
  "(R)-(-)-p-Toluenesulfinamide": "CC1=CC=C([S@](N)=O)C=C1",
  "(R)-(+)-1,1'-Bi-2-naphthol": "OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3O",
  "(R)-(+)-1,1'-Binaphthyl-2,2'-diamine": "NC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N",
  "(R)-(+)-2-Amino-2'-hydroxy-1,1'-binaphthyl": "OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N",
  "(R)-(+)-2-Diphenylphosphino-2'-methoxy-1,1'-binaphthyl": "COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3P(C5=CC=CC=C5)C6=CC=CC=C6",
  "(R)-(+)-3,3'-Dibromo-5,5',6,6',7,7',8,8'-octahydro-1,1'-bi-2-naphthol": "OC1=C(Br)C=C2CCCCC2=C1C3=C4CCCCC4=CC(Br)=C3O",
  "(R)-(+)-5,5',6,6',7,7',8,8'-Octahydro-1,1'-bi-2-naphthol": "OC1=CC=C2CCCCC2=C1C3=C4CCCCC4=CC=C3O",
  "(R)-(+)-BINAP": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=C(C(C=CC=C4)=C4C=C3)C(C(C=CC=C5)=C5C=C6)=C6P(C7=CC=CC=C7)C8=CC=CC=C8",
  "(R)-(+)-Indoline-2-carboxylic Acid": "O=C([C@@H]1NC2=C(C=CC=C2)C1)O",
  "(R)-(+)-N,N-Dimethyl-1-ferrocenylethylamine": "C[C@H]([C]1[CH][CH][CH][CH]1)N(C)C.[Fe].[CH]2[CH][CH][CH][CH]2",
  "(R)-(+)-tert-Butylsulfinamide": "CC(C)([S@](N)=O)C",
  "(R)-(+)-α,α-Diphenyl-2-pyrrolidinemethanol": "OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3NCCC3",
  "(R)-(+)-α,α-Diphenyl-2-pyrrolidinemethanol Trimethylsilyl Ether": "C[Si](OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3NCCC3)(C)C",
  "(R)-2-(Methoxymethyl)pyrrolidine": "COC[C@@H]1NCCC1",
  "(R)-2,2'-Dimethoxy-1,1'-binaphthyl": "COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OC",
  "(R)-3,3'-Bis[[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]-1,1'-bi-2-naphthol": "OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C6C=CC=CC6=C1C7=C8C=CC=CC8=CC(/C=N/[C@H](C9=CC=CC=C9)[C@H](N%10CC%11=C(C=CC=C%11)C%10)C%12=CC=CC=C%12)=C7O",
  "(R)-3,3'-Dibromo-1,1'-bi-2-naphthol": "OC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3O",
  "(R)-3,3'-Dibromo-2,2'-dimethoxy-1,1'-binaphthyl": "COC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3OC",
  "(R)-4-Benzyl-2-oxazolidinone": "O=C1OC[C@@H](CC2=CC=CC=C2)N1",
  "(R)-4-Isopropyl-2-oxazolidinone": "O=C1OC[C@@H](C(C)C)N1",
  "(R)-4-Phenylthiazolidine-2-thione": "S=C1SC[C@@H](C2=CC=CC=C2)N1",
  "(R)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": "CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@]4([H])N1CCC4",
  "(R)-N-(2-Benzoylphenyl)-1-benzylpyrrolidine-2-carboxamide": "O=C([C@@H]1N(CC2=CC=CC=C2)CCC1)NC3=CC=CC=C3C(C4=CC=CC=C4)=O",
  "(R)-N,N-Dimethyl-1-[(S)-1',2-bis(diphenylphosphino)ferrocenyl]ethylamine": "C[C@@H](N(C)C)c1c(P(C2=CC=CC=C2)C3=CC=CC=C3)ccc1.[Del][Fe][Del].c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "(R)-N,N-Dimethyl-1-[(S)-2-(diphenylphosphino)ferrocenyl]ethylamine": "CN(C)[C@H](c1cccc1)CP(C2=CC=CC=C2)C3=CC=CC=C3.[Del][Fe][Del].c4cccc4",
  "(R,R)-(-)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@@](C1=NC2=CC=CC=C2N=C1[P@@](C(C)(C)C)C)C(C)(C)C",
  "(R,R)-(-)-N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,2-cyclohexanediamine": "OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=N/[C@H]2[C@H](/N=C/C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3O)CCCC2",
  "(R,R)-(-)-N,N'-Bis(3,5-di-tert-butylsalicylidene)-1,2-cyclohexanediaminomanganese(III) Chloride": "OC1=C(C(C)(C)C)C=C(C(C)(C)C)C=C1/C=[N]([Mn+]/[N]2=C\\C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3O)\\[C@@]4([H])[C@@]2([H])CCCC4.[Cl-]",
  "(R,R)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@](CC[P@@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "(R,R'')-2,2''-Bis(diphenylphosphino)-1,1''-biferrocene": "[Del][Fe][Del].[Del][Fe][Del].P(C1=CC=CC=C1)(c2cccc2c3cccc3P(C4=CC=CC=C4)C5=CC=CC=C5)C6=CC=CC=C6.c7cccc7.c8cccc8",
  "(R,R)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": "CC(C1=N[C@H](C2=CC=CC=C2)CO1)(C3=N[C@H](C4=CC=CC=C4)CO3)C",
  "(R,R)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": "CC([C@H]1N=C(C2=CC=CC(C3=N[C@H](C(C)C)CO3)=N2)OC1)C",
  "(R,R)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": "C1(C2=N[C@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@H](C5=CC=CC=C5)CO4)=CC=C1",
  "(R,R)-2-Iodo-1,3-bis[1-(mesitylcarbamoyl)ethoxy]benzene": "C[C@H](C(NC1=C(C)C=C(C)C=C1C)=O)OC2=C(I)C(O[C@@H](C(NC3=C(C)C=C(C)C=C3C)=O)C)=CC=C2",
  "(R,R)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": "CC1=CC=C(S(=O)(N[C@H](C2=CC=CC=C2)[C@H](N)C3=CC=CC=C3)=O)C=C1",
  "(R,R,R)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a:3,4-a']dinaphthalen-4-yl)bis(1-phenylethyl)amine": "C[C@@H](N(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)[C@@H](C6=CC=CC=C6)C)C7=CC=CC=C7",
  "(S)-(-)-1,1'-Bi-2-naphthol": "OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3O",
  "(S)-(-)-1,1'-Binaphthyl-2,2'-diamine": "NC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N",
  "(S)-(-)-2-(Diphenylmethyl)pyrrolidine": "[C@H]1(C(C2=CC=CC=C2)C3=CC=CC=C3)NCCC1",
  "(S)-(-)-2-Amino-2'-hydroxy-1,1'-binaphthyl": "OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3N",
  "(S)-(-)-3-(tert-Butoxycarbonyl)-4-formyl-2,2-dimethyl-1,3-oxazolidine": "CC1(C)OC[C@@H](C=O)N1C(OC(C)(C)C)=O",
  "(S)-(-)-3,3'-Dibromo-5,5',6,6',7,7',8,8'-octahydro-1,1'-bi-2-naphthol": "OC1=C(Br)C=C2CCCCC2=C1C3=C4CCCCC4=CC(Br)=C3O",
  "(S)-(-)-3-tert-Butoxycarbonyl-4-methoxycarbonyl-2,2-dimethyl-1,3-oxazolidine": "CC1(C)OC[C@@H](C(OC)=O)N1C(OC(C)(C)C)=O",
  "(S)-(-)-5,5',6,6',7,7',8,8'-Octahydro-1,1'-bi-2-naphthol": "OC1=CC=C2CCCCC2=C1C3=C4CCCCC4=CC=C3O",
  "(S)-(-)-BINAP": "P(C1=CC=CC=C1)(C2=CC=CC=C2)C3=C(C(C=CC=C4)=C4C=C3)C(C(C=CC=C5)=C5C=C6)=C6P(C7=CC=CC=C7)C8=CC=CC=C8",
  "(S)-(-)-Indoline-2-carboxylic Acid": "O=C([C@H]1NC2=C(C=CC=C2)C1)O",
  "(S)-(-)-N,N-Dimethyl-1-ferrocenylethylamine": "CN(C)[C@H](c1cccc1)C.[Del][Fe][Del].c2cccc2",
  "(S)-(-)-tert-Butylsulfinamide": "CC(C)([S@@](N)=O)C",
  "(S)-(-)-α,α-Diphenyl-2-pyrrolidinemethanol": "OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@@H]3CCCN3",
  "(S)-(-)-α,α-Diphenyl-2-pyrrolidinemethanol Trimethylsilyl Ether": "C[Si](OC(C1=CC=CC=C1)(C2=CC=CC=C2)[C@H]3NCCC3)(C)C",
  "(S)-(+)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a;3,4-a']dinaphthalen-4-yl)dimethylamine": "CN(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)C",
  "(S)-(+)-1-(2-Pyrrolidinylmethyl)pyrrolidine": "N1(C[C@H]2NCCC2)CCCC1",
  "(S)-(+)-1,1'-Binaphthyl-2,2'-diyl Bis(trifluoromethanesulfonate)": "O=S(C(F)(F)F)(OC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OS(C(F)(F)F)(=O)=O)=O",
  "(S)-(+)-1,1'-Binaphthyl-2,2'-diyl Hydrogen Phosphate": "O=P1(OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)O",
  "(S)-(+)-2-(Anilinomethyl)pyrrolidine": "[C@H]1(CNC2=CC=CC=C2)NCCC1",
  "(S)-(+)-2-[Hydroxy(diphenyl)methyl]-1-methylpyrrolidine": "CN1[C@H](C(C2=CC=CC=C2)(O)C3=CC=CC=C3)CCC1",
  "(S)-(+)-4-Benzyl-3-propionyl-2-oxazolidinone": "O=C1OC[C@H](CC2=CC=CC=C2)N1C(CC)=O",
  "(S)-(+)-4-Isopropyl-3-propionyl-2-oxazolidinone": "O=C1OC[C@H](C(C)C)N1C(CC)=O",
  "(S)-(+)-4-Phenyl-2-oxazolidinone": "O=C1OC[C@H](C2=CC=CC=C2)N1",
  "(S)-(+)-6,6'-Dibromo-1,1'-bi-2-naphthol": "OC1=CC=C2C=C(Br)C=CC2=C1C3=C4C=CC(Br)=CC4=CC=C3O",
  "(S)-(+)-p-Toluenesulfinamide": "CC1=CC=C([S@@](N)=O)C=C1",
  "(S)-(4-Isopropyloxazolin-2-yl)ferrocene": "CC(C)[C@H]1COC(c2cccc2)=N1.[Del][Fe][Del].c3cccc3",
  "(S)-1-(Diphenylphosphino)-2-[(S)-4-isopropyloxazolin-2-yl]ferrocene": "CC(C)[C@@H]1N=C(c2c(P(C3=CC=CC=C3)C4=CC=CC=C4)ccc2)OC1.[Del][Fe][Del].c5cccc5",
  "(S)-2-(Methoxymethyl)pyrrolidine": "COC[C@H]1NCCC1",
  "(S)-2,2'-Dimethoxy-1,1'-binaphthyl": "COC1=CC=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC=C3OC",
  "(S)-2,4-Diphenyl-4,5-dihydrooxazole": "C1(C2=CC=CC=C2)=N[C@@H](C3=CC=CC=C3)CO1",
  "(S)-3,3'-Dibromo-1,1'-bi-2-naphthol": "OC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3O",
  "(S)-3,3'-Dibromo-2,2'-dimethoxy-1,1'-binaphthyl": "COC1=C(Br)C=C2C=CC=CC2=C1C3=C4C=CC=CC4=CC(Br)=C3OC",
  "(S)-4-(4-Aminobenzyl)-2-oxazolidinone": "O=C1OC[C@H](CC2=CC=C(N)C=C2)N1",
  "(S)-4-Benzyl-2-oxazolidinone": "O=C1OC[C@H](CC2=CC=CC=C2)N1",
  "(S)-4-Isopropyl-2-oxazolidinone": "O=C1OC[C@H](C(C)C)N1",
  "(S)-4-Isopropyl-3-(1-naphthylmethyl)-2,5,5-triphenyl-1,3,2-oxazaborolidine": "CC([C@@H]1N(CC2=C3C=CC=CC3=CC=C2)B(C4=CC=CC=C4)OC1(C5=CC=CC=C5)C6=CC=CC=C6)C",
  "(S)-4-Isopropylthiazolidine-2-thione": "S=C1SC[C@H](C(C)C)N1",
  "(S)-4-Phenylthiazolidine-2-thione": "S=C1SC[C@H](C2=CC=CC=C2)N1",
  "(S)-4-tert-Butyl-2-(2-pyridyl)oxazoline": "CC([C@@H]1N=C(C2=NC=CC=C2)OC1)(C)C",
  "(S)-5-(Pyrrolidin-2-yl)-1H-tetrazole": "C1([C@H]2NCCC2)=NN=NN1",
  "(S)-5,5-Diphenyl-2-methyl-3,4-propano-1,3,2-oxazaborolidine": "CB1OC(C2=CC=CC=C2)(C3=CC=CC=C3)[C@@]4([H])N1CCC4",
  "(S)-N,N-Dimethyl-1-[(R)-1',2-bis(diphenylphosphino)ferrocenyl]ethylamine": "C[C@@H](N(C)C)c1c(P(C2=CC=CC=C2)C3=CC=CC=C3)ccc1.[Del][Fe][Del].c4(P(C5=CC=CC=C5)C6=CC=CC=C6)cccc4",
  "(S,S)-(-)-2,2'-Isopropylidenebis(4-tert-butyl-2-oxazoline)": "CC(C1=N[C@@H](C(C)(C)C)CO1)(C2=N[C@@H](C(C)(C)C)CO2)C",
  "(S,S)-(+)-2,3-Bis(tert-butylmethylphosphino)quinoxaline": "C[P@](C1=NC2=CC=CC=C2N=C1[P@](C(C)(C)C)C)C(C)(C)C",
  "(S,S)-1,2-Bis[(2-methoxyphenyl)phenylphosphino]ethane": "COC1=CC=CC=C1[P@@](CC[P@](C2=CC=CC=C2OC)C3=CC=CC=C3)C4=CC=CC=C4",
  "(S,S)-1,2-Bis[(tert-butyl)methylphosphino]ethane Bis(borane)": "C[P@@](CC[P@@](C(C)(C)C)C)C(C)(C)C.B.B",
  "(S,S)-2,2'-Isopropylidenebis(4-phenyl-2-oxazoline)": "CC(C1=N[C@@H](C2=CC=CC=C2)CO1)(C3=N[C@@H](C4=CC=CC=C4)CO3)C",
  "(S,S)-2,6-Bis(4-isopropyl-2-oxazolin-2-yl)pyridine": "CC([C@@H]1N=C(C2=CC=CC(C3=N[C@@H](C(C)C)CO3)=N2)OC1)C",
  "(S,S)-2,6-Bis(4-phenyl-2-oxazolin-2-yl)pyridine": "C1(C2=N[C@@H](C3=CC=CC=C3)CO2)=NC(C4=N[C@@H](C5=CC=CC=C5)CO4)=CC=C1",
  "(S,S)-N-(2-Amino-1,2-diphenylethyl)-p-toluenesulfonamide": "CC1=CC=C(S(=O)(N[C@@H](C2=CC=CC=C2)[C@@H](N)C3=CC=CC=C3)=O)C=C1",
  "(S,S,S)-(3,5-Dioxa-4-phosphacyclohepta[2,1-a:3,4-a']dinaphthalen-4-yl)bis(1-phenylethyl)amine": "C[C@H](N(P1OC2=CC=C3C=CC=CC3=C2C4=C5C=CC=CC5=CC=C4O1)[C@H](C6=CC=CC=C6)C)C7=CC=CC=C7",
  "[(R)-2,2'-Bis(diphenylphosphino)-1,1'-binaphthyl]ruthenium(II) Dichloride": "C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8.[Ru+2].[Cl-].[Cl-]",
  "[(S)-2,2'-Bis(diphenylphosphino)-1,1'-binaphthyl]ruthenium(II) Dichloride": "C1(C2=C3C=CC=CC3=CC=C2P(C4=CC=CC=C4)C5=CC=CC=C5)=C6C=CC=CC6=CC=C1P(C7=CC=CC=C7)C8=CC=CC=C8.[Ru+2].[Cl-].[Cl-]",
  "2,4-Dibromo-6-[(E)-[[(1R,2R)-2-(isoindolin-2-yl)-1,2-diphenylethyl]imino]methyl]phenol": "OC1=C(/C=N/[C@H](C2=CC=CC=C2)[C@H](N3CC4=C(C=CC=C4)C3)C5=CC=CC=C5)C=C(Br)C=C1Br",
  "Acetic Acid (1R,2S)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Ester": "CC(O[C@H](C1=CC=CC=C1)[C@@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O",
  "Acetic Acid (1S,2R)-2-[N-Benzyl-N-(mesitylenesulfonyl)amino]-1-phenylpropyl Ester": "CC(O[C@@H](C1=CC=CC=C1)[C@H](N(CC2=CC=CC=C2)S(=O)(C(C(C)=CC(C)=C3)=C3C)=O)C)=O",
  "Bis(acetato)aqua[(S,S)-4,6-bis(4-isopropyl-2-oxazolin-2-yl)-m-xylene]rhodium": "CC1=CC(C)=C(C2=N[C@H](CO2)C(C)C)C=C1C3=N[C@@H](C(C)C)CO3.CC([O-])=O.[O-]C(C)=O.[Rh+2].O",
  "Bromo[[1,3-bis[(4S,5S)-1-benzoyl-4,5-diphenyl-2-imidazolin-2-yl]benzene]palladium(II)]": "O=C(N1[C@@H](C2=CC=CC=C2)[C@H](C3=CC=CC=C3)N=C1C4=CC(C5=N[C@@H](C6=CC=CC=C6)[C@H](C7=CC=CC=C7)N5C(C8=CC=CC=C8)=O)=CC=C4)C9=CC=CC=C9.[Pd+]Br",
  "Cinchonidine": "O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34",
  "Cinchonine": "O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=CC=C34",
  "Dibenzyl L-Tartrate": "O[C@H]([C@H](C(OCC1=CC=CC=C1)=O)O)C(OCC2=CC=CC=C2)=O",
  "Diethyl D-(-)-Tartrate": "O[C@@H]([C@@H](C(OCC)=O)O)C(OCC)=O",
  "Diethyl L-(+)-Tartrate": "O[C@H]([C@H](C(OCC)=O)O)C(OCC)=O",
  "Diisopropyl D-(-)-Tartrate": "O[C@@H]([C@@H](C(OC(C)C)=O)O)C(OC(C)C)=O",
  "Diisopropyl L-(+)-Tartrate": "O[C@H]([C@H](C(OC(C)C)=O)O)C(OC(C)C)=O",
  "Dimethyl D-(-)-Tartrate": "O[C@@H]([C@@H](C(OC)=O)O)C(OC)=O",
  "Dimethyl L-(+)-Tartrate": "O[C@H]([C@H](C(OC)=O)O)C(OC)=O",
  "Di-tert-butyl L-(+)-Tartrate": "O[C@H]([C@H](C(OC(C)(C)C)=O)O)C(OC(C)(C)C)=O",
  "D-Proline": "O=C(O)[C@@H]1NCCC1",
  "Ethyl (11bR)-4-Amino-2,6-bis(3,5-di-tert-butylphenyl)-4,5-dihydro-3H-cyclohepta[1,2-a:7,6-a']dinaphthalene-4-carboxylate": "O=C(C1(N)CC2=C(C3=CC(C(C)(C)C)=CC(C(C)(C)C)=C3)C=C4C=CC=CC4=C2C5=C6C=CC=CC6=CC(C7=CC(C(C)(C)C)=CC(C(C)(C)C)=C7)=C5C1)OCC",
  "Hydroquinidine Hydrochloride": "O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)CC)C3=CC=NC4=CC=C(OC)C=C34.[H]Cl",
  "L-Prolinanilide": "O=C(NC1=CC=CC=C1)[C@H]2NCCC2",
  "L-Proline": "O=C(O)[C@H]1NCCC1",
  "L-Thioproline": "O=C(O)[C@H]1NCCC1=S",
  "N-[(11bS)-4,5-Dihydro-3H-dinaphtho[2,1-c:1',2'-e]azepin-2-yl]trifluoromethanesulfonamide": "O=S(C(F)(F)F)(NC1=C2CNCC3=CC=C4C=CC=CC4=C3C2=C5C=CC=CC5=C1)=O",
  "N-Benzylcinchonidinium Chloride": "O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=CC=C35.[Cl-]",
  "N-Benzylcinchoninium Chloride": "O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=CC=C35.[Cl-]",
  "N-Benzylquinidinium Chloride": "O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=C(OC)C=C35.[Cl-]",
  "N-Benzylquininium Chloride": "O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=[N+](CC4=CC=CC=C4)C5=CC=C(OC)C=C35.[Cl-]",
  "O-TBDPS-D-Thr-N-Boc-L-tert-Leu-Diphenylphosphine": "O=C(OC(C)(C)C)N[C@@H](C(C)(C)C)C(N[C@H]([C@@H](O[Si](C1=CC=CC=C1)(C(C)(C)C)C2=CC=CC=C2)C)CP(C3=CC=CC=C3)C4=CC=CC=C4)=O",
  "Quinidine": "O[C@H]([C@@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34",
  "Quinine": "O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34",
  "Quinine Hydrochloride Dihydrate": "O[C@@H]([C@H]1C[C@H]2[C@H](C[N@@]1CC2)C=C)C3=CC=NC4=CC=C(OC)C=C34.[H]Cl.[H]O[H].[H]O[H]",
  "β-Isocupreidine": "[H][C@@](C1([H])[H])(C([H])([H])C2([H])[H])[C@@]3(C([H])([H])C([H])([H])[H])O[C@](C4=C(C([H])=C5O[H])C(C([H])=C5[H])=NC([H])=C4[H])([H])[C@]1([H])[N@@]2C3([H])[H]",
}