export const halogenationBrClI = {
  "1,2-Dibromo-1,1,2,2-tetrachloroethane": "ClC(Cl)(Br)C(Cl)(Br)Cl",
  "1,3-Dibromo-5,5-dimethylhydantoin": "O=C(N1Br)N(Br)C(C)(C)C1=O",
  "1,3-Diiodo-5,5-dimethylhydantoin": "O=C(N1I)N(I)C(C)(C)C1=O",
  "1,8-Diazabicyclo[5.4.0]-7-undecene Hydrogen Tribromide": "N12CCCCCC1=NCCC2.[H+].[Br-].[Br-].[Br-]",
  "1-Butyl-3-methylimidazolium Tribromide": "C[N+]1=CN(CCCC)C=C1.[Br-].[Br-].[Br-]",
  "1-Chloro-2-iodoethane": "ICCCl",
  "2,4,4,6-Tetrabromo-2,5-cyclohexadienone": "BrC1=CC(Br)(Br)C=C(Br)C1=O",
  "2-Bromo-2-cyano-N,N-dimethylacetamide": "O=C(N(C)C)C(Br)C#N",
  "4-Dimethylaminopyridinium Bromide Perbromide": "CN(C)C1=CC=[NH+]C=C1.Br[Br-]Br",
  "5,5-Dibromomeldrum's Acid (=5,5-Dibromo-2,2-dimethyl-4,6-dioxy-1,3-dioxane)": "O=C(OC(C)(C)O1)C(Br)(Br)C1=O",
  "Benzyltrimethylammonium Dichloroiodate": "C[N+](C)(CC1=CC=CC=C1)C.Cl[I-]Cl",
  "Benzyltrimethylammonium Tetrachloroiodate": "C[N+](C)(CC1=CC=CC=C1)C.Cl[I-](Cl)(Cl)Cl",
  "Benzyltrimethylammonium Tribromide": "C[N+](C)(CC1=CC=CC=C1)C.[Br-].[Br-].[Br-]",
  "Bis(2,4,6-trimethylpyridine)bromonium Hexafluorophosphate": "CC1=CC(C)=C([BrH+])C(C)=N1.CC2=CC(C)=CC(C)=N2.F[P-](F)(F)(F)(F)F",
  "Bis(2,4,6-trimethylpyridine)iodonium Hexafluorophosphate": "CC1=CC(C)=C([IH+])C(C)=N1.CC2=CC(C)=CC(C)=N2.F[P-](F)(F)(F)(F)F",
  "Bis(pyridine)iodonium Tetrafluoroborate": "[IH+]C1=NC=CC=C1.F[B-](F)(F)F.C2=NC=CC=C2",
  "Bromine - 1,4-Dioxane Complex": "BrBr.O1CCOCC1",
  "Bromodimethylsulfonium Bromide": "C[S+](Br)C.[Br-]",
  "Bromotrichloromethane": "ClC(Cl)(Br)Cl",
  "Carbon Tetrabromide": "BrC(Br)(Br)Br",
  "Carbon Tetraiodide": "IC(I)(I)I",
  "Chloramine B Hydrate": "O=S([N-]Cl)(C1=CC=CC=C1)=O.O.[Na+]",
  "Chloramine T Trihydrate": "CC1=CC=C(S(=O)([N-]Cl)=O)C=C1.[H]O[H].[H]O[H].[H]O[H].[Na+]",
  "Cyanuric Chloride": "ClC1=NC(Cl)=NC(Cl)=N1",
  "Dibromoisocyanuric Acid": "O=C(N(Br)C(N1)=O)N(Br)C1=O",
  "Dichloramine B": "O=S(N(Cl)Cl)(C1=CC=CC=C1)=O",
  "Dichloramine T": "O=S(N(Cl)Cl)(C(C=C1)=CC=C1C)=O",
  "Hydriodic Acid": "[H]I",
  "Iodine": "II",
  "Monosodium Bromoisocyanurate Hydrate": "BrC([N-]C(N1)=O)=NC1=O.[Na+]",
  "N,N-Dimethyl-N-(methylsulfanylmethylene)ammonium Iodide": "CSC=[N+](C)C.[I-]",
  "N-Bromoacetamide": "CC(NBr)=O",
  "N-Bromophthalimide": "O=C(N1Br)C2=CC=CC=C2C1=O",
  "N-Bromosaccharin": "O=C1N(Br)S(=O)(C2=C1C=CC=C2)=O",
  "N-Bromosuccinimide": "O=C(N1Br)CCC1=O",
  "N-Chlorophthalimide": "O=C(N1Cl)C2=CC=CC=C2C1=O",
  "N-Chlorosaccharin": "O=C1N(Cl)S(=O)(C2=C1C=CC=C2)=O",
  "N-Chlorosuccinimide": "O=C(N1Cl)CCC1=O",
  "N-Iodosaccharin": "O=C1N(I)S(=O)(C2=C1C=CC=C2)=O",
  "N-Iodosuccinimide": "O=C(N1I)CCC1=O",
  "o-Chloramine T": "CC1=CC=CC=C1S([N-]Cl)(=O)=O.[Na+]",
  "Oxalyl Chloride": "O=C(Cl)C(Cl)=O",
  "Pyridine Iodine Monochloride": "IC1=NC=CC=C1Cl",
  "Pyridinium Bromide Perbromide": "BrBr.C1=[NH+]C=CC=C1.[Br-]",
  "Sodium Dichloroisocyanurate": "ClN(C([N-]C1=O)=O)C(N1Cl)=O.[Na+]",
  "Tetrabutylammonium Tribromide": "CCCC[N+](CCCC)(CCCC)CCCC.[Br-].[Br-].[Br-]",
  "Tetramethylammonium Dichloroiodate": "C[N+](C)(C)C.Cl[I-]Cl",
  "Thionyl Chloride": "O=S(Cl)Cl",
  "Trichloroisocyanuric Acid": "O=C(N(Cl)C(N1Cl)=O)N(Cl)C1=O",
  "Trichloromethanesulfonyl Chloride": "O=S(C(Cl)(Cl)Cl)(Cl)=O",
  "Trimethylphenylammonium Tribromide": "C[N+](C)(C)C1=CC=CC=C1.[Br-].[Br-].[Br-]",
}